import React, { useEffect, useState } from "react";
import { TiPlus } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import CreateAddressModal from "../modal/CreateAddressModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress } from "../../services/profileFetch";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { fetchAddress } from "../../services/getAllList";
import CircularProgress from "@mui/material/CircularProgress";
import Breadcrumbs from "../pageProps/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";

const Address = () => {
    const [openModal, setOpenModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const address = useSelector((state) => state.product.address);
    const dispatch = useDispatch();
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenModal = () => setOpenModal(true);
    const navigate = useNavigate();
    const removeAddress = async (id) => {
        try {
            setDeleteLoading(true);
            const response = await deleteAddress(id);
            console.log(response);
            dispatch(
                showNotification({
                    message: "Adres Silindi!",
                    type: "success",
                })
            );
            fetchAddress(dispatch);
            setDeleteLoading(false);
        } catch (error) {
            dispatch(
                showNotification({
                    message: "Adres Silinirken Bir Hata Oluştu!",
                    type: "error",
                })
            );
        }
    };

    useEffect(() => {
        fetchAddress(dispatch);
    }, []);

    const highlightStyle = {
        color: "#d0121a", // Change this to the desired color
        fontWeight: "bold", // Change this to the desired font weight
    };
    const renderDescription = (data) => {
        if (!data) {
            return null; // or handle accordingly if product.des is not defined
        }

        const description = data?.split(/:(.*?)-/).map((part, index) => {
            return (
                <span key={index} style={index % 2 === 1 ? highlightStyle : {}}>
                    {part}
                </span>
            );
        });

        return <>{description}</>;
    };

    return (
        <div className="px-5">
            <CreateAddressModal open={openModal} handleClose={handleCloseModal} />
            <div className="flex flex-col items-start pt-2 ">
                <IoArrowBackCircleSharp
                    onClick={() => navigate(-1)}
                    size={45}
                    className="cursor-pointer"
                    color="#1F2937"
                />

                <Breadcrumbs />
            </div>

            <div className="w-full flex flex-col items-center justify-center gap-4 ">
                <div className="flex justify-between bg-[#1F2937] text-white items-center w-full max-md:w-full h-[80px] border border-[#EAEAEA] shadow-md rounded-md mt-3 px-3 py-2">
                    <h3 className="max-md:text-xl md:text-2xl font-semibold">Adres Bilgilerim</h3>
                    <button
                        onClick={handleOpenModal}
                        className="flex items-center justify-center max-md:gap-1 gap-2"
                    >
                        <TiPlus size={25} color="orange" />
                        <p>Yeni Adres Ekle</p>
                    </button>
                </div>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-full max-md:w-full border bg-white border-[#EAEAEA] shadow-md p-5 gap-5">
                    {address?.map((item, i) => (
                        <div
                            key={i}
                            className="border flex flex-col justify-between bg-white border-[#EAEAEA] h-[230px] w-full max-md:h-[130px] shadow-md rounded-md p-2 max-md:text-xs"
                        >
                            <div className="flex flex-col gap-2 overflow-hidden">
                                <div className="w-full border-b truncate bg-[#1F2937] text-white rounded-sm p-1.5">{item?.adressTitle}</div>
                                <div className="w-full truncate">
                                    {renderDescription(item?.billingAddressDistrict)}
                                </div>
                                <div className="w-full truncate">{`${item?.shippingAddressCity}/${item?.shippingAddressDistrict}`}</div>
                                <div className="w-full">{item?.phoneNumber}</div>
                            </div>
                            {deleteLoading ? (
                                <CircularProgress size={20} color="info" />
                            ) : (
                                <button
                                    className="self-start p-1 mt-auto flex items-center hover:bg-red-100"
                                    onClick={() => removeAddress(item?.userAdressId)}
                                >
                                    <MdDelete size={20} />
                                    <p>Sil</p>
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Address;
