import React from "react";
import { Link } from "react-router-dom";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { MdSwitchAccount } from "react-icons/md";
import { useSelector } from "react-redux";
import { useUser } from "../../redux/user/hooks";
import ReactWhatsapp from "react-whatsapp";
import { IoLogoWhatsapp } from "react-icons/io5";

const Whatsapp = () => {
    // const user = useUser();

    // const products = useSelector((state) => state.product.productInBasket);
    return (
        <div className="fixed top-[70%] -left-10 z-50 hidden md:flex flex-col  -rotate-90">
                <div className="w-32 h-[50px] rounded-md flex flex-col justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer bg-green-500 text-white">
                    <div >
                        <ReactWhatsapp className="flex justify-center items-center gap-1" number="+908508854081" message="Merhaba, benim adım">
                            <IoLogoWhatsapp className="text-3xl" />
                        <p className="text-base max-md:text-xs font-semibold font-titleFont">Whatsap</p>
                        </ReactWhatsapp>
                    </div>
                </div>
        </div>
    );
};

export default Whatsapp;
