import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const dealerSlice = createSlice({
    name: "dealer",
    initialState: {
        productInBasket: [],
    },
    reducers: {
   
        addToCart: (state, action) => {
            console.log(action.payload)
            const {  productDimensionsId } = action.payload;
            const dimension = state.productInBasket.find(
                (dim) => dim.productDimensionsId === productDimensionsId
            );
            if (dimension) {
                toast.error("Sepetinizde Mevcut!");
                return;
            }
            else{
                state.productInBasket.push(action.payload);
            }
            console.log(state.productInBasket)
        },
        increaseQuantity: (state, action) => {
            const item = state.productInBasket.find(
                (item) =>
                    item._id === action.payload._id &&
                    item.productDimensionId === action.payload.productDimensionId
            );
            if (item) {
                if (item.stock >= item.quantity + 1) {
                    item.quantity++;
                    toast.success("Miktar artırıldı");
                } else {
                    toast.error("Stok limitine ulaşıldı!");
                }
            }
        },
        drecreaseQuantity: (state, action) => {
            const item = state.productInBasket.find(
                (item) =>
                    item._id === action.payload._id &&
                    item.productDimensionId === action.payload.productDimensionId
            );

            if (item) {
                if (item.quantity === 1) {
                    toast.error("Miktar 1'den az olamaz");
                } else {
                    item.quantity--;
                    toast.success("Miktar azaltıldı");
                }
            }
        },
        deleteItem: (state, action) => {
            state.productInBasket = state.productInBasket.filter(
                (item) => item.productDimensionId === action.payload
            );
            toast.success("Ürün sepetten silindi!");
        },
        resetBasketCart: (state) => {
            state.productInBasket = [];
            // Dispatch a success toast
        },
    },
});

export const {
    addToCart,
    increaseQuantity,
    drecreaseQuantity,
    deleteItem,
    resetBasketCart,
} = dealerSlice.actions;
export default dealerSlice.reducer;
