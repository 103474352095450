// import React from "react";
// import { sidebarLinks } from "../../constants/index";
// import { NavLink, useLocation } from "react-router-dom";
// import classNames from "classnames";
// const Sidebar = () => {
//     const { pathname } = useLocation();
//     console.log(pathname);

//     return (
//         <section className="sticky top-0 left-0 flex h-screen w-fit flex-col justify-between p-6 pt-28 border-r-[1px] text-black max-sm:w-[75px] lg:w-[264px]">
            
//             <div className="flex flex- flex-col gap-6">
//                 {sidebarLinks?.map((link) => {
//                     return (
//                         <NavLink
//                             to={link.route}
//                             key={link.label}
//                             className={({ isActive }) => 
//                             classNames("flex gap-4 max-md:gap-2 items-center p-4 max-md:p-1 rounded-lg justify-start", {
//                                 "bg-[#1F2937] text-white": isActive,
//                             })
//                         }
                        
//                         >
//                             {link.imgUrl}
//                             <p className="text-lg font-semibold max-lg:hidden">{link.label}</p>
//                         </NavLink>
//                     );
//                 })}
//             </div>
//         </section>
//     );
// };

// export default Sidebar;


import React from "react";
import { sidebarLinks } from "../../constants/index";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

const Sidebar = () => {
    const { pathname } = useLocation();

    return (
        <section className="sticky top-0 left-0 flex h-screen w-fit flex-col justify-between p-6 pt-28 border-r-[1px] text-black max-sm:w-[75px] lg:w-[264px]">
            <div className="flex flex- flex-col gap-6">
                {sidebarLinks?.map((link) => {
                    const isActive = pathname === link.route;
                    return (
                        <NavLink
                            to={link.route}
                            key={link.label}
                            className={() =>
                                classNames("flex gap-4 max-md:gap-2 items-center p-4 max-md:p-1 rounded-lg justify-start", {
                                    "bg-[#1F2937] text-white": isActive,
                                })
                            }
                        >
                            {link.icon}
                            <p className="text-lg font-semibold max-lg:hidden">{link.label}</p>
                        </NavLink>
                    );
                })}
            </div>
        </section>
    );
};

export default Sidebar;
