import React from "react";
import { truncatedText } from "../../constants/generalFunction";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { FaAddressCard } from "react-icons/fa";
import { TbFileDescription } from "react-icons/tb";
import { Checkbox } from "@mui/material";
import { RiPencilLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoNavigate } from "react-icons/io5";
import { MdLocationOn } from "react-icons/md";


const PaymentAddress = ({ updateAddress, handleCheckboxChange, selectedAddressId }) => {
    const address = useSelector((state) => state.product.address);
    const navigate = useNavigate();
    
    return (
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-full max-md:w-full border border-[#EAEAEA] shadow-md p-5 gap-5">
            {address?.length !== 0  ? 
            (
                address?.map((item, i) => (
                    <div
                        key={i}
                        className="border flex flex-col justify-between border-[#EAEAEA] h-[230px] w-full max-md:h-[200px] shadow-md rounded-md p-3 max-md:text-xs"
                    >
                        <div className="flex flex-col gap-2 overflow-hidden">
                            <div className="w-full border-b bg-[#33475B] text-white truncate flex items-center justify-between p-1.5 rounded-md">
                                <span className="font-semibold ">{item?.adressTitle}</span>
                                <Checkbox
                                    checked={selectedAddressId === item?.userAdressId}
                                    onChange={(e) => handleCheckboxChange(e, item?.userAdressId)}
                                    sx={{
                                        color: "#fff",
                                        "&.Mui-checked": {
                                            color: "#fff",
                                        },
                                    }}
                                />
                            </div>
                            <div className="w-full pt-1 truncate flex items-center gap-2">
                                <IoMdPerson size={20} />
                                <span>{item?.shippingAddressContactName}</span>
                            </div>
                            <div className="w-full truncate flex items-center gap-2">
                                <TbFileDescription size={20} />
                                <span>{truncatedText(item?.shippingAddressDescription, 20)}</span>
                            </div>
                            <div className="w-full truncate flex items-center gap-2">
                                <FaAddressCard size={20} />
                                <span>{`${item?.shippingAddressCity}/${item?.shippingAddressDistrict}`}</span>
                            </div>
                            <div className="w-full flex items-center gap-2">
                                <FaPhoneAlt size={20} />
                                <span>{item?.phoneNumber}</span>
                            </div>
                            <button
                                onClick={() => updateAddress(item?.userAdressId)}
                                className="w-full flex justify-end gap-1 p-1"
                            >
                                <span>Düzenle</span>
                                <RiPencilLine size={20} />
                            </button>
                        </div>
                    </div>
                ))
            ) : (
                <>
                    <button
                        className=" flex items-center justify-center gap-2 rounded-md p-2 "
                        onClick={() => navigate("/profil/adres")}
                    >
                        <span className="hover:underline font-semibold text-[#1F2937]"> Adres Eklemek için tıklayın </span>
                        <MdLocationOn size={20}/>
                    </button>
                </>
            )}
        </div>
    );
};

export default PaymentAddress;
