

import "./style.css";
import { SlBasket } from "react-icons/sl";
import { CgProfile } from "react-icons/cg";
import { HiLocationMarker } from "react-icons/hi";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
export const sidebarLinks= [
  {
      label: "Profil Güncelle",
      route: "/profil",
      icon:<CgProfile size={25}/>
  },
  {
      label: "Siparişlerim",
      route: "/profil/siparislerim",
      icon:<SlBasket size={25}/>
  },
  {
    label: "Sipariş Takip",
    route: "/profil/siparis-takip",
    icon: <LocalShippingIcon size={25}/>
},
  {
      label: "Adreslerim",
      route: "/profil/adres",
      icon: <HiLocationMarker size={25}/>
  },
]

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Anasayfa",
    link: "/",
  },
  {
    _id: 1002,
    title: "Ürünler",
    link: "/urunler",
  },
  {
    _id: 1006,
    title: "Kategori",
  },
  {
    _id: 1004,
    title: "İletişim",
    link: "iletisim",
  },
  {
    _id: 1004,
    title: "B2B Girişi",
    link: "signin",
  },
  
];
export const menuList = [
  {
    _id: 1001,
    title: "Anasayfa",
    link: "/",
  },
  {
    _id: 1002,
    title: "Ürünler",
    link: "/urunler",
  },
  {
    _id: 1004,
    title: "İletişim",
    link: "iletisim",
  },
  {
    _id: 1004,
    title: "B2B Girişi",
    link: "signin",
  },
  
];



