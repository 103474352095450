import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const productSlice = createSlice({
    name: "product",
    initialState: {
        products: [],
        dealerProducts: [],
        productInBasket: [],
        checkedPrices: [],
        checkedCategorys: [],
        categories: [],
        favorite: [],
        address: [],
        stock: [],
    },
    reducers: {
        addStock: (state, action) => {
            state.stock = action.payload;
        },
        addCategories: (state, action) => {
            state.categories = action.payload;
        },
        addProducts: (state, action) => {
            state.products = action.payload;
        },
        addDealerProducts: (state, action) => {
            state.dealerProducts = action.payload;
        },
        addToCart: (state, action) => {
            const { _id, productDimensionId } = action.payload;
            const product = state.products.find((prod) => prod.productId === _id);
            const dimension = product.productDimensions.find(
                (dim) => dim.productDimensionId === productDimensionId
            );
            if (!dimension) {
                toast.error("Boyut bulunamadı");
                return;
            }

            action.payload.price = dimension.price;

            const item = state.productInBasket.find(
                (item) => item._id === _id && item.productDimensionId === productDimensionId
            );

            if (!item) {
                state.productInBasket.push(action.payload);
                // toast.error("Bu boyutta ürün zaten mevcut");
                // console.log("Bu boyutta ürün zaten mevcut");
            }
        },
        increaseQuantity: (state, action) => {
            console.log(action);
            const item = state.productInBasket.find(
                (item) =>
                    item._id === action.payload._id &&
                    item.productDimensionId === action.payload.productDimensionId
            );
            console.log(item);
            if (item) {
                if (item.stock >= item.quantity + 1) {
                    item.quantity++;
                    toast.success("Miktar artırıldı");
                } else {
                    toast.error("Stok limitine ulaşıldı!");
                }
            }
        },
        drecreaseQuantity: (state, action) => {
            console.log(action);
            const item = state.productInBasket.find(
                (item) =>
                    item._id === action.payload._id &&
                    item.productDimensionId === action.payload.productDimensionId
            );
            console.log(item);
            if (item) {
                if (item.quantity === 1) {
                    toast.error("Miktar 1'den az olamaz");
                } else {
                    item.quantity--;
                    toast.success("Miktar azaltıldı");
                }
            }
        },
        deleteItem: (state, action) => {
            state.productInBasket = state.productInBasket.filter(
                (item) => item.productDimensionId !== action.payload
            );
            toast.success("Ürün sepetten silindi!");
        },
        resetCart: (state) => {
            state.productInBasket = [];
            state.address = [];
            state.stock = [];
            state.favorite = [];
            // Dispatch a success toast
        },
        resetBasketCart: (state) => {
            state.productInBasket = [];
            // Dispatch a success toast
        },
        resetstocks: (state) => {
            state.stock = [];
            // Dispatch a success toast
        },
        toggleBrand: (state, action) => {
            const brand = action.payload;
            const isBrandChecked = state.checkedBrands.some((b) => b._id === brand._id);

            if (isBrandChecked) {
                state.checkedBrands = state.checkedBrands.filter((b) => b._id !== brand._id);
            } else {
                state.checkedBrands.push(brand);
            }
        },
        togglePrice: (state, action) => {
            const price = action.payload;
            const isPriceChecked = state.checkedPrices?.some(
                (p) => p.priceOne === price.priceOne && p.priceTwo === price.priceTwo
            );

            if (isPriceChecked) {
                state.checkedPrices = state.checkedPrices?.filter(
                    (p) => p.priceOne !== price.priceOne || p.priceTwo !== price.priceTwo
                );
            } else {
                state.checkedPrices?.push(price);
            }
        },
        deletePrice: (state) => {
            state.checkedPrices = [];
        },
        toggleCategory: (state, action) => {
            const category = action.payload;
            const isCategoryChecked = state.checkedCategorys?.some(
                (b) => b.subCategoryId === category.subCategoryId
            );

            if (isCategoryChecked) {
                state.checkedCategorys = state.checkedCategorys?.filter(
                    (b) => b.subCategoryId !== category.subCategoryId
                );
            } else {
                state.checkedCategorys.push(category);
            }
        },
        addToFavorite: (state, action) => {
            const { productId, productDimensionId } = action.payload;
            const product = state.products.find((prod) => prod.productId === productId);

            const dimension = product.productDimensions.find(
                (dim) => dim.productDimensionId === productDimensionId
            );

            if (!dimension) {
                toast.error("Boyut bulunamadı");
                return;
            }
            const item = state.favorite.find(
                (item) =>
                    item.productId === productId && item.productDimensionId === productDimensionId
            );
            if (!item) {
                state.favorite.push(action.payload);
            }
        },
        deleteFavoriteItem: (state, action) => {
            state.favorite = state.favorite.filter(
                (item) => item.productDimensionId !== action.payload
            );
        },
        resetFavorite: (state) => {
            state.favorite = [];
        },

        allAddress: (state, action) => {
            state.address = action.payload;
        },
    },
});

export const {
    addToCart,
    increaseQuantity,
    drecreaseQuantity,
    resetstocks,
    deleteItem,
    resetBasketCart,
    resetCart,
    toggleBrand,
    toggleCategory,
    addToFavorite,
    resetFavorite,
    deleteFavoriteItem,
    addCategories,
    addProducts,
    allAddress,
    togglePrice,
    addStock,
    deletePrice,
    addDealerProducts,
} = productSlice.actions;
export default productSlice.reducer;
