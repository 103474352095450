import React from "react";
import { FaChevronRight } from "react-icons/fa";

const ProductNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-14 rounded-full  text-[#33475B] hover:bg-black hover:bg-opacity-40 duration-300 flex justify-center items-center z-10 absolute top-[35%] right-2"
      onClick={onClick}
    >
      <span className="text-xl">
        <FaChevronRight size={35}/>
      </span>
    </div>
  );
};

export default ProductNextArrow;

