import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";

const Index = () => {
    return (
        <div className="relative">
            <div className="flex">
                <Sidebar />
                {/* <section className='flex min-h-screen flex-1 flex-col  bg-cariBg h-screen bg-no-repeat bg-cover'> */}

                <section className="flex min-h-screen flex-1 flex-col bg-gradient-to-t from-[#F5F7FA] to-zinc-100">
                    <main className="w-full ">
                        <Outlet />
                    </main>
                </section>
            </div>
        </div>
    );
};

export default Index;
