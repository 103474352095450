import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFavoriteItem  } from "../../redux/product/productSlice";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { MdDeleteOutline } from "react-icons/md";
import { convertTurkishChars, formatPrice } from "../../constants/generalFunction";
import { useNavigate } from "react-router-dom";
import { deleteFavoriteItemFetch } from "../../services/favoriteFetch";

const FavoriteItem = ({ item }) => {
    const [loadingInc, setLoadingInc] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const products = useSelector((state) => state.product.products);
    const product = products?.find((p) => p.productId === item?.productId && p.productDimensions[0].productDimensionId === item?.productDimensionId)

    const handleProductDetails = () => {
        if (product && product.productName) {
            const productNameEnglish = convertTurkishChars(product.productName.toString());
            const productItem = {
                _id: product.productId,
                img: [
                    { img: product.productImage1 },
                    { img: product.productImage2 },
                    { img: product.productImage3 },
                ],
                productName: product.productName,
                dimension: product.productDimensions,
                categories: product.categories,
                des: product.productDescription,
                ...product,
            };
            navigate(`/product/${productNameEnglish}`, {
                state: {
                    item: productItem,
                },
            });
        } else {
            console.error("Product or product.productName is undefined");
        }
    };

    const [animationParent] = useAutoAnimate();


    const deleteFavorite = async () => {
        try {
            const response = await deleteFavoriteItemFetch(item?.userFavoriId);
            
            if (response?.statusCode === 200) {
            // dispatch(deleteFavoriteItem(props?._id));
            // fetchBasketList(dispatch);
            // }
            dispatch(deleteFavoriteItem(item?.productDimensionId))
            dispatch(showNotification({ type: "error", message: "Ürün Favorilerden Silindi." }));
            }
            if (response?.statusCode === 404) {
                dispatch(showNotification({ type: "error", message: "Ürün Favorilerde Bulunamadı." }));
                }
            
        } catch (error) {
            dispatch(
                showNotification({
                    message: "Ürün Silinirken Bir Hata Oluştu!",
                    type: "error",
                })
            );
        }
    };

    return (
        <div ref={animationParent} className="w-full grid grid-cols-5 mb-4 border py-2">
            <div className="flex col-span-5 mdl:col-span-3 items-center gap-4 ml-4">
                <MdDeleteOutline
                    // size={32}
                    onClick={deleteFavorite}
                    className="text-red-500  hover:bg-red-500 hover:text-white rounded-full p-1 duration-300 cursor-pointer max-md:h-[90px] max-md:w-[90px] h-[45px] w-[45px]"
                />
                <div className="w-32 h-32">
                    <img
                        onClick={handleProductDetails}
                        className="w-32 h-32 object-contain"
                        src={product?.productImage1}
                        alt="productImage"
                    />
                </div>
                <div>
                    <h1
                        onClick={handleProductDetails}
                        className="font-titleFont w-[80%] font-semibold cursor-pointer max-md:text-sm"
                    >
                        {product?.productName}
                    </h1>
                </div>
            </div>
            <div className="col-span-5 mdl:col-span-2 flex  items-center justify-between py-4  mdl:py-0  mdl:px-0 max-md:gap-4  mdl:gap-0">
                <div
                    onClick={handleProductDetails}
                    className="flex w-1/2 items-center justify-center pr-1 text-lg max-md:text-sm font-semibold cursor-pointer"
                >
                    {item?.size}
                </div>
                <div
                    onClick={handleProductDetails}
                    className="flex w-1/2 items-center justify-center pr-1 text-lg max-md:text-sm font-semibold cursor-pointer"
                >
                    {formatPrice(item?.price)}TL
                </div>
                
               
            </div>
        </div>
    );
};

export default FavoriteItem;
