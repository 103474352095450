import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const filterSlice = createSlice({
    name: "filter",
    initialState: {
        checkedPrices: [],
        checkedCategorys: [],
        dealerSearchProducts: "",
        stockCheckState: false,
    },
    reducers: {
     
        togglePrice: (state, action) => {
            const price = action.payload;
            console.log( state.checkedPrices);
            const isPriceChecked = state.checkedPrices?.some(
                (p) => p.priceOne === price.priceOne && p.priceTwo === price.priceTwo
            );
            if (isPriceChecked) {
                state.checkedPrices = state.checkedPrices?.filter(
                    (p) => p.priceOne !== price.priceOne || p.priceTwo !== price.priceTwo
                );
            } else {
                state.checkedPrices?.push(price);
            }
            console.log(state.checkedPrices);
        },
        deletePrice: (state) => {
            state.checkedPrices = [];
        },
        toggleCategory: (state, action) => {
            const category = action.payload;
            const isCategoryChecked = state.checkedCategorys?.some(
                (b) => b.subCategoryId === category.subCategoryId
            );

            if (isCategoryChecked) {
                state.checkedCategorys = state.checkedCategorys?.filter(
                    (b) => b.subCategoryId !== category.subCategoryId
                );
            } else {
                state.checkedCategorys.push(category);
            }
        },
        addDealerSearch : (state, action) => {
            state.dealerSearchProducts = action.payload;
        },
        changeStockCheckState: (state, action) => {
            state.stockCheckState = action.payload;
        },
        
    },
});

export const {
    toggleCategory,
    togglePrice,
    deletePrice,
    addDealerSearch,
    changeStockCheckState
} = filterSlice.actions;
export default filterSlice.reducer;
