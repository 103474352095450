import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, InputAdornment, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { forgot } from "../../services/authFetch";
import { forgotSchema } from "../../styles/YupSchemas";
import LockResetIcon from '@mui/icons-material/LockReset';
import { BsArrowReturnLeft } from "react-icons/bs";

const ForgotPassword = () => {
    const [progress, setProgress] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(forgotSchema),
    });

    const onSubmit = async (data) => {
        try {
            setProgress(true);
            
            const response = await forgot(data.email);
            if (response?.data?.statusCode === 200) {
                setProgress(false);
                
                dispatch(showNotification({ type: "success", message: "Mailinize gelen linke tıklayınız." }));
                navigate("/signin");
            } else if (response?.data?.statusCode === 404) {
                dispatch(
                    showNotification({ type: "error", message: response?.data?.statusMessage })
                );
            } else {
                dispatch(showNotification({ type: "error", message: response?.data?.message }));
            }
        } catch (Error) {
            console.log(Error);
        } finally {
            setProgress(false);
        }
    };
    return (
        <div className="w-full h-screen flex items-center justify-center pt-12">
            <div className="w-full h-full">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full mx-auto lgl:w-[450px] flex items-center justify-center"
                >
                    <div className="px-6 py-4 w-full h-[90%] shadow-lg rounded-md flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
                        <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                            Şifre Sıfırlama
                        </h1>
                        <div className="flex flex-col gap-3">
                        <div className="flex flex-col gap-.5">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    label="Email"
                                    sx={{ color: "#b5cbd7" }}
                                    type={"email"}
                                    autoComplete="current-password"
                                    color="info"
                                    {...register("email")}
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    textTransform: "capitalize",
                                    backgroundColor: "#000000",
                                    ":hover": { backgroundColor: "#262626" },
                                }}
                                // color="primary"
                                disabled={progress}
                                startIcon={
                                    progress ? (
                                        <CircularProgress color="inherit" size={"16px"} />
                                    ) : (
                                        <LockResetIcon />
                                    )
                                }
                            >
                                Şifremi Sıfırla
                            </Button>
                            <div className="flex items-center justify-between">
                                <p className="text-sm text-center font-titleFont font-medium">
                                    
                                    <Link to="/signin" className="flex items-center justify-center gap-1 hover:text-blue-600 duration-300">
                                        <span className="">
                                            Giriş Sayfasına Dön
                                        </span>
                                        <BsArrowReturnLeft color="" className="" size={13}/>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;


