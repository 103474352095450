import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import { deleteStockItem } from "../../services/notificationFetch";
import { fetchProducts, fetchStocks } from "../../services/getAllList";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { convertTurkishChars, formatPrice } from "../../constants/generalFunction";

const ItemCard = ({ item }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const products = useSelector((state) => state.product.products);
    const product = products?.find((product) => product?.productId === item?.productDimension?.product?.productId);

    const handleProductDetails = () => {
        const productNameEnglish = convertTurkishChars(product?.productName);
        const productItem = {
            _id: product?._id,
            img: [
                { img: product?.productImage1 },
                { img: product?.productImage2 },
                { img: product?.productImage3 },
            ],
            productName: product?.productName,
            dimension: product?.productDimensions,
            categories: product?.categories,
            des: product.productDescription,
            ...product,
        };
        navigate(`/product/${productNameEnglish}`, {
            state: {
                item: productItem,
            },
        });
    };
    
    const deleteItemStock = async () => {
        try {
            const response = await deleteStockItem(item?.notificationId);
            fetchStocks(dispatch);
            fetchProducts(dispatch);
        } catch (error) {
            dispatch(
                showNotification({
                    message: "Bir Hata Oluştu!",
                    type: "error",
                })
            );
        }
    };
    
    return (
        <div className="w-full grid grid-cols-5 mb-4 border py-2">
            <div className="     flex col-span-5 mdl:col-span-2 items-center gap-2 ">
                <MdDeleteOutline
                    onClick={deleteItemStock}
                    className=  "text-red-500 hover:bg-red-500 hover:text-white w-8 h-8 rounded-full duration-300 cursor-pointer"
                />
                <img
                    onClick={handleProductDetails}
                    className="w-32 h-32 object-contain cursor-pointer"
                    src={item?.productDimension?.product?.productImage1}
                    alt="productImage"
                />

                <div onClick={handleProductDetails}>
                    <h1 className="font-titleFont font-semibold w-[80%] cursor-pointer">
                        {item?.productDimension?.product?.productName}
                    </h1>
                </div>
            </div>

            <div
                onClick={handleProductDetails}
                className="col-span-5 mdl:col-span-3 flex items-center justify-between py-4 mdl:py-0 px-4 mdl:px-0 gap-6 mdl:gap-0 cursor-pointer"
            >
                <div className="flex w-1/3 items-center text-lg font-semibold">
                    <h1 className="font-titleFont font-semibold">
                        {item?.productDimension?.dimension?.size}
                    </h1>
                </div>

                <div className="w-1/3 flex items-center font-titleFont mr-10">
                    <p className="">Stoğa Eklendiğinde size mail ile bildirilecek.</p>
                </div>
                <div className="w-1/3 flex items-center font-titleFont font-bold mr-10 text-lg">
                    <p className="">{formatPrice(item?.productDimension?.price)}TL</p>
                </div>
            </div>
        </div>
    );
};

export default ItemCard;
