import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { logoLight } from "../../assets/images";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HttpsIcon from "@mui/icons-material/Https";
import UpdateIcon from "@mui/icons-material/Update";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { deleteUser, setUser } from "../../redux/user/actions";
import { useUser } from "../../redux/user/hooks";
import InputMask from "react-input-mask";
import { updateUser } from "../../services/profileFetch";
import Breadcrumbs from "../pageProps/Breadcrumbs";
import { IoArrowBackCircleSharp } from "react-icons/io5";

const Profile = () => {
    const [visible, setVisible] = useState(false);
    const [progress, setProgress] = useState(false);
    const [visibleR, setVisibleR] = useState(false);
    const [visibleOld, setVisibleOld] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useUser();

    const schema = yup.object({
        newPassword: yup
            .string()
            .min(6, "Şifre 6 karakterden az olamaz")
            .required("Şifre gerekli*"),
        oldPassword: yup
            .string()
            .min(6, "Şifre 6 karakterden az olamaz")
            .required("Eski Şifre gerekli*"),
        passwordR: yup
            .string()
            .oneOf([yup.ref("newPassword"), null], "Şifreler Eşleşmiyor")
            .required("Şifre gerekli*"),
        phoneNumber: yup.string().min(11, "Telefon numarası 11 karakterden az olamaz"),
        userName: yup.string().min(5, "Kullanıcı adı 5 karakterden az olamaz"),
        email: yup.string(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            userName: user?.userName,
            email: user?.email,
        },
    });

    const onSubmit = async (data) => {
        try {
            const processedNumber = data.phoneNumber.replace(/[\(\)\s-]/g, "");
            const obj = { ...data, phoneNumber: "0" + processedNumber };
            setProgress(true);
            const response = await updateUser(obj);
            if (response?.data?.statusCode === 200) {
                setProgress(false);
                await deleteUser();
                await setUser(response?.data?.user);
                dispatch(showNotification({ type: "success", message: "Güncelleme Başarılı." }));
                navigate("/");
            } else {
                dispatch(
                    showNotification({ type: "error", message: response?.data?.statusMessage })
                );
            }
        } catch (Error) {
            console.log(Error);
        } finally {
            setProgress(false);
        }
    };
    return (
        <div className="w-full mx-auto px-5">
            <div className="flex flex-col items-start pt-2">
                <IoArrowBackCircleSharp
                    onClick={() => navigate(-1)}
                    size={45}
                    className="cursor-pointer "
                    color="#1F2937"
                />

                <Breadcrumbs />
            </div>

            <div className="max-w-container mx-auto px-4">
                <div className="w-full pt-10 flex items-center justify-center">
                    <div className="">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="w-full lgl:w-[600px] flex items-center justify-center"
                        >
                            <div className="px-6 py-1 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
                                <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                                    Profili Güncelle
                                </h1>
                                <div className="flex flex-col gap-3">
                                    <div className="flex space-x-2">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            id="email"
                                            label="email"
                                            sx={{ color: "#b5cbd7" }}
                                            type={"email"}
                                            autoComplete="current-password"
                                            color="info"
                                            {...register("email")}
                                            error={!!errors.email}
                                            helperText={errors?.email?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircle color="#000000" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            id="userName"
                                            label="Kullanıcı Adı"
                                            sx={{ color: "#b5cbd7" }}
                                            type={"userName"}
                                            autoComplete="current-password"
                                            color="info"
                                            {...register("userName")}
                                            error={!!errors.userName}
                                            helperText={errors?.userName?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircle color="#000000" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div className="flex gap-2">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            id="oldPassword"
                                            label="Eski Şifre"
                                            sx={{ color: "white" }}
                                            type={visible ? "text" : "password"}
                                            autoComplete="current-password"
                                            color="info"
                                            {...register("oldPassword")}
                                            error={!!errors.oldPassword}
                                            helperText={errors?.oldPassword?.message}
                                            InputProps={{
                                                endAdornment: (
                                                    <EndAdorment
                                                        visible={visibleOld}
                                                        setVisible={setVisibleOld}
                                                    />
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <HttpsIcon color="#000000" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {/* </div>
                            <div className="flex flex-col gap-.5"> */}
                                        <InputMask
                                            mask="(999) 999-9999"
                                            {...register("phoneNumber")}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    {...inputProps}
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    id="phoneNumber"
                                                    label="Telefon Numarası"
                                                    sx={{ color: "white" }}
                                                    type={"text"}
                                                    autoComplete="current-password"
                                                    color="info"
                                                    {...register("phoneNumber")}
                                                    error={!!errors.phoneNumber}
                                                    helperText={errors?.phoneNumber?.message}
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    <div className="flex gap-2">
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            id="newPassword"
                                            label="Yeni Şifre"
                                            sx={{ color: "white" }}
                                            type={visible ? "text" : "password"}
                                            color="info"
                                            {...register("newPassword")}
                                            error={!!errors.newPassword}
                                            helperText={errors?.newPassword?.message}
                                            InputProps={{
                                                endAdornment: (
                                                    <EndAdorment
                                                        visible={visible}
                                                        setVisible={setVisible}
                                                    />
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <HttpsIcon color="#000000" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {/* </div>
                            <div className="flex flex-col gap-.5"> */}
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            variant="outlined"
                                            id="passwordR"
                                            label="Yeni Şifre Tekrar"
                                            sx={{ color: "white" }}
                                            type={visibleR ? "text" : "password"}
                                            autoComplete="newPassword"
                                            color="info"
                                            {...register("passwordR")}
                                            error={!!errors.passwordR}
                                            helperText={errors?.passwordR?.message}
                                            InputProps={{
                                                endAdornment: (
                                                    <EndAdorment
                                                        visible={visibleR}
                                                        setVisible={setVisibleR}
                                                    />
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <HttpsIcon color="#000000" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            mt: 3,
                                            mb: 2,
                                            textTransform: "capitalize",
                                            backgroundColor: "#000000",
                                            ":hover": { backgroundColor: "#262626" },
                                        }}
                                        // color="primary"
                                        disabled={progress}
                                        startIcon={
                                            progress ? (
                                                <CircularProgress color="inherit" size={"16px"} />
                                            ) : (
                                                <UpdateIcon />
                                            )
                                        }
                                    >
                                        Güncelle
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;

const EndAdorment = ({ visible, setVisible }) => {
    return (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={() => setVisible(!visible)}
                onMouseDown={(e) => e.preventDefault()}
            >
                {visible ? <VisibilityIcon color="secondary" /> : <VisibilityOffIcon />}
            </IconButton>
        </InputAdornment>
    );
};
