import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../../components/home/Products/Heading";
import Product from "../../components/home/Products/Product";
import SampleNextArrow from "../../components/home/NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../../components/home/NewArrivals/SamplePrevArrow";
import { getDetailProducts } from "../../services/productFetch";
import { useDispatch } from "react-redux";
import { showNotification } from "../../redux/notifications/notificationSlice";

const RecommendedProducts = ({ productCategoryId }) => {
    const [sliderProducts, setSliderProducts] = useState();
    const dispatch = useDispatch();
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    };
    
    const fetchSliderProducts = async (productCategoryId) => {
        try {
            const res = await getDetailProducts(productCategoryId);
            console.log(res)
            if (res?.data) setSliderProducts(res.data);
        } catch (error) {
            dispatch(
                showNotification({ type: "error", message: "Ürünler Getirilirken Hata Oluştu!" })
            );
        }
    };
    
    useEffect(() => {
      if (productCategoryId !== null ) {
        fetchSliderProducts(productCategoryId);
      }
    }, [productCategoryId]);

    return (
        <>
        {sliderProducts?.length > 3 && <div className="w-full pb-16 pt-7">
            <Heading heading="Bu Ürüne Bakanlar Bunlarada Baktı" />
            <Slider {...settings}>
                {sliderProducts?.map((item, i) => (
                    <div key={i} className="px-2">
                       <Product
                        _id={item.productId}
                        img={[
                            { img: item.productImage1 },
                            { img: item.productImage2 },
                            { img: item.productImage3 },
                        ]}
                        productName={item.productName}
                        price="35.00"
                        dimension={item.dimensions}
                        categories={item.categories}
                        badge={false}
                        des={item.productDescription}
                        {...item}
                    />
                    </div>
                ))}
                  
            </Slider>
        </div>}
        </>
    );
};

export default RecommendedProducts;
