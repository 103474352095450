import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaSearch, FaCaretDown } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdProductionQuantityLimits } from "react-icons/md";
import { useUser } from "../../../redux/user/hooks";
import { deleteUser } from "../../../redux/user/actions";
import { resetCart } from "../../../redux/product/productSlice";
import { Tooltip } from "@mui/material";
import { FaLongArrowAltRight } from "react-icons/fa";
import { RiLoginBoxLine } from "react-icons/ri";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { formatPrice } from "../../../constants/generalFunction";
import { FaRegHeart } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";
import { SlBasketLoaded } from "react-icons/sl";

const HeaderBottom = () => {
    const [show, setShow] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredProducts, setFilteredProducts] = useState();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const categories = useSelector((state) => state.product.categories);
    const stock = useSelector((state) => state.product.stock);
    const products = useSelector((state) => state.product.productInBasket);
    const allProducts = useSelector((state) => state.product.products);
    const favorite = useSelector((state) => state.product.favorite);
    const dealerBasket = useSelector((state) => state.dealer.productInBasket);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref = useRef();
    const refUser = useRef();
    const refSearchBar = useRef();
    const user = useUser();
    // console.log(user);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current.contains(e.target)) {
                setShow(true);
                setShowUser(false);
                setShowSearchBar(false);
            } else if (refUser.current.contains(e.target)) {
                setShowUser(true);
                setShow(false);
                setShowSearchBar(false);
            } else if (refSearchBar?.current?.contains(e.target)) {
                setShowSearchBar(true);
                setShow(false);
                setShowUser(false);
            } else {
                setShow(false);
                setShowUser(false);
                setShowSearchBar(false);
            }
        };
        document.body.addEventListener("click", handleClickOutside);
        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, [ref, refUser, refSearchBar]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setShowSearchBar(true);
    };
    useEffect(() => {
        const filtered = allProducts?.filter((item) => {
            const productName = item.productName ?? "";
            const query = searchQuery ?? "";
            return productName.toLowerCase().includes(query.toLowerCase());
        });
        setFilteredProducts(filtered);
    }, [searchQuery, allProducts]);

    const logout = async () => {
        await localStorage.clear();
        await deleteUser();
        await dispatch(resetCart());
    };

    return (
        <div className="w-full bg-[#FFFFFF] relative border-b shadow-sm">
            <div className="max-w-container mx-auto">
                <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
                    {user?.role !== "bayi" && user?.role !== "bayiUser" && (
                        <div
                            onClick={() => setShow(!show)}
                            ref={ref}
                            className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
                        >
                            <HiOutlineMenuAlt4 className="w-5 h-5" />
                            <p className="text-[14px] font-normal ">Kategoriler</p>

                            {show && (
                                <motion.ul
                                    initial={{ y: 30, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    className="rounded-md absolute top-20 z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
                                >
                                    {categories?.map((item, i) => (
                                        <Link key={i} to={`category/${item.categoryId}`}>
                                            <li className="text-gray-300 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-start gap-1.5">
                                                <FaLongArrowAltRight />
                                                {item?.categoryName}
                                            </li>
                                        </Link>
                                    ))}
                                </motion.ul>
                            )}
                        </div>
                    )}
                    {user?.role !== "bayi" && user?.role !== "bayiUser" && (
                        <div
                            ref={refSearchBar}
                            className="relative w-full lg:w-[600px] h-[50px] text-base  bg-[#EDF0F3] flex items-center gap-2 justify-between px-6 rounded-xl"
                        >
                            <input
                                className="flex-1 h-full bg-[#EDF0F3] placeholder:text-primeColor/70 outline-none p placeholder:text-[14px]"
                                type="text"
                                onChange={handleSearch}
                                value={searchQuery}
                                placeholder="Ürün Arayın.."
                            />
                            <FaSearch className="w-5 h-5" />
                            {showSearchBar && searchQuery && (
                                <div
                                    className={`w-full mx-auto h-96 bg-[#EDF0F3] top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
                                >
                                    {filteredProducts?.map((item, i) => (
                                        <div
                                            onClick={() =>
                                                navigate(`/product/${item.productId}`, {
                                                    state: {
                                                        item: item,
                                                    },
                                                }) &
                                                setShowSearchBar(false) &
                                                setSearchQuery("")
                                            }
                                            key={i}
                                            className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
                                        >
                                            <img
                                                className="w-24 h-20 object-contain rounded-md"
                                                src={item?.productImage1}
                                                alt="productImg"
                                            />
                                            <div className="flex flex-col gap-1">
                                                <p className="font-semibold">{item?.productName}</p>
                                                <p className="text-xs">
                                                    {item?.productDescription.length > 100
                                                        ? `${item?.productDescription.slice(
                                                              0,
                                                              100
                                                          )}...`
                                                        : item?.productDescription}
                                                </p>
                                                <p className="text-sm pb-1">
                                                    Fiyat:{" "}
                                                    <span className="text-primeColor font-semibold">
                                                        {formatPrice(
                                                            item?.productDimensions?.[0]?.price
                                                        ) ?? ""}
                                                        TL
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
                        <div ref={refUser} onClick={() => setShowUser(!showUser)} className="flex">
                            <IoPersonOutline size={22} />
                            <FaCaretDown />
                        </div>
                        {showUser && (
                            <motion.ul
                                initial={{ y: 30, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className="absolute top-9 -left-4 z-50 bg-primeColor w-44 text-[#767676] h-auto p-4 pb-6"
                            >
                                {user?.userName ? (
                                    <Link to="/" onClick={logout}>
                                        <li className="text-gray-300 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-center gap-1.5">
                                            <RiLogoutBoxRLine size={20} color="text-gray-300" />
                                            Çıkış Yap
                                        </li>
                                    </Link>
                                ) : (
                                    <>
                                        <Link to="/signin">
                                            <li className="text-gray-300 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer flex items-center justify-center gap-1.5">
                                                <RiLoginBoxLine size={20} />
                                                Giriş Yap
                                            </li>
                                        </Link>
                                    </>
                                )}
                            </motion.ul>
                        )}
                        {/* {(user?.role !== "bayi" || user?.role !== "bayiUser") && (
                            <>
                                <Link to="/sepet">
                                    <Tooltip title="Sepetim" arrow>
                                        <div className="relative">
                                            <SlBasketLoaded size={22} />
                                            <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                                                {products?.length > 0 ? products?.length : 0}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to="/stok">
                                    <Tooltip title="Stok Bildirimlerim" arrow>
                                        <div className="relative">
                                            <MdProductionQuantityLimits size={20} />
                                            <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                                                {stock?.length > 0 ? stock?.length : 0}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to="/favoriler">
                                    <Tooltip title="Favorilerim" arrow>
                                        <div className="relative">
                                            <FaRegHeart size={20} />
                                            <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                                                {favorite?.length > 0 ? favorite?.length : 0}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </Link>
                            </>
                        )} */}
                        {(user?.role !== "bayi" && user?.role !== "bayiUser") ? (
                            <>
                                <Link to="/sepet">
                                    <Tooltip title="Sepetim" arrow>
                                        <div className="relative">
                                            <SlBasketLoaded size={22} />
                                            <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                                                {products?.length > 0 ? products?.length : 0}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to="/stok">
                                    <Tooltip title="Stok Bildirimlerim" arrow>
                                        <div className="relative">
                                            <MdProductionQuantityLimits size={20} />
                                            <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                                                {stock?.length > 0 ? stock?.length : 0}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Link to="/favoriler">
                                    <Tooltip title="Favorilerim" arrow>
                                        <div className="relative">
                                            <FaRegHeart size={20} />
                                            <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                                                {favorite?.length > 0 ? favorite?.length : 0}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </Link>
                            </>
                        ): (
                            <Link to="/bayi-sepet">
                                    <Tooltip title="Sepetim" arrow>
                                        <div className="relative">
                                            <SlBasketLoaded size={22} />
                                            <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                                                {dealerBasket?.length > 0 ? dealerBasket?.length : 0}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </Link>
                        )}
                    </div>
                </Flex>
            </div>
        </div>
    );
};

export default HeaderBottom;
