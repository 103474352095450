import React, { useState } from "react";
import img from "../../assets/images/order_follow_up.png";
import { RiSendPlaneLine } from "react-icons/ri";

const OrderTrackingUser = () => {
    const [value, setValue] = useState("");
    const [data, setData] = useState("");

    const onSubmit = async () => {
        if (!value) return;
        setData(value);
    };

    return (
        <div className="flex flex-col items-center pt-12 gap-2 px-5 " >
            <div>
                <div className="text-center mb-3">
                    <h3 className="text-2xl font-semibold ">Sipariş Sorgulama</h3>
                </div>
                <div className="mb-3">
                    {img ? (
                        <img className="w-[360px] h-[275px]" src={img} />
                    ) : (
                        <div className="mx-auto skeleton w-[360px] h-[275px]"></div>
                    )}
                </div>
                <div className="flex ">
                    <input
                        max={6}
                        type="number"
                        onChange={(e) => setValue(e.target.value)}
                        className="w-[320px] h-[40px] border border-gray-300 rounded-l-md px-2 "
                        placeholder="Sipariş numaranızı giriniz"
                    />
                    <button
                        disabled={!value}
                        type="button"
                        onClick={onSubmit}
                        className={`${
                            value ? "bg-primeColor" : "bg-[#455A64]"
                        } text-white flex items-center justify-center cursor-pointer rounded-r-md`}
                    >
                        <RiSendPlaneLine className="w-8" />
                    </button>
                </div>
                {data && <div className="text-center mt-3 text-lg font-semibold">{data}</div>}
            </div>
        </div>
    );
};

export default OrderTrackingUser;
