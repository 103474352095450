import React from "react";
import DiscountIcon from '@mui/icons-material/Discount';


const Badge = ({ discount }) => {
  return (
    <div className="bg-white/90 w-[92px] h-[35px] text-white flex justify-center items-center text-base font-semibold hover:bg-white/80 duration-300 cursor-pointer rounded-md">
      <DiscountIcon className="text-red-500"/>
      <span className="text-red-400">%{discount?.toFixed(2) * 100}</span>
    </div>
  );
};

export default Badge;
