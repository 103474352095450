import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HeaderSlider() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 15000,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 500,
        cssEase: "linear",
        pauseOnHover: false,
    };
    const slogans = [
        "Adım Attığınız Her Yerde Şıklık!",
        "Evinize Sıcaklık Katın!",
        "Halıda Tarzın Adresi!",
        "Evinize Özgün Dokunuşlar!",
        "Adım Adım Konfor ve Estetik!",
        "Kaliteli Halılarla Konforun Keyfini Çıkarın!",
        "Yumuşaklık ve Estetik Bir Arada!",
        "Evinizin Ruhunu Yansıtan Halılar!"
    ];

    return (
        <div className="w-full mx-auto bg-[#f5f5f5]">
            <Slider {...settings} className="flex items-center justify-center bg-red-400 text-white">
                {slogans?.map((slogan, index) => (
                    <div data-aos="fade-up" key={index} className="text-center bg-transparent mx-[50px] opacity-1">
                        <p className="lg:text-[1rem] text-red md:text-[0.8rem] max-md:text-[0.5rem] mx-auto w-full">
                            {slogan}
                        </p>
                    </div>
                ))}
            </Slider>
        </div>
    );
}




// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// export default function HeaderSlider() {
//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 15000,
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         autoplay: true,
//         autoplaySpeed: 500,
//         cssEase: "linear",
//         pauseOnHover: false,
//     };
//     const slogans = [
//         "Adım Attığınız Her Yerde Şıklık!",
//         "Evinize Sıcaklık Katın!",
//         "Halıda Tarzın Adresi!",
//         "Evinize Özgün Dokunuşlar!",
//         "Adım Adım Konfor ve Estetik!"
//     ];

//     const svgIcons = [
//         <svg key="1" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="inline-block w-6 h-6 mr-2">
//             <path d="M24 64H56 80V88v88 80 80 88 24H56 24c-13.3 0-24-10.7-24-24s10.7-24 24-24h8V360H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h8V280H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h8V200H24c-13.3 0-24-10.7-24-24s10.7-24 24-24h8V112H24C10.7 112 0 101.3 0 88S10.7 64 24 64zm88 0H528V448H112V64zM640 88c0 13.3-10.7 24-24 24h-8v40h8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8v32h8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8v32h8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8v40h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H584 560V424 336 256 176 88 64h24 32c13.3 0 24 10.7 24 24z"/>
//         </svg>,
//         <svg key="2" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="inline-block w-6 h-6 mr-2">
//             <path d="M320 32C145.9 32 0 145.9 0 320s145.9 288 320 288 320-145.9 320-288S494.1 32 320 32zm0 64c123.5 0 224 100.5 224 224s-100.5 224-224 224S96 443.5 96 320 196.5 96 320 96zm0 32c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48zm-48 240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"/>
//         </svg>,
//         <svg key="3" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="inline-block w-6 h-6 mr-2">
//             <path d="M320 32C145.9 32 0 145.9 0 320s145.9 288 320 288 320-145.9 320-288S494.1 32 320 32zm0 448c-88.2 0-160-71.8-160-160s71.8-160 160-160 160 71.8 160 160-71.8 160-160 160zm0-128c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48zm0-224c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"/>
//         </svg>,
//         <svg key="4" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="inline-block w-6 h-6 mr-2">
//             <path d="M320 0C145.1 0 0 145.1 0 320c0 161.9 106.3 303.7 256 371.2V320H96c-53 0-96-43-96-96s43-96 96-96h160V256H96c-35.3 0-64 28.7-64 64s28.7 64 64 64h160v128c22.7 8.3 46.7 15.4 71.1 20.8 6.9-29.4 26.3-54.8 52.9-68.5 26.8-13.8 58.5-16 87.1-6.3C439.5 480.6 384 408.2 384 320c0-66.3 38.1-124.4 96-153.6V320H448v-64h160c53 0 96-43 96-96s-43-96-96-96H544V256H512V112c-27.8-17.4-60.1-28-96-28-88.4 0-160 71.6-160 160 0 83.2 60.3 151.2 140 166.4 4.4 2.2 9.1 3.2 13.9 3.2 22.1 0 42.9-14.2 50.3-35.5 4.4-12.5 2.1-25.9-5.5-36.5-7.4-10.3-19.4-17.1-32.4-17.1-30.3 0-53.9 24.5-54.2 54.9-30.5 18-68.2 26.9-107.3 26.9-123.1 0-224-100.9-224-224S196.9 96 320 96s224 100.9 224 224c0 85.6-53.2 160.2-128 192v32c70.7-25.2 128-90.4 128-175.1 0-88.6-71.4-160-160-160-52.3 0-98.6 25.5-128 64.6-29.4-39.1-75.7-64.6-128-64.6-88.6 0-160 71.4-160 160 0 52.3 25.5 98.6 64.6 128 39.1-29.4 64.6-75.7 64.6-128 0-61.3-35.4-114.2-88-142.9 52.6-45.5 120.5-73.1 192-73.1 161.9 0 288 126.1 288 288S481.9 608 320 608c-52.6 0-103.5-13.9-146.9-38.8 3.1-9.2 6.5-18.4 10-27.5 19.5 9.7 40.6 14.8 62.6 14.8 61.9 0 112-50.1 112-112 0-24.4-7.7-47.2-21.7-66.4C281.2 366.5 320 338.1 320 304V224c66.5 0 120-53.5 120-120s-53.5-120-120-120z"/>
//         </svg>,
//         <svg key="5" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className="inline-block w-6 h-6 mr-2">
//             <path d="M320 0C145.1 0 0 145.1 0 320c0 161.9 106.3 303.7 256 371.2V320H96c-53 0-96-43-96-96s43-96 96-96h160V256H96c-35.3 0-64 28.7-64 64s28.7 64 64 64h160v128c22.7 8.3 46.7 15.4 71.1 20.8 6.9-29.4 26.3-54.8 52.9-68.5 26.8-13.8 58.5-16 87.1-6.3C439.5 480.6 384 408.2 384 320c0-66.3 38.1-124.4 96-153.6V320H448v-64h160c53 0 96-43 96-96s-43-96-96-96H544V256H512V112c-27.8-17.4-60.1-28-96-28-88.4 0-160 71.6-160 160 0 83.2 60.3 151.2 140 166.4 4.4 2.2 9.1 3.2 13.9 3.2 22.1 0 42.9-14.2 50.3-35.5 4.4-12.5 2.1-25.9-5.5-36.5-7.4-10.3-19.4-17.1-32.4-17.1-30.3 0-53.9 24.5-54.2 54.9-30.5 18-68.2 26.9-107.3 26.9-123.1 0-224-100.9-224-224S196.9 96 320 96s224 100.9 224 224c0 85.6-53.2 160.2-128 192v32c70.7-25.2 128-90.4 128-175.1 0-88.6-71.4-160-160-160-52.3 0-98.6 25.5-128 64.6-29.4-39.1-75.7-64.6-128-64.6-88.6 0-160 71.4-160 160 0 52.3 25.5 98.6 64.6 128 39.1-29.4 64.6-75.7 64.6-128 0-61.3-35.4-114.2-88-142.9 52.6-45.5 120.5-73.1 192-73.1 161.9 0 288 126.1 288 288S481.9 608 320 608c-52.6 0-103.5-13.9-146.9-38.8 3.1-9.2 6.5-18.4 10-27.5 19.5 9.7 40.6 14.8 62.6 14.8 61.9 0 112-50.1 112-112 0-24.4-7.7-47.2-21.7-66.4C281.2 366.5 320 338.1 320 304V224c66.5 0 120-53.5 120-120s-53.5-120-120-120z"/>
//         </svg>
//     ];

//     return (
//         <div className="w-full mx-auto bg-[#f5f5f5]">
//             <Slider {...settings} className="flex items-center justify-center bg-red-400 text-white">
//                 {slogans.map((slogan, index) => (
//                     <div data-aos="fade-up" key={index} className="text-center bg-transparent mx-[25px] opacity-1">
//                         <p className="lg:text-[1rem] text-red md:text-[0.8rem] max-md:text-[0.5rem] mx-auto w-[18rem] flex items-center justify-center">
//                             {svgIcons[index]} {slogan}
//                         </p>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// }
