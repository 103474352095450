import { showNotification } from "../redux/notifications/notificationSlice";
import { addCategories, addProducts, allAddress, addToCart, addStock, resetBasketCart, resetstocks, resetFavorite, addToFavorite } from "../redux/product/productSlice";
import { userBasketList } from "./basketFetch";
import { getAllFavorite } from "./favoriteFetch";
import { getAllStock } from "./notificationFetch";
import { getCategories, getProducts } from "./productFetch";
import { getAddress } from "./profileFetch";

export const fetchCategories = async (dispatch) => {
    try {
        const response = await getCategories();
        if (response) {
            dispatch(addCategories(response));
        } else {
            dispatch(
                showNotification({
                    message: "Kurumlar Listelenirken Hata Oluştu",
                    type: "error",
                })
            );
        }
    } catch (error) {
        console.log(error);
    }
};

export const fetchProducts = async (dispatch) => {
    try {
        const response = await getProducts();
        // console.log("response", response);
        if (response?.data) {
            dispatch(addProducts(response?.data));
        } else {
            dispatch(
                showNotification({
                    message: "Ürünler Getirilirken Hata Oluştu!",
                    type: "error",
                })
            );
        }
    } catch (error) {
        console.log(error);
    }
};

export const fetchAddress = async (dispatch) => {
    try {
        const response = await getAddress();
        
        if (response?.data) {
            dispatch(allAddress(response?.data));
        }
        // else {
        //     dispatch(
        //         showNotification({
        //             message: "Adres Getirilirken Hata Oluştu!",
        //             type: "error",
        //         })
        //     );
        // }
    } catch (error) {
        console.log(error);
    }
};

export const fetchBasketList = async (dispatch) => {
    try {
        const response = await userBasketList();
        // console.log(response)
        if (response?.data) {
            const basketItems = response?.data?.userBasketItems.map((item) => {
                const { stock, price, stock_b2c, product, dimension, productDimensionId } =
                    item.productDimension;
                return {
                    _id: product.productId,
                    name: product.productName,
                    image: product.productImage1,
                    dimensionId: dimension.dimensionId,
                    size: dimension.size,
                    stock: stock_b2c,
                    price: price,
                    quantity: item.quantity,
                    userBasketItemId: item.userBasketItemId,
                    basketId: response?.data.basketId,
                    productDimensionId: productDimensionId,
                };
            });
            // console.log("basketItems", basketItems);
            dispatch(resetBasketCart());
            basketItems.forEach((item) => {
                // console.log("item", item);
                dispatch(addToCart(item));
            });
        } else {
            dispatch(resetBasketCart());
        }
    } catch (error) {
        console.log(error);
    }
};

export const fetchStocks = async (dispatch) => {
    try {
        const response = await getAllStock();
        // console.log("response", response);

        // console.log("result", result.result.data);
        if (response?.data) {
            dispatch(addStock(response?.data));
        } else {
            dispatch(resetstocks());
            dispatch(
                showNotification({
                    message: "Adres Getirilirken Hata Oluştu!",
                    type: "error",
                })
            );
        }
    } catch (error) {
        console.log(error);
    }
};

export const fetchFavorites = async (dispatch) => {
    try {
        const response = await getAllFavorite();
        // console.log("response", response);
        if (response?.data) {
            const basketItems = response?.data?.map((item) => {
                return { 
                    userFavoriId: item.userFavoriId,
                    productId: item.productDimension.product.productId,
                    productDimensionId: item.productDimension.productDimensionId,
                    size: item.productDimension.dimension.size,
                    price: item.productDimension.price,
                    stock: item.productDimension.stock,
                };
            });
            dispatch(resetFavorite());
            // console.log("basketItems", basketItems);
            basketItems.forEach((item) => {
                dispatch(addToFavorite(item));
            });
        } else {
            dispatch(resetFavorite());
        }
    } catch (error) {
        console.log(error);
    }
};

