// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { saleImgOne, saleImgTwo, saleImgThree } from "../../../assets/images/index";
// import Image from "../../designLayouts/Image";
// import ShopNow from "../../designLayouts/buttons/ShopNow";
// import { homeCardsFetch } from "../../../services/homeFetch";

// const Sale = () => {
//     const [data, setData] = useState();

//     const getCards = async () => {
//         try {
//             const res = await homeCardsFetch();
//             if (res?.data) {
//                 setData(res.data);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     };
//     useEffect(() => {
//         getCards();
//     }, []);

//     console.log(data);
//     return (
//         <div className="py-20 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
//             {/* {
//         data?.slice(0,2).map((item, index) => ( */}
//             <div className="bg-[#f3f3f3] w-full md:w-2/3 lg:w-1/2 h-full flex flex-col justify-center items-center text-black">
//                 <div className="aspect-w-4 aspect-h-3 w-full mb-4">
//                     <Image
//                         className="h-[450px] w-full object-fill"
//                         imgSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScytWL7HjbBnir7t-nzqlGy1oCm5p5a1byDoQ5tS83Bs2iy27KdulkWDCi3-JXvsMOb-0&usqp=CAU"
//                     />
//                 </div>
//                 <div className="text-left h-140 md:h-200 lg:h-260 w-full mx-4 ">
//                     <div className="mx-8">
//                         <h2 className="text-4xl md:text-5xl lg:text-5xl font-bold mb-6">
//                             Imprimante sales
//                         </h2>
//                         <p className="text-lg md:text-xl lg:text-2xl mb-6">
//                             Up to{" "}
//                             <span className="text-4xl md:text-5xl lg:text-5xl font-bold">30%</span>{" "}
//                             sales for all impriamnte{" "}
//                         </p>
//                         <div className=" mb-8">
//                             <ShopNow />
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="w-full md:w-2/3 lg:w-1/2 h-auto flex flex-col gap-4 lg:gap-5">
//                 <div className="bg-[#f3f3f3] w-full h-full flex flex-col justify-center items-center text-black">
//                     <div className="aspect-w-4 aspect-h-2 w-full mb-4">
//                         <Image
//                             className="h-[225px] w-full object-fill"
//                             imgSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScytWL7HjbBnir7t-nzqlGy1oCm5p5a1byDoQ5tS83Bs2iy27KdulkWDCi3-JXvsMOb-0&usqp=CAU"
//                         />
//                     </div>
//                     <div className="text-left h-110 md:h-150 lg:h-180 w-full mx-4 ">
//                         <div className="mx-8">
//                             <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold mb-4">
//                                 Imprimante sales
//                             </h2>
//                             <p className="text-lg md:text-xl lg:text-2xl mb-2">aşedşaedaed</p>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="bg-[#f3f3f3] w-full h-full flex flex-col justify-center items-center text-black">
//                     <div className="aspect-w-4 aspect-h-2 w-full mb-4">
//                         <Image
//                             className="h-[225px] w-full object-fill"
//                             imgSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScytWL7HjbBnir7t-nzqlGy1oCm5p5a1byDoQ5tS83Bs2iy27KdulkWDCi3-JXvsMOb-0&usqp=CAU"
//                         />
//                     </div>
//                     <div className="text-left h-110 md:h-150 lg:h-180 w-full mx-4 ">
//                         <div className="mx-8">
//                             <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold mb-4">
//                                 Imprimante sales
//                             </h2>
//                             <p className="text-lg md:text-xl lg:text-2xl mb-2">aldlöaelödaöe</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Sale;

import React, { useEffect, useState } from "react";
import Image from "../../designLayouts/Image";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import { homeCardsFetch } from "../../../services/homeFetch";

const Sale = () => {
    const [data, setData] = useState([]);

    const getCards = async () => {
        try {
            const res = await homeCardsFetch();
            if (res?.data) {
                setData(res.data);
                console.log(res.data)
            } 
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getCards();
    }, []);

    return (
        <div className="py-20 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10 max-md:hidden">
            {data?.length >= 3 && (
                <>
                    <div className="bg-[#f3f3f3] w-full md:w-2/3 lg:w-1/2 h-full flex flex-col justify-center items-center text-black px-2">
                        <div className="aspect-w-4 aspect-h-3 w-full mb-4">
                            <Image
                                className="h-[515px] w-full object-cover"
                                imgSrc={data[0]?.sliderCardImage}
                            />
                        </div>
                        <div className="text-left h-140 md:h-220 lg:h-280 w-full mx-4 ">
                            <div className="mx-8">
                                <h2 className="text-3xl md:text-5xl lg:text-5xl font-bold mb-6">
                                    {data[0]?.sliderCardTitle}
                                </h2>
                                <p className="text-lg md:text-xl lg:text-2xl mb-6">
                                    {data[0]?.sliderCardDescription}
                                </p>
                                <div className="mb-8">
                                    <ShopNow />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-2/3 lg:w-1/2 h-auto flex flex-col gap-4 lg:gap-5 px-2">
                        <div className="bg-[#f3f3f3] w-full h-full flex flex-col justify-center items-center text-black">
                            <div className="aspect-w-4 aspect-h-2 w-full mb-4">
                                <Image
                                    className="h-[225px] w-full object-cover"
                                    imgSrc={data[1]?.sliderCardImage}
                                />
                            </div>
                            <div className="text-left h-110 md:h-130 lg:h-160 w-full mx-4 ">
                            <div className="mx-8 flex max-md:flex-col">
                                    <div className="w-2/3 items-start">
                                    <h2 className="text-2xl md:text-4xl lg:text-4xl font-bold mb-4">
                                        {data[1]?.sliderCardTitle}
                                    </h2>
                                    <p className="text-lg md:text-xl lg:text-2xl mb-1">
                                        {data[1]?.sliderCardDescription}
                                    </p>
                                    </div>
                                    <div className="w-1/3 items-end ">
                                      <div className="md:mb-12"/>
                                    <div className=" mb-4 mt-1">
                                    <ShopNow />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-[#f3f3f3] w-full h-full flex flex-col justify-center items-center text-black">
                            <div className="aspect-w-4 aspect-h-2 w-full mb-4">
                                <Image
                                    className="h-[225px] w-full object-cover"
                                    imgSrc={data[2]?.sliderCardImage}
                                />
                            </div>
                            <div className="text-left h-110 md:h-130 lg:h-160 w-full mx-4 ">
                                <div className="mx-8 flex max-md:flex-col">
                                    <div className="w-2/3 items-start">
                                    <h2 className="text-2xl md:text-4xl lg:text-4xl font-bold mb-4">
                                        {data[2]?.sliderCardTitle}
                                    </h2>
                                    <p className="text-lg md:text-xl lg:text-2xl mb-1">
                                        {data[2]?.sliderCardDescription}
                                    </p>
                                    </div>
                                    <div className="w-1/3 items-end ">
                                      <div className="md:mb-12"/>
                                    <div className="mb-4 mt-1">
                                    <ShopNow />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Sale;
