import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { emptyCart } from "../../assets/images/index";
import FavoriteItem from "./FavoriteItem";
import { fetchFavorites } from "../../services/getAllList";

const Favorite = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.favorite);
    
    
    useEffect(() => {
        fetchFavorites(dispatch);
    }, []);

    return (
        <div className="max-w-container mx-auto px-4">
            <Breadcrumbs title="Favoriler" />
            {products?.length > 0 ? (
                <div className="pb-20 border shadow-md rounded-lg">
                    <div className="w-full h-20 bg-[#33475B] text-white hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold text-center">
                        <h2 className="col-span-2">Ürün</h2>
                        <h2></h2>
                        <h2>Boyut</h2>
                        <h2>Fiyat</h2>
                    </div>
                    <div className="mt-5">
                        {products?.map((item) => (
                            <div key={item._id}>
                                <FavoriteItem item={item} />
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
                >
                    <div>
                        <img
                            className="w-80 rounded-lg p-4 mx-auto"
                            src={emptyCart}
                            alt="emptyCart"
                        />
                    </div>
                    <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
                        <h1 className="font-titleFont text-xl font-bold uppercase">
                            Sepetiniz Boş
                        </h1>
                        <p className="text-sm text-center px-10 -mt-2">
                            Alışveriş sepetinize Ürün Ekleyin.
                        </p>
                        <Link to="/urunler">
                            <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                                Alışveriş Yap
                            </button>
                        </Link>
                    </div>
                </motion.div>
            )}
        </div>
    );
};


export default Favorite;
