import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import productReducer from "./product/productSlice";
import notificationsReducer from "./notifications/notificationSlice";
import authReducer from "./user/userSlice";
import filterReducer from "./filters/filterSlice";
import dealerSlice from "./dealer/dealerSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["filter"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationsReducer,
  product: productReducer,
  filter: filterReducer,
  dealer : dealerSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
