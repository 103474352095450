import { get, postJSON, delJSON } from './request';
import { BASEURL } from './baseURL';

export const getCityAndDistrictList = () => get(`${BASEURL}/Profile/get-province-district`);
export const userAddressCreate = (data) => postJSON(`${BASEURL}/profile/userAdressCreate`, data);
export const userAddressUpdate = (data) => postJSON(`${BASEURL}/profile/userAdressUpdate`, data);
export const getAddress = () => get(`${BASEURL}/Profile/UserAddressList`);
export const deleteAddress = (id) => delJSON(`${BASEURL}/Profile/userAdressDelete/${id}`);
export const updateUser = (data) => postJSON(`${BASEURL}/Profile/UpdateUser`, data);




