import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteItem, drecreaseQuantity, increaseQuantity } from "../../redux/product/productSlice";
import { deleteBasketItem, userBasketUpdate } from "../../services/basketFetch";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { fetchBasketList } from "../../services/getAllList";
import CircularProgress from "@mui/material/CircularProgress";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { MdDeleteOutline } from "react-icons/md";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import { convertTurkishChars, formatPrice } from "../../constants/generalFunction";
import { useNavigate } from "react-router-dom";

const ItemCard = ({ item }) => {
    console.log(item);
    const [loadingInc, setLoadingInc] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const products = useSelector((state) => state.product.products);
    const product = products?.find((p) => p.productId === item?._id);
    // console.log(product);

    const handleProductDetails = () => {
        if (product && product.productName) {
            const productNameEnglish = convertTurkishChars(product.productName.toString());
            const productItem = {
                _id: product.productId,
                img: [
                    { img: product.productImage1 },
                    { img: product.productImage2 },
                    { img: product.productImage3 },
                ],
                productName: product.productName,
                dimension: product.productDimensions,
                categories: product.categories,
                des: product.productDescription,
                ...product,
            };
            navigate(`/product/${productNameEnglish}`, {
                state: {
                    item: productItem,
                },
            });
        } else {
            console.error("Product or product.productName is undefined");
        }
    };

    const [animationParent] = useAutoAnimate();

    const incOrDescQuantity = async (data) => {
        try {
            const { userBasketItemId, productDimensionId, quantity, _id, desc, stock } = data;
            setLoadingInc(true);
            console.log(data)
            if (quantity <= stock) {
                const response = await userBasketUpdate({
                    userBasketItemId,
                    quantity,
                });
                if (response?.data?.statusCode === 200) {
                   
                    fetchBasketList(dispatch);
                    desc
                        ? dispatch(drecreaseQuantity({ _id: _id, productDimensionId: productDimensionId }))
                        : dispatch(increaseQuantity({ _id: _id, productDimensionId: productDimensionId }));
                    setLoadingInc(false);
                }
            }
        } catch (error) {
            dispatch(
                showNotification({
                    message: "Bir Hata Oluştu!",
                    type: "error",
                })
            );
        }
    };

    const deleteItemBasket = async () => {
        try {
            const response = await deleteBasketItem(item.userBasketItemId);
            if (response?.data?.statusCode === 200) {
                dispatch(deleteItem(item?.productDimensionId));
                // fetchBasketList(dispatch);
            }
        } catch (error) {
            dispatch(
                showNotification({
                    message: "Ürün Silinirken Bir Hata Oluştu!",
                    type: "error",
                })
            );
        }
    };

    return (
        <div ref={animationParent} className="w-full grid grid-cols-5 mb-4 border py-2">
            <div className="flex col-span-6 mdl:col-span-2 items-center gap-4 ml-4">
                <MdDeleteOutline
                    // size={32}
                    onClick={deleteItemBasket}
                    className="text-red-500  hover:bg-red-500 hover:text-white rounded-full p-1 duration-300 cursor-pointer max-md:h-[90px] max-md:w-[90px] h-[45px] w-[45px]"
                />
                <div className="w-32 h-32">
                    <img
                        onClick={handleProductDetails}
                        className="w-32 h-32 object-contain"
                        src={item.image.img ? item.image?.img : item.image}
                        alt="productImage"
                    />
                </div>
                <div>
                    <h1
                        onClick={handleProductDetails}
                        className="font-titleFont w-[80%] font-semibold cursor-pointer max-md:text-xs"
                    >
                        {item?.name}
                    </h1>
                </div>
            </div>
            <div className="col-span-6 mdl:col-span-3 flex items-center justify-between py-4  mdl:py-0 px-4 mdl:px-0 max-md:gap-4 gap-6 mdl:gap-0">
                <div
                    onClick={handleProductDetails}
                    className="flex w-1/3 items-center text-lg max-md:text-sm font-semibold cursor-pointer"
                >
                    {item?.size}
                </div>
                <div
                    onClick={handleProductDetails}
                    className="flex w-1/3 items-center text-lg max-md:text-sm font-semibold cursor-pointer"
                >
                    {formatPrice(item?.price)}TL
                </div>
                <div className="w-1/3 flex items-center gap-6 max-md:gap-3 text-lg">
                    {loadingInc ? (
                        <CircularProgress size={20} color="info" />
                    ) : (
                        <CiCircleMinus
                            onClick={() => {
                                incOrDescQuantity({
                                    userBasketItemId: item.userBasketItemId,
                                    dimensionId: item.dimensionId,
                                    productDimensionId: item.productDimensionId,
                                    quantity : item.quantity === 1 ? 1 : item.quantity - 1,
                                    _id: item._id,
                                    desc: true,
                                    stock: item.stock,
                                });
                            }}
                            className="w-6 h-6 text-2xl flex items-center justify-center cursor-pointer duration-300"
                        />
                    )}
                    <p>{item.quantity}</p>
                    {loadingInc ? (
                        <CircularProgress size={20} color="info" />
                    ) : (
                        <CiCirclePlus
                            onClick={() => {
                                incOrDescQuantity({
                                    userBasketItemId: item?.userBasketItemId,
                                    dimensionId: item?.dimensionId,
                                    productDimensionId: item?.productDimensionId,
                                    quantity: item?.quantity + 1,
                                    _id: item?._id,
                                    desc: false,
                                    stock: item?.stock,
                                });
                            }}
                            className="w-6 h-6 text-2xl flex items-center justify-center cursor-pointer duration-300  "
                        />
                    )}
                </div>
                <div
                    onClick={handleProductDetails}
                    className="w-1/3 flex items-center font-titleFont font-bold max-md:text-sm text-lg cursor-pointer"
                >
                    <p>{formatPrice(item?.quantity * item?.price)}TL</p>
                </div>
            </div>
        </div>
    );
};

export default ItemCard;
