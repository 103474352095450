import { delJSON, postJSON, get } from './request';
import { BASEURL } from './baseURL';

// export const getCityAndDistrictList = () => get(`${BASEURL}/Profile/get-province-district`);
// export const userAddressCreate = (data) => postJSON(`${BASEURL}/profile/userAdressCreate`, data);
export const createNotificationFetch = (id) => postJSON(`${BASEURL}/Notification/NotificationCreate/${id}`);
export const getAllStock = () => get(`${BASEURL}/notification/notificationList`);
export const deleteStockItem = (id) => delJSON(`${BASEURL}/Notification/NotificationDelete/${id}`);



