// import React, { useEffect, useState } from "react";
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
//     TableSortLabel,
//     Typography,
//     Box,
//     TablePagination,
//     Divider,
//     IconButton,
//     Tooltip,
//     Chip,
// } from "@mui/material";
// import CheckIcon from "@mui/icons-material/Check";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import { useDispatch, useSelector } from "react-redux";
// import { showNotification } from "../store/notifications/notificationSlice";
// import { fetchDealerOrderList } from "../services/getAllList";
// import Swal from "sweetalert2";
// import { acceptOrder, rejectOrder } from "../services/Dealer";
// import PageLoading from "../components/PageLoading";
// import SearchField from "../components/SearchField";
// import AnimatedPage from "../components/AnimatedPage";
// import { getComparator, stableSort } from "../utils/sorts";

// const columns = [
//     { id: "type", label: "Kullanıcı Tipi" },
//     { id: "desenImg", label: "Desen Resim" },
//     { id: "malzemeAdi", label: "Malzeme Adı" },
//     { id: "size", label: "Ebat" },
//     { id: "name", label: "Yetkili İsim" },
//     { id: "email", label: "Yetkili Mail" },
//     { id: "phoneNumber", label: "Telefon Numarası" },
//     { id: "contactName", label: "Adres İletişim İsmi" },
//     { id: "userAdress", label: "Adres" },
//     { id: "addressDescription", label: "Adres Açıklama" },
//     { id: "zipCode", label: "Zip Kodu" },
//     { id: "stock", label: "Stok" },
//     { id: "quantity", label: "Talep Edilen Adet" },
//     { id: "status", label: "Sipariş Durumu" },
// ];

// const bayiColumns = [
//     { id: "type", label: "Kullanıcı Tipi" },
//     { id: "desenImg", label: "Desen Resim" },
//     { id: "malzemeAdi", label: "Malzeme Adı" },
//     { id: "size", label: "Ebat" },
//     { id: "stock", label: "Stok" },
//     { id: "quantity", label: "Talep Edilen Adet" },
//     { id: "status", label: "Sipariş Durumu" },
// ];
// const Dealer = () => {
//     const allOrganizations = useSelector((state) => state.service.allInstitutions);
//     const allDealerOrders = useSelector((state) => state.service.allDealerOrders);
//     const userInfo = useSelector((state) => state.user.userInfo);

//     const [rows, setRows] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchText, setSearchText] = useState("");
//     const [order, setOrder] = useState("asc");
//     const [orderBy, setOrderBy] = useState("");
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(6);

//     const dispatch = useDispatch();

//     useEffect(() => {
//         fetchDealerOrderList(dispatch).finally(() => setLoading(false));
//     }, [dispatch]);

//     useEffect(() => {
//         if (allDealerOrders) {
//             setRows(allDealerOrders);
//         }
//     }, [allDealerOrders]);

//     const handleRequestSort = (property) => {
//         const isAsc = orderBy === property && order === "asc";
//         setOrder(isAsc ? "desc" : "asc");
//         setOrderBy(property);
//         const sortedRows = stableSort(rows, getComparator(order, property));
//         setRows(sortedRows);
//     };

//     const handleSearch = (event) => {
//         setSearchText(event.target.value);
//     };

//     const getFilteredRows = (rows, filter) => {
//         return filter
//             ? rows.filter((row) =>
//                   row.malzemeAdi?.toString().toLowerCase().includes(filter.toLowerCase())
//               )
//             : rows;
//     };

//     const handleRejectDealerOrder = async (id) => {
//         Swal.fire({
//             icon: "warning",
//             text: "Siparişi iptal etmek istediğinize emin misiniz?",
//             input: "number",
//             inputPlaceholder: "Bayinin Alabileceği Bir Adet Sayısı Giriniz",
//             showCancelButton: true,
//             confirmButtonText: "İptal Et!",
//             confirmButtonColor: "#43a047",
//             cancelButtonColor: "#d32f2f",
//             cancelButtonText: "İptal",
//             showCloseButton: true,
//             preConfirm: (quantity) => {
//                 if (!quantity) {
//                     Swal.showValidationMessage('Bayinin Alabileceği Bir Stock Sayısı Giriniz');
//                     return false;
//                 }
//                 return quantity;
//             },
//         }).then(async (result) => {
//             if (result.isConfirmed) {
//                 const quantity = result.value; // Kullanıcının girdiği adet sayısı
//                 try {
//                     const response = await rejectOrder(id, quantity); // Adet sayısını fonksiyona gönderiyoruz
//                     if (response?.data?.statusCode === 200) {
//                         dispatch(
//                             showNotification({
//                                 message: "Sipariş Reddedildi",
//                                 type: "success",
//                             })
//                         );
//                         fetchDealerOrderList(dispatch);
//                     } else {
//                         dispatch(
//                             showNotification({
//                                 message: "Bir Hata Oluştu",
//                                 type: "error",
//                             })
//                         );
//                     }
//                 } catch (error) {
//                     console.log(error);
//                 }
//             }
//         });
//     };

//     const handleConfirmDealerOrder = async (id) => {
//         Swal.fire({
//             icon: "warning",
//             text: "Siparişi Onaylamak istediğinize emin misiniz?",
//             showCancelButton: true,
//             confirmButtonText: "Evet, Onayla!",
//             confirmButtonColor: "#43a047",
//             cancelButtonColor: "#d32f2f",
//             cancelButtonText: "İptal",
//             showCloseButton: true,
//         }).then(async (result) => {
//             if (result.isConfirmed) {
//                 try {
//                     const response = await acceptOrder(id);
//                     if (response?.data?.statusCode === 200) {
//                         dispatch(
//                             showNotification({
//                                 message: "Sipariş Onaylandı",
//                                 type: "success",
//                             })
//                         );
//                         fetchDealerOrderList(dispatch);
//                     } else {
//                         dispatch(
//                             showNotification({
//                                 message: "Bir Hata Oluştu",
//                                 type: "error",
//                             })
//                         );
//                     }
//                 } catch (error) {
//                     console.log(error);
//                 }
//             }
//         });
//     };

//     const filteredRows = getFilteredRows(rows, searchText);

//     const groupByBayi = (rows) => {
//         const grouped = rows.reduce((acc, row) => {
//             const bayiAdi = row.type === "Bayi" ? row.name : "B2C Siparişleri";
//             if (!acc[bayiAdi]) {
//                 acc[bayiAdi] = [];
//             }
//             acc[bayiAdi].push(row);
//             return acc;
//         }, {});

//         return Object.entries(grouped);
//     };

//     const groupedRows = groupByBayi(filteredRows);
//     const userColumns = userInfo?.roles?.[0] === "bayi" ? bayiColumns : columns;

//     return (
//         <AnimatedPage>
//             <Box className="flex justify-between items-center">
//                 <Typography variant="h4" component="h2" gutterBottom>
//                     Siparişler
//                 </Typography>
//                 <SearchField handleSearch={handleSearch} />
//             </Box>
//             <Divider />
//             <Paper className="mt-5">
//                 <TableContainer>
//                     <Table>
//                         <TableHead>
//                             <TableRow>
//                                 {userColumns.map((column) => (
//                                     <TableCell key={column.id}>
//                                         <TableSortLabel
//                                             active={orderBy === column.id}
//                                             direction={orderBy === column.id ? order : "asc"}
//                                             onClick={() => handleRequestSort(column.id)}
//                                         >
//                                             {column.label}
//                                         </TableSortLabel>
//                                     </TableCell>
//                                 ))}
//                                 <TableCell align="right">İşlemler</TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {groupedRows.map(([bayiAdi, orders]) => (
//                                 <React.Fragment key={bayiAdi}>
//                                     <TableRow>
//                                         <TableCell colSpan={userColumns.length + 1}>
//                                             <Typography variant="h6">
//                                                 <Chip label={bayiAdi} color="secondary" />
//                                             </Typography>
//                                         </TableCell>
//                                     </TableRow>
//                                     {orders.map((row) => (
//                                         <TableRow key={row.orderId}>
//                                             {userColumns.map((column) => (
//                                                 <TableCell key={column.id}>
//                                                     {column.id === "quantity" ? (
//                                                         <Chip label={row[column.id]} color="primary" />
//                                                     ) : column.id === "stock" ? (
//                                                         <Chip label={row[column.id]} color="success" />
//                                                     ) : (
//                                                         row[column.id]
//                                                     )}
//                                                 </TableCell>
//                                             ))}
//                                             {
//                                                 row.status === "Onay Bekleniyor" ? (
//                                                     <TableCell align="right">
//                                                     <Tooltip title="Onayla">
//                                                         <IconButton
//                                                             onClick={() =>
//                                                                 handleConfirmDealerOrder(row.orderId)
//                                                             }
//                                                         >
//                                                             <CheckIcon color="success" />
//                                                         </IconButton>
//                                                     </Tooltip>
//                                                     <Tooltip title="Reddet">
//                                                         <IconButton
//                                                             onClick={() =>
//                                                                 handleRejectDealerOrder(row.orderId)
//                                                             }
//                                                         >
//                                                             <HighlightOffIcon color="error" />
//                                                         </IconButton>
//                                                     </Tooltip>
//                                                 </TableCell>
//                                                 ) : (
//                                                     <Box></Box>
//                                                 )
//                                             }

//                                         </TableRow>
//                                     ))}
//                                 </React.Fragment>
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//                 <TablePagination
//                     rowsPerPageOptions={[6, 12, 24]}
//                     component="div"
//                     count={filteredRows.length}
//                     rowsPerPage={rowsPerPage}
//                     page={page}
//                     // onPageChange={handleChangePage}
//                     // onRowsPerPageChange={handleChangeRowsPerPage}
//                 />
//             </Paper>
//             {loading && <PageLoading />}
//         </AnimatedPage>
//     );
// };

// export default Dealer;

import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Typography,
    Box,
    TablePagination,
    Divider,
    IconButton,
    Tooltip,
    Chip,
    Avatar,
    Button,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import { acceptOrder, rejectOrder } from "../services/Dealer";
// import { getComparator, stableSort } from "../utils/sorts";
import { showNotification } from "../../redux/notifications/notificationSlice";
import SearchField from "../../components/dealerComponents/SearchField";
import AnimatedPage from "../../components/dealerComponents/AnimatedPage";
import PageLoading from "../../components/dealerComponents/PageLoading";
import { getComparator, stableSort } from "../../constants/sorts";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteItem, resetBasketCart } from "../../redux/dealer/dealerSlice";
import LinearProgress from "@mui/material/LinearProgress";
import { createOrderDealer } from "../../services/dealerFetch";
import { useNavigate } from "react-router-dom";

const columns = [
    { id: "productImage", label: "Denen Resim" },
    { id: "malzemeAdi", label: "Malzeme Adı" },
    { id: "size", label: "Ebat" },
    { id: "stock", label: "Stok" },
    { id: "quantity", label: "Talep Edilen Adet" },
];

const Dealer = () => {
    const productInBasket = useSelector((state) => state.dealer.productInBasket);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [progress, setProgress] = useState(false);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    // console.log(rows)
    useEffect(() => {
        if (productInBasket) {
            setRows(productInBasket);
            setLoading(false);
        }
    }, [productInBasket]);
    // console.log(productInBasket);

    const onSubmit = async (data) => {
        // console.log(data);
        setProgress(true);

        try {
            const response = await createOrderDealer(productInBasket);
            // console.log(response)
            if (response?.data?.statusCode === 200) {
                dispatch(
                    showNotification({
                        message: "Sipariş Verildi.",
                        type: "success",
                    })
                );
                navigate("/urunler")
                dispatch(resetBasketCart());
            } else {
                dispatch(
                    showNotification({
                        message: "Sipariş Oluşturulurken Bir Hata Oluştu",
                        type: "info",
                    })
                );
            }
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    message: "Bayi Eklenirken Bir Hata Oluştu",
                    type: "error",
                })
            );
        } finally {
            setProgress(false);
        }
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        const sortedRows = stableSort(rows, getComparator(order, property));
        setRows(sortedRows);
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getFilteredRows = (rows, filter) => {
        return filter
            ? rows.filter((row) =>
                  row.malzemeAdi?.toString().toLowerCase().includes(filter.toLowerCase())
              )
            : rows;
    };

    const filteredRows = getFilteredRows(rows, searchText);

    const groupByBayi = (rows) => {
        const grouped = rows.reduce((acc, row) => {
            const bayiAdi = row.type === "Bayi" ? row.name : "B2C Siparişleri";
            if (!acc[bayiAdi]) {
                acc[bayiAdi] = [];
            }
            acc[bayiAdi].push(row);
            return acc;
        }, {});

        return Object.entries(grouped);
    };

    const groupedRows = groupByBayi(filteredRows);

    const handleDeleteUser = async (id) => {
        // console.log("id", id);
        Swal.fire({
            icon: "warning",
            text: "Sepetten Silmek istediğinize emin misiniz?",
            showCancelButton: true,
            confirmButtonText: "Evet, Sil!",
            confirmButtonColor: "#43a047",
            cancelButtonColor: "#d32f2f",
            cancelButtonText: "İptal",
            showCloseButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // console.log("id", id);
                    await dispatch(deleteItem(id));
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    return (
        <AnimatedPage>
            <Box className="flex justify-between items-center">
                <Typography variant="h4" component="h2" gutterBottom>
                    Sepetim
                </Typography>
                {/* <SearchField handleSearch={handleSearch} /> */}
            </Box>
            <Divider />
            {/* <CreateUsers open={openUsersModal} handleClose={handleCloseUsersModal} />
            <UpdateUsers
                open={openUpdateUsersModal}
                handleClose={handleCloseUpdateUsersModal}
                updateUserId={updateUserId}
            /> */}
            {loading ? (
                <PageLoading />
            ) : (
                <Paper elevation={3}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : "asc"}
                                                onClick={() => handleRequestSort(column.id)}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                    <TableCell>İşlemler</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {filteredRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow hover key={index}>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    style={
                                                        column.id === "malzemeAdi"
                                                            ? {
                                                                  wordWrap: "break-word",
                                                                  whiteSpace: "normal",
                                                                  maxWidth: "150px",
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    {column.id === "productImage" ? (
                                                        <img
                                                            src={row[column.id]}
                                                            alt="Desen"
                                                            style={{ width: 100, height: 100 }}
                                                        />
                                                    ) : column.id === "quantity" ? (
                                                        <Chip
                                                            label={row[column.id]}
                                                            color="primary"
                                                        />
                                                    ) : column.id === "stock" ? (
                                                        <Chip
                                                            label={row[column.id]}
                                                            color="success"
                                                        />
                                                    ) : (
                                                        row[column.id]
                                                    )}
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                <Tooltip title="Sil" arrow>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleDeleteUser(
                                                                row.productDimensionsId
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[6, 9, 24, 100]}
                        component="div"
                        count={filteredRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            )}
            <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{
                    mt: 1,
                    mb: 1,
                    backgroundColor: "#000000",
                    "&:hover": {
                        backgroundColor: "#393939",
                    },
                }}
                color="primary"
                onClick={onSubmit}
                disabled={progress}
            >
                {progress ? "Sipariş İşlemi Devam Ediyor..." : "Sipariş Ver"}
            </Button>
        </AnimatedPage>
    );
};

export default Dealer;
