import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "../../../redux/user/hooks";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../../redux/notifications/notificationSlice";
import { SlBasketLoaded } from "react-icons/sl";
import { userBasketCreate } from "../../../services/basketFetch";
import { fetchBasketList, fetchFavorites, fetchProducts, fetchStocks } from "../../../services/getAllList";
import { FaCheck } from "react-icons/fa";
import { createNotificationFetch } from "../../../services/notificationFetch";
import { TbBellPlus } from "react-icons/tb";
import { formatPrice } from "../../../constants/generalFunction";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa";
import { createFavoriteFetch, deleteFavoriteItemFetch } from "../../../services/favoriteFetch";
import { FaHeart } from "react-icons/fa";
import { RiLoader2Fill } from "react-icons/ri";

const ProductInfo = ({ productInfo, setProductInfo }) => {
    // console.log(productInfo)
    const [loading, setLoading] = useState(false);
    const [exist, setExist] = useState(false);
    const products = useSelector((state) => state.product.products);
    const favorite = useSelector((state) => state.product.favorite);
    const [selectedDimension, setSelectedDimension] = useState(null);
    const user = useUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userFavorite = favorite?.find(
        (f) => f.productDimensionId === selectedDimension?.productDimensionId
    );
    useEffect(() => {
        if (selectedDimension) {
            const isFavorite = favorite?.some(
                (item) => item.productDimensionId === selectedDimension.productDimensionId
            );
            setExist(isFavorite);
        }
    }, [favorite, selectedDimension]);

    const addToFavoriteList = async () => {
        if (user?.userName) {
            try {
                setLoading(true);
                const data = {
                    productDimensionId: selectedDimension?.productDimensionId,
                };
                const response = await createFavoriteFetch(data);
                if (response?.data?.statusCode === 200) {
                    dispatch(showNotification({ type: "success", message: "Favorilere Eklendi." }));
                    fetchFavorites(dispatch);
                    setLoading(false);
                } else {
                    dispatch(
                        showNotification({
                            type: "error",
                            message: "Favorilere Eklenirken Bir Hata Oluştu!",
                        })
                    );
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            dispatch(
                showNotification({ type: "error", message: "Favorilere Eklemek İçin Giriş Yapın!" })
            );
            navigate("/signin");
        }
    };

    const deleteFavorite = async () => {
        try {
            setLoading(true);
            const response = await deleteFavoriteItemFetch(userFavorite?.userFavoriId);
            if (response?.statusCode === 200) {
                dispatch(
                    showNotification({ type: "error", message: "Ürün Favorilerden Silindi." })
                );
                fetchFavorites(dispatch);
                setLoading(false);
            }
            if (response?.statusCode === 404) {
                dispatch(
                    showNotification({ type: "error", message: "Ürün Favorilerde Bulunamadı." })
                );
                setLoading(false);
            }
        } catch (error) {
            dispatch(
                showNotification({
                    message: "Ürün Silinirken Bir Hata Oluştu!",
                    type: "error",
                })
            );
            setLoading(false);
        }
    };

    useEffect(() => {
        if (productInfo) setSelectedDimension(productInfo?.productDimensions?.[0]);
    }, [productInfo]);

    // console.log(selectedDimension);
    useEffect(() => {
        if (products) {
            setProductInfo(
                products.find((product) => product.productId === productInfo?.productId)
            );
            setSelectedDimension(
                productInfo?.productDimensions?.find(
                    (dim) => dim.dimensionId === selectedDimension?.dimensionId
                )
            );
        }
    }, [products]);

    const handleDimensionClick = (dimension) => {
        setSelectedDimension(dimension);
    };

    const addToBasket = async () => {
        if (!selectedDimension) {
            dispatch(showNotification({ type: "error", message: "Lütfen Bir Ebat Seçin" }));
        } else {
            if (user?.userName) {
                try {
                    const obj = {
                        productDimensionId: selectedDimension.productDimensionId,
                        quantity: 1,
                    };
                    const response = await userBasketCreate(obj);
                    if (response?.data?.statusCode === 200) {
                        dispatch(
                            showNotification({ type: "success", message: "Ürün Sepete Eklendi" })
                        );
                        await fetchBasketList(dispatch);
                    } else if (response?.data?.statusCode === 400) {
                        dispatch(
                            showNotification({ type: "info", message: "Sepetinizde Ürün Mevcut!" })
                        );
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                dispatch(
                    showNotification({ type: "error", message: "Sepete Eklemek İçin Giriş Yapın" })
                );
                navigate("/signin");
            }
        }
    };

    const createNotification = async () => {
        if (user?.userName) {
            try {
                const response = await createNotificationFetch(
                    selectedDimension?.productDimensionId
                );
                
                dispatch(showNotification({ type: "success", message: "Stok Bildirimi Açıldı." }));
                 fetchProducts(dispatch);
                 fetchStocks(dispatch);
            } catch (error) {
                console.log(error);
            }
        } else {
            dispatch(
                showNotification({ type: "error", message: "Stok Bildirimi İçin Giriş Yapın" })
            );
            navigate("/signin");
        }
    };

    const discount = selectedDimension?.discount || 0;
    const cariDiscount = selectedDimension?.cariDiscount || 0;
    const discountedPrice = selectedDimension?.price / (1 - discount);
    const finalPrice = discountedPrice / (1 - cariDiscount);
    const discountRatio = 1 - selectedDimension?.price / finalPrice;

    // useEffect(() => {
    //     if (discountRatio) {
    //         setDiscountRatio(discountRatio);
    //     }
    // }, [discountRatio]);

    return (
        <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
                <h2 className="text-4xl max-md:text-xl font-semibold">{productInfo?.productName}</h2>
                {selectedDimension?.stock_b2c === 0 && (
                    <button
                        disabled={selectedDimension?.notificationStatus}
                        onClick={createNotification}
                        className={`flex items-center justify-center space-x-2 p-2 bg-orange-500 rounded-md text-white ${
                            !selectedDimension?.notificationStatus
                                ? "bg-orange-500 rounded-md"
                                : "bg-orange-300"
                        }`}
                    >
                        {!selectedDimension?.notificationStatus ? (
                            <>
                                <span> Stok Bildirimini Aç</span>
                                <TbBellPlus size={30} />
                            </>
                        ) : (
                            <>
                                <span> Stok Bildirimi Açıldı</span>
                                <FaCheck size={25} />
                            </>
                        )}
                    </button>
                )}
            </div>
            <div className="flex items-center justify-start gap-2 border-r">
                <p className="font-medium  self-start">Barkod No: </p>
                <span className="self-end ">{productInfo?.productBarcode}</span>
            </div>
            {/* <i className="text-2xl font-semibold">
                {selectedDimension
                    ? selectedDimension?.kdv?.taxRate
                        ? formatPrice(
                              selectedDimension?.price / (1 - selectedDimension?.kdv?.taxRate)
                          )
                        : formatPrice(selectedDimension?.price)
                    : formatPrice(productInfo?.productDimensions?.[0]?.price) ?? "N/A"}{" "}
                TL
            </i> */}
            <i className="text-2xl font-semibold flex items-start justify-start gap-2">
                {(discount !== 0 || cariDiscount !== 0) && (
                    <>
                        <span className="line-through mr-3 text-[26px] max-md:text-base text-[#656565] ">
                            {formatPrice(finalPrice) ?? "N/A"}TL
                        </span>
                        <span>
                            <span className="flex items-center justify-center">
                                <span className="text-[#198754] text-[18px]">
                                    {" "}
                                    %{(discountRatio * 100).toFixed(0)}
                                </span>
                                <IoMdArrowDropdown
                                    className="text-red-500 text-semibold max-md:text-base"
                                    sx={{ fontSize: "1.5rem" }}
                                />
                            </span>
                        </span>
                    </>
                )}
            </i>
            <i className="text-4xl max-md:text-2xl font-semibold">
                {selectedDimension ? (
                    <>
                        <span className="">{formatPrice(selectedDimension?.price) ?? "N/A"}TL</span>
                    </>
                ) : (
                    formatPrice(productInfo?.productDimensions?.[0]?.price) ?? "N/A" + " TL"
                )}
            </i>
            <div className="gap-2">
                {/* <p className="">
                    <div
                        className={`badge ${
                            selectedDimension?.stock_b2c === 0 ? "badge-warning " : "badge-success"
                        } text-xl gap-2 text-white p-3`}
                    >
                        <span className="">{selectedDimension?.stock_b2c === 0 ? "" : "Stok:"}</span>{" "}
                        {selectedDimension
                            ? selectedDimension?.stock_b2c === 0 || selectedDimension?.stock_b2c === null
                               & "Stokta Yok" : productInfo?.productDimensions?.[0]?.stock_b2c === 0 & "Stokta Yok"
                            }
                    </div>
                </p> */}
               {(selectedDimension?.stock_b2c === 0 || selectedDimension?.stock_b2c === null ) && <p className="">
                 <div
                     className={`badge ${
                      selectedDimension?.stock_b2c === 0 || selectedDimension?.stock_b2c === null & "badge-warning"} text-xl gap-2 text-white p-3`}
                         >
                            {selectedDimension?.stock_b2c === 0 || selectedDimension?.stock_b2c === null ? "Stokta Yok" : ""}
                </div>
                </p>}

            </div>
            <hr />
            <div className="flex max-md:flex-col items-center justify-around">
                <div className="flex items-center justify-center gap-2 border-r">
                    <p className="font-medium text-lg self-start">Ürünün Maddesi: </p>
                    <span className="self-end text-lg">{productInfo?.productMaterial}</span>
                </div>

                <div className="flex items-center justify-center gap-2 border-r">
                    <p className="font-medium text-lg self-start">Üretici: </p>
                    <span className="self-end text-lg">{productInfo?.productBrand}</span>
                </div>
                <div className="flex items-center justify-center gap-2 border-r">
                    <p className="font-medium text-lg self-start">Kategori: </p>
                    <span className="self-end text-lg">{productInfo?.categories?.[0]?.categoryName}</span>
                </div>
            </div>
            <hr />
            <div className="flex gap-4 flex-wrap w-full">
                {productInfo?.productDimensions?.map((dim) => (
                    <button
                        key={dim.dimensionId}
                        onClick={() => handleDimensionClick(dim)}
                        className={`px-4 py-2 mx-1 border rounded ${
                            selectedDimension?.dimensionId === dim.dimensionId
                                ? "bg-black text-white"
                                : "bg-white text-black"
                        }`}
                    >
                        {dim.size}
                    </button>
                ))}
            </div>
            <div className="flex items-center gap-5">
                <button
                    disabled={selectedDimension?.stock_b2c === 0}
                    onClick={addToBasket}
                    className={` flex justify-center gap-3 items-center py-4 rounded-md  duration-300 text-white text-lg font-titleFont w-[90%] ${
                        selectedDimension?.stock_b2c === 0 || selectedDimension?.stock_b2c === null
                            ? "bg-green-400"
                            : "bg-green-500 hover:bg-green-600"
                    }`}
                >
                    <SlBasketLoaded size={25} />
                    Sepete Ekle
                </button>
                <button className="flex w-[10%] max-md:w-[18%] items-center justify-center rounded-full bg-[#A3A3A3] h-full p-3">
                    {/* <FaRegHeart color="white" size={27} /> */}
                    {exist ? (<>
                    
                        {loading ? (
                             <RiLoader2Fill size={27} color="white" />
                         ) : (
                             <FaHeart
                                 size={27}
                                 color="red"
                                 onClick={deleteFavorite}
                                 className=" cursor-pointer hover:text-red-500"
                             />
                         )
                        }
                    </>
                        
                    ) : (
                        <>
                        {
                            loading ? (
                                <RiLoader2Fill size={27} color="white" />
                            ) : (
                                <FaRegHeart
                                    size={27}
                                    onClick={addToFavoriteList}
                                    className="text-white cursor-pointer hover:text-red-500"
                                />
                            )
                        }
                        </>
                    )}
                </button>
            </div>
        </div>
    );
};

export default ProductInfo;
