import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
    useLocation,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import ForgotPassword from "./pages/Account/ForgotPassword.jsx";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/Payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import Dealer from "./pages/Dealer/Dealer.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notifications from "./components/notification/Notification";
import PublicRoutes from "./route/route-control/PublicRoutes";
import PrivateRoutes from "./route/route-control/PrivateRoutes";
import ProfileLayout from "./pages/Profile/Index.js";
import Profile from "./components/Profile/Profile.jsx";
import Address from "./components/Profile/Address.jsx";
import Stock from "./pages/Stock/Stock.jsx";
import PaymentResult from "./pages/PaymentResult/PaymentResult.jsx";
import Order from "./components/Profile/Order.jsx";
import OrderDetails from "./components/Profile/OrderDetails.jsx";
import Favorite from "./pages/Favorite/Favorite.jsx";
import ResetPassword from "./pages/Account/ResetPassword.jsx";
import HeaderSlider from "./components/HeaderSlider/HeaderSlider.jsx";
import CurrentLayout from "./pages/Current/Index.jsx";
import CarpetOrder from "./pages/Current/CarpetOrder/Index.jsx";
import OrderTracking from "./pages/Current/OrderTracking/Index.jsx";
import OrderTrackingUser from "./components/Profile/OrderTrackingUser.jsx";
import { useSelector } from "react-redux";
import DealerCard from "./pages/DealerCard/DealerCard.jsx";
import Whatsapp from "./components/Whatsapp/Whatsapp.jsx";

const Layout = () => {
    const location = useLocation();
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <Header />
            <HeaderBottom />
            {location.pathname === "/" && <HeaderSlider />}
            <SpecialCase />
            <Whatsapp /> 
            <ScrollRestoration />
            <Outlet />
            <Footer />
            <FooterBottom />
        </div>
    );
};
const ShopOrDealer = () => {
    const user = useSelector((state) => state.auth.user);
    return user?.role === "bayi" || user?.role === "bayiUser" ? <Dealer /> : <Shop />;
};
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/urunler" element={<ShopOrDealer />} />
                {/* <Route path="/urunlerr" element={<Dealer />} /> */}
                <Route path="/about" element={<About />} />
                <Route path="/iletisim" element={<Contact />} />
                <Route path="/journal" element={<Journal />} />
                <Route path="/category/:id" element={<Offer />} />
                <Route path="/product/:_id" element={<ProductDetails />} />
                <Route element={<PrivateRoutes />}>
                    <Route path="/odeme" element={<Payment />} />
                    <Route path="/sepet" element={<Cart />} />
                    <Route path="/favoriler" element={<Favorite />} />
                    <Route path="/bayi-sepet" element={<DealerCard />} />
                    <Route path="/stok" element={<Stock />} />
                    <Route path="/profil" element={<ProfileLayout />}>
                        <Route index element={<Profile />} />
                        <Route path="/profil/adres" element={<Address />} />
                        <Route path="/profil/siparislerim" element={<Order />} />
                        <Route path="/profil/siparis-detay" element={<OrderDetails />} />
                        <Route path="/profil/siparis-takip" element={<OrderTrackingUser />} />
                    </Route>
                </Route>
                <Route element={<PublicRoutes />}>
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Route>
            </Route>
            {/* <Route element={<PrivateRoutes />}>
                <Route path="/cari-panel" element={<CurrentLayout />}>
                    <Route index element={<CarpetOrder />} />
                    <Route path="/cari-panel/siparis-takip" element={<OrderTracking />} />
                </Route>
            </Route> */}
            <Route element={<PrivateRoutes />}>
                <Route path="/odeme-ekrani" element={<PaymentResult />} />
            </Route>
        </Route>
    )
);

function App() {
    return (
        <div className="font-bodyFont">
            <Notifications />
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
