import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import propTypes from "prop-types";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
    Box,
    Button,
    TextField,
    Typography,
    IconButton,
    Stack,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@mui/material";
import { Grid } from "@mui/material";
import { modalStyle } from "../../styles/modalStyle";
import { createDealerOrderSchema } from "../../styles/YupSchemas";
import { stockOrderDealerRequest } from "../../services/dealerFetch";

const StockRequestModal = ({ open, handleClose, id }) => {
    const [progress, setProgress] = useState(false);

    const dispatch = useDispatch();
    const theme = useTheme();

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(createDealerOrderSchema),
    });

    const quantityValue = watch("quantity");

    const onSubmit = async (data) => {
        try {
            const obj = {
                productDimensionsId: id,
                quality: data.quantity,
                appUserId: null,
            };
            setProgress(true);
            const response = await stockOrderDealerRequest(obj);
            if (response?.data?.statusCode === 200) {
                dispatch(
                    showNotification({
                        message: "Stoğa Eklendiğinde Mail ile Bilgilendirme Yapılacaktır.",
                        type: "success",
                    })
                );
            } else {
                dispatch(
                    showNotification({
                        message: "Stok Bildirimi Açılırken Bir Hata Oluştu!",
                        type: "info",
                    })
                );
            }
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    message: "Bir Hata Oluştu",
                    type: "error",
                })
            );
        } finally {
            setProgress(false);
            handleClose();
            reset();
        }
    };

    return (
        <Box>
            <Modal
                aria-labelledby="create-assignments-modal-title"
                aria-describedby="create-assignments-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle} width={"50%"}>
                        <Box className="flex justify-between items-center">
                            <Typography
                                id="create-assignments-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ color: "#000000" }}
                            >
                                Stok Bildirimi Al
                            </Typography>
                            <IconButton
                                aria-label="exit"
                                size="large"
                                onClick={handleClose}
                                color="error"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
                            {progress ? <LinearProgress color="primary" /> : null}
                        </Stack>
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                            sx={{ mt: 1, width: "100%" }}
                        >
                            <Grid item xs={12} sm={8} className="mt-2">
                                <Box className="flex gap-4 pt-2 items-center justify-center">
                                    <FormControl fullWidth size="small" error={!!errors.quantity}>
                                        <TextField
                                            id="quantity"
                                            label="Talep Etmek İstediğiniz Adedi Giriniz"
                                            type="number"
                                            value={quantityValue || ""}
                                            onChange={(event) =>
                                                setValue("quantity", event.target.value)
                                            }
                                            error={!!errors.quantity}
                                            helperText={errors?.quantity?.message}
                                            inputProps={{ min: 1 }}
                                            {...register("quantity")}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    backgroundColor: "#000000",
                                    "&:hover": {
                                        backgroundColor: "#393939",
                                    },
                                }}
                                color="primary"
                                disabled={progress}
                            >
                                {progress ? "Stok Bildirim İşlemi Devam Ediyor..." : "Stok Bildirimi Al"}
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default StockRequestModal;

StockRequestModal.propTypes = {
    open: propTypes.bool.isRequired,
    handleClose: propTypes.func.isRequired,
};
