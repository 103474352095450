import React, { useState } from "react";
import { useDispatch } from "react-redux";
import NavTitle from "./NavTitle";
import { deletePrice, togglePrice } from "../../../../redux/filters/filterSlice";
import { FiMinus } from "react-icons/fi";
import { GoSearch } from "react-icons/go";

const Price = () => {
    const dispatch = useDispatch();
    const [customPrice, setCustomPrice] = useState({ min: "", max: "" });
    const priceList = [
        {
            _id: 950,
            priceOne: 0,
            priceTwo: 199,
        },
        {
            _id: 951,
            priceOne: 200,
            priceTwo: 499,
        },
        {
            _id: 952,
            priceOne: 500,
            priceTwo: 999,
        },
        {
            _id: 953,
            priceOne: 1000,
            priceTwo: 1499,
        },
        {
            _id: 954,
            priceOne: 1500,
            priceTwo: 2499,
        },
    ];

    const handlePriceClick = (price) => {
        // console.log(price);
        dispatch(togglePrice(price));
    };

    const handleCustomPrice = () => {
        if (customPrice.min && customPrice.max) {
            dispatch(deletePrice())
            dispatch(
                togglePrice({
                    priceOne: parseFloat(customPrice.min),
                    priceTwo: parseFloat(customPrice.max),
                })
            );
            // setCustomPrice({ min: "", max: "" });
        }
    };

    return (
        <div className="cursor-pointer">
            <NavTitle title="Fiyata Göre Filtrele" icons={false} />
            <div className="font-titleFont">
                <div className="mt-1">
                    <div className="flex items-center justify-between gap-2 mb-1">
                        <div className="flex items-center justify-center gap-1">
                            <input
                                type="number"
                                placeholder="Min"
                                value={customPrice.min}
                                onChange={(e) =>
                                    setCustomPrice({ ...customPrice, min: e.target.value })
                                }
                                className="w-11 h-8 border-[1px] rounded-md text-black p-1"
                            />
                              <FiMinus size={15} color="black"/>
                             <input
                                type="number"
                                placeholder="Max"
                                value={customPrice.max}
                                onChange={(e) =>
                                    setCustomPrice({ ...customPrice, max: e.target.value })
                                }
                                className="w-11 h-8 border-[1px] rounded-md text-black p-1 n"
                            />
                        </div>
                        <button onClick={handleCustomPrice} className=" hover:bg-[#E5E7EB] hover:text-black p-2 rounded-full">
                        <GoSearch size={20} color="black"/>
                    </button>
                    </div>
                    
                </div>
                <ul className="flex flex-col gap-2 text-sm lg:text-base text-[#767676]">
                    {priceList.map((item) => (
                        <div key={item._id} className="form-control">
                            <label className="cursor-pointer label">
                                <span className="label-text">{`${item.priceOne}TL - ${item.priceTwo}TL`}</span>
                                <input
                                    type="checkbox"
                                    onChange={() => handlePriceClick(item)}
                                    className="checkbox checkbox-info"
                                />
                            </label>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Price;
