import React, { useState, useEffect, useRef } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { fetchBasketList, fetchProducts } from "../../services/getAllList";
import { resetBasketCart } from "../../redux/product/productSlice";

const PaymentResult = () => {
    const [hubConnection, setHubConnection] = useState(null);
    const [updateStatus, setUpdateStatus] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data, conversationId } = location.state?.item || {};
    const iframeRef = useRef(null);
console.log(updateStatus);
    useEffect(() => {
        if (data && iframeRef.current) {
            const doc =
                iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            doc.open();
            doc.write(data);
            doc.close();
        }
    }, [data]);
    
    useEffect(() => {
        if (updateStatus) {
            if (updateStatus?.status && updateStatus?.status === "success") {
                dispatch(showNotification({ type: "success", message: "Ödemeniz Gerçekleşti. Sipariş Bilgileriniz Mailinize İletildi." }));
                fetchProducts(dispatch);
                dispatch(resetBasketCart());
                fetchBasketList(dispatch);
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            } else {
                dispatch(
                    showNotification({ type: "error", message: "Ödeme Sırasında Hata Oluştu!." })
                );
                navigate("/sepet");
            }
        }
    }, [updateStatus]);

    useEffect(() => {
        const createHubConnection = async () => {
            const connection = new HubConnectionBuilder()
                .withUrl(`http://b2bcarpetapi.liberta.com.tr/pay-hub`)
                .build();

            try {
                await connection.start();
                // console.log("Connected to SignalR hub");
            } catch (error) {
                console.log("Error connecting to SignalR hub", error);
            }
            setHubConnection(connection);
        };

        createHubConnection();

        return () => {
            if (hubConnection) {
                hubConnection.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (hubConnection) {
            try {
                hubConnection
                    .invoke("RegisterTransaction", conversationId)
                    .catch((err) => console.error(err));

                hubConnection.on("Receive", (res) => {
                    setUpdateStatus(res);
                });
            } catch (error) {
                console.log(error);
            }

            return () => {
                hubConnection.off("Receive");
            };
        }
    }, [hubConnection, conversationId]);

    return (
        <div className="flex items-center justify-center shadow-lg rounded-md">
            <iframe ref={iframeRef} style={{ width: "100%", height: "500px" }}></iframe>
        </div>
    );
};

export default PaymentResult;
