import React, { useEffect, useState } from "react";
import Breadcrumbs from "../pageProps/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { getOrderList } from "../../services/orderFetch";
import { formatDate } from "../../constants/generalFunction";
import { MdRemoveRedEye } from "react-icons/md";
import { IoArrowBackCircleSharp } from "react-icons/io5";

const OrderDetails = () => {
    const [order, setOrder] = useState();
    const navigate = useNavigate();

    const getData = async () => {
        const response = await getOrderList();
        if (response?.data) {
            setOrder(response.data);
        } 
    };
    
    useEffect(() => {
        getData();
    }, []);

    const handleOrderDetail = (id) => {
        const obj = order?.find((o) => o.orderId === id);
        
        navigate(`/profil/siparis-detay`, {
            state: {
                item: obj,
            },
        });
    };

    return (
        <div className="px-5 max-md:ml-0">
            <div className="flex flex-col items-start pt-2">
                <IoArrowBackCircleSharp
                    onClick={() => navigate(-1)}
                    size={45}
                    className="cursor-pointer"
                    color="#1F2937"
                />

                <Breadcrumbs />
            </div>
            <h1 className="text-2xl font-semibold">Siparişlerim({order?.length})</h1>
            <div className="flex flex-col items-start mt-5 w-full overflow-y-auto">
                {order ? (
                    order?.map((o, i) => (
                        <div
                            key={i}
                            className="flex items-center max-md:flex-col pt-5 justify-between w-full border-b-[1px] border-black p-2"
                        >
                            <div className="flex">
                                <div>
                                    <img
                                        className="w-24 h-24 object-cover rounded-md"
                                        src={o?.productDimension?.product?.productImage1}
                                    />
                                </div>
                                <div className="flex flex-col gap-0.5 pl-5">
                                    <p className="text-[#8DC41A] font-bold max-md:text-sm">
                                        <div className="badge badge-warning gap-2">
                                            
                                        {o?.orderStatus}
                                        </div>
                                    </p>
                                    <p className="text-lg font-semibold max-md:text-sm">
                                        {o?.productDimension?.product?.productName}
                                    </p>
                                    <p className="max-md:text-sm ">
                                        {formatDate(o?.createDate)} Tarihinde Sipariş Verildi
                                    </p>
                                    <p className="max-md:text-sm ">5986421 numaralı sipariş</p>
                                </div>
                            </div>
                            <div className="">
                                <button
                                    onClick={() => handleOrderDetail(o.orderId)}
                                    className="bg-[#1F2937] max-md:mt-1 rounded-md cursor-pointer hover:bg-black active:bg-gray-900 max-md:p-2 px-4 py-2 font-titleFont font-semibold max-md:text-sm text-lg text-gray-200 hover:text-white gap-2 duration-300 flex items-center justify-center"
                                >
                                    <span>
                                    Siparişi Görüntüle
                                    </span>
                                    <MdRemoveRedEye size={22}/>
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <>
                        <div className="mx-auto">
                            <span className="loading loading-dots loading-lg"></span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default OrderDetails;
