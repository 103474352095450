export default function Map({mapAddress}) {
    return (
      <div className="bg-[#f5f5f5]">
      <div className="w-full py-5 mx-auto md:my-[40px] my-[20px]">
                  <iframe
                      src={mapAddress}
                      style={{ border: 0, width: "100%", height: "450px"}}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                  />
              </div>
      </div>
    )
  }
  