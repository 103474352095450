import React from "react";
import { FaChevronRight } from "react-icons/fa";

const ProductNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="w-7 h-7 rounded-full text-[#33475B] bg-white opacity-70 hover:bg-black hover:text-white hover:bg-opacity-60 duration-300 cursor-pointer flex justify-center items-center z-10 absolute top-[35%] right-2"
      onClick={onClick}
    >
      <span className="">
        <FaChevronRight size={20}/>
      </span>
    </div>
  );
};

export default ProductNextArrow;

