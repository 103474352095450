import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import { FaDownload } from "react-icons/fa";
import Slider from "react-slick";
import ProductNextArrow from "./ProductNextArrow";
import ProductPrevArrow from "./ProductPrevArrow";
import RecommendedProducts from "./RecommendedProducts";
import { Dialog, DialogContent } from "@mui/material"; // Dialog components
import { TiTimes } from "react-icons/ti";
import Badge from "../../components/home/Products/Badge";

const ProductDetails = () => {
    const [prevLocation, setPrevLocation] = useState("");
    const [productInfo, setProductInfo] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [discountRatio, setDiscountRatio] = useState(null);
    const location = useLocation();
    const productCategoryId = productInfo?.categories?.[0]?.categoryId || null;

    useEffect(() => {
        setProductInfo(location?.state?.item);
        setPrevLocation(location.pathname);
    }, [location, productInfo?.ficheTech]);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };
    
    const tabs = [
        // {
        //     id: "Fiche Technique",
        //     label: "Fiche Technique",
        //     content: productInfo?.des,
        // },
        {
            id: "Description",
            label: "Ürün Açıklaması",
            content: productInfo?.des,
        },
        // {
        //     id: "Video",
        //     label: "Video",
        //     content: (
        //         <iframe
        //             width="560"
        //             height="315"
        //             src="https://www.youtube.com/embed/watch?v=6e0yIRDVPlA&list=RD6e0yIRDVPlA&start_radio=1"
        //             title="YouTube Video"
        //             frameBorder="0"
        //             allowFullScreen
        //         ></iframe>
        //     ),
        // },
    ];
    const [activeTab, setActiveTab] = useState(tabs[0].id);
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const arrImg = [
        {
            img: productInfo?.productImage1,
        },
        {
            img: productInfo?.productImage2,
        },
        {
            img: productInfo?.productImage3,
        },
    ];

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ProductNextArrow />,
        prevArrow: <ProductPrevArrow />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    };
    return (
        <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
            <div className="max-w-container mx-auto px-4">
                <div className="xl:-mt-10 -mt-7">
                    {/* <Breadcrumbs title="" prevLocation={prevLocation} /> */}
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4 pt-12">
                    <div className="h-full xl:col-span-2 relative">
                        <Slider {...settings}>
                            {productInfo?.img
                                ? productInfo?.img?.map((image, index) => (
                                      <div key={index} className="px-2 h-[400px] w-[500px]">
                                          <img
                                              onClick={() => handleImageClick(image.img)}
                                              className="w-full h-full object-cover cursor-pointer"
                                              src={image.img}
                                              alt=""
                                          />
                                      </div>
                                  ))
                                : arrImg?.map((img, index) => (
                                      <div key={index} className="px-2 h-[400px] w-[500px]">
                                          <img
                                              onClick={() => handleImageClick(img.img)}
                                              className="w-full h-full object-cover cursor-pointer"
                                              src={img.img}
                                              alt=""
                                          />
                                      </div>
                                  ))}
                        </Slider>

                        <div className="absolute top-6 left-20">
                            {/* {discountRatio && <Badge discount={discountRatio} />} */}
                        </div>
                    </div>
                    <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
                        <ProductInfo
                            // setDiscountRatio={setDiscountRatio}
                            productInfo={productInfo}
                            setProductInfo={setProductInfo}
                        />
                    </div>
                </div>
                <div>
                    <div className=" space-x-4  pt-4">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`${
                                    activeTab === tab.id
                                        ? "bg-primeColor text-white"
                                        : "bg-gray-200 text-gray-800"
                                } py-2 px-4  focus:outline-none rounded-md`}
                                onClick={() => handleTabClick(tab.id)}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>
                    <div className="my-4">
                        {tabs.map((tab) => (
                            <div key={tab.id} className={activeTab === tab.id ? "" : "hidden"}>
                                {tab.id === "Fiche Technique" && productInfo?.ficheTech ? (
                                    <div>
                                        <table className="table-auto w-full">
                                            <tbody>
                                                {productInfo?.ficheTech.map((row) => (
                                                    <tr key={row.label} className="bg-gray-100">
                                                        <td className="border px-4 py-2 font-semibold">
                                                            {row.label}
                                                        </td>
                                                        <td className="border px-4 py-2">
                                                            {row.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="my-4 flex justify-end">
                                            <button className="inline-flex items-center px-4 py-2 border border-gray-300 bg-blue-500 hover:bg-blue-600 text-white font-bodyFont">
                                                <FaDownload className="h-5 w-5 mr-2 text-white" />
                                                <a
                                                    href={productInfo?.pdf}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-white"
                                                >
                                                    Download PDF
                                                </a>{" "}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <p>{tab.content}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {/*burada productın categorisini prop geçip ona uygun verileri çekmen gerekli */}
                <RecommendedProducts productCategoryId={productCategoryId} />
            </div>
            <Dialog open={selectedImage !== null} onClose={() => setSelectedImage(null)}>
                <DialogContent>
                    <button
                        onClick={() => setSelectedImage(null)}
                        className="absolute top-0 right-0 p-2 m-4 bg-red-500  hover:bg-red-600 text-white rounded-full"
                    >
                        {" "}
                        <TiTimes size={20} />
                    </button>
                    <img
                        src={selectedImage}
                        alt="Full size"
                        className="md:w-[550px] md:h-[650px] w-[300px] h-[350px] p-0 m-0"
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ProductDetails;
