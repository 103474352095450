import React from "react";
import { FaChevronLeft } from "react-icons/fa";

const ProductPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="cursor-pointer w-14 h-14 rounded-full text-[#33475B] hover:bg-black hover:bg-opacity-40 duration-300  flex justify-center items-center absolute z-10 top-[35%] left-2"
      onClick={onClick}
    >
      <span className="text-xl">
        <FaChevronLeft size={35}/>
      </span>
    </div>
  );
};

export default ProductPrevArrow;
