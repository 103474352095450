import CryptoJS from "crypto-js";

const secretKey = "your-secret-key";


export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

// Şifre çözme fonksiyonu
export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const list = [
  {piece : 1},
  {piece : 2},
  {piece : 3},
  {piece : 4},
  {piece : 5},
  {piece : 6},
  {piece : 7},
  {piece : 8},
  {piece : 9},
  {piece : 10},
  {piece : 11},
  {piece : 12},
  {piece : 13},
  {piece : 14},
  {piece : 15},
  {piece : 16},
  {piece : 17},
  {piece : 18},
  {piece : 19},
  {piece : 20},
  {piece : 21},
  {piece : 22},
  {piece : 23},
  {piece : 24},
  {piece : 25},
  {piece : 26},
  {piece : 27},
  {piece : 28},
  {piece : 29},
  {piece : 30},
  {piece : 31},
  {piece : 32},
  {piece : 33},
  {piece : 34},
  {piece : 35},
  {piece : 40},
  {piece : 45},
  {piece : 50},
  {piece : 55},
  {piece : 60},
  

];
export const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidNumber = /^\d+$/.test(keyValue);
    if (!isValidNumber) {
        event.preventDefault();
    }
};

export const formatPrice = (price) => {
    if (price === undefined || price === null) {
      return "";
    }
    return new Intl.NumberFormat('tr-TR', {
    //   style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(price);
  };

export const convertTurkishChars = (str) => {
    if (typeof str !== 'string') {
        console.error('Input is not a string:', str);
        return '';
    }

    const turkishChars = {
        'ç': 'c', 'Ç': 'C',
        'ğ': 'g', 'Ğ': 'G',
        'ı': 'i', 'I': 'I',
        'İ': 'I', 'i': 'i',
        'ö': 'o', 'Ö': 'O',
        'ş': 's', 'Ş': 'S',
        'ü': 'u', 'Ü': 'U'
    };

    // Türkçe karakterleri dönüştür
    let convertedStr = str.split('').map(char => turkishChars[char] || char).join('');
    
    // Boşlukları tireye dönüştür
    convertedStr = convertedStr.replace(/\s+/g, '-');
    
    return convertedStr;
};
export const formatDate = (dateString) => {
    // Orijinal tarih stringini al
    const date = new Date(dateString);
  
    // Ay isimlerini içeren bir dizi oluştur
    const months = [
      'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
      'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];
  
    // Gün, ay ve yıl bilgilerini al
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    // İstenen formata dönüştür
    return `${day} ${month} ${year}`;
  }
export const truncatedText = (text, limit ) => {
    const truncatedText = text?.length > limit ? text?.substring(0, limit) + "..." : text;
    return truncatedText;
  };

  export const months = [
    { key : "01" , value : "01"},
    { key : "02" , value : "02"},
    { key : "03" , value : "03"},
    { key : "04" , value : "04"},
    { key : "05" , value : "05"},
    { key : "06" , value : "06"},
    { key : "07" , value : "07"},
    { key : "08" , value : "08"},
    { key : "09" , value : "09"},
    { key : "10" , value : "10"},
    { key : "11" , value : "11"},
    { key : "12" , value : "12"},
    ];

    export const years = [
        { key : "2024" , value : "2024"},
        { key : "2025" , value : "2025"},
        { key : "2026" , value : "2026"},
        { key : "2027" , value : "2027"},
        { key : "2028" , value : "2028"},
        { key : "2029" , value : "2029"},
        { key : "2030" , value : "2030"},
        { key : "2031" , value : "2031"},
        { key : "2032" , value : "2032"},
        { key : "2033" , value : "2033"},
        { key : "2034" , value : "2034"},
        { key : "2035" , value : "2035"},
        { key : "2036" , value : "2036"},
        { key : "2037" , value : "2037"},   
        { key : "2038" , value : "2038"},
    ]
