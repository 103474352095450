import React, { useEffect, useRef, useState } from "react";
import { BsGridFill } from "react-icons/bs";
import { ImList } from "react-icons/im";
import { IoFilterSharp } from "react-icons/io5";
import { useClickAway } from "react-use";
import { GoSearch } from "react-icons/go";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { addDealerSearch, changeStockCheckState } from "../../../redux/filters/filterSlice";

const ProductBanner = ({ itemsPerPageFromBanner, handleNav, nav }) => {
    const [girdViewActive, setGridViewActive] = useState(true);
    const [listViewActive, setListViewActive] = useState(false);
    const [stock, setStock] = useState(false);
    const [search, setSearch] = useState("");
    const [focus, setFocus] = useState(false);
    const ref = useRef();
    const dispatch = useDispatch();
    useClickAway(ref, () => {
        setFocus(false);
    });
    const handleStock = () => {
        setStock(!stock);
        dispatch(changeStockCheckState(!stock));
    };

    useEffect(() => {
        const gridView = document.querySelector(".gridView");
        const listView = document.querySelector(".listView");

        gridView.addEventListener("click", () => {
            setListViewActive(false);
            setGridViewActive(true);
        });
        listView.addEventListener("click", () => {
            setGridViewActive(false);
            setListViewActive(true);
        });
    }, [girdViewActive, listViewActive]);

    useEffect(() => {
        if (search?.length >= 4) {
            dispatch(addDealerSearch(search));
        } else if (search?.length === 0) {
            dispatch(addDealerSearch(search));
        }
    }, [search]);
    
    return (
        <div className="w-full flex flex-col md:flex-row md:items-center justify-between">
            <div className="flex items-center gap-4">
                <span
                    className={`${
                        girdViewActive
                            ? "bg-primeColor text-white"
                            : "border-[1px] border-gray-300 text-[#737373]"
                    } w-8 h-8 text-lg flex items-center justify-center cursor-pointer gridView`}
                >
                    <BsGridFill />
                </span>
                <span
                    className={`${
                        listViewActive
                            ? "bg-primeColor text-white"
                            : "border-[1px] border-gray-300 text-[#737373]"
                    } w-8 h-8 text-base flex items-center justify-center cursor-pointer listView`}
                >
                    <ImList />
                </span>
                <span
                    onClick={handleNav}
                    className="bg-primeColor text-white w-24 gap-1 h-8 text-base smd:hidden flex items-center justify-center cursor-pointer listView"
                >
                    <span>Filtrele</span>
                    <IoFilterSharp />
                </span>
                {/* <span className="flex items-center gap-2">
                <input 
                    type="checkbox" 
                    id="nav" 
                    checked={stock} 
                    onChange={handleStock} 
                    className="cursor-pointer w-6 h-6 checkbox"
                />
                <label htmlFor="nav" className="text-[#737373] cursor-pointer">Stokta olmayanları da ara</label>
            </span> */}
                <div className="form-control">
                    <label className="label cursor-pointer gap-1">
                        <input
                            checked={stock}
                            onChange={handleStock}
                            type="checkbox"
                            defaultChecked
                            className="checkbox cursor-pointer w-6 h-6"
                        />
                        <span className="label-text text-[#737373]">Stokta olanları ara</span>
                    </label>
                </div>
            </div>

            <div
                ref={ref}
                className="min-h-[2rem] h-[3.313rem] w-[300px] flex items-center justify-center bg-white z-10"
            >
                <label className="h-[2.688rem] rounded-full bg-[#edf0f3] w-full relative group border border-transparent focus-within:bg-[#F5F7FA] focus-within:border-[#1d9bf0] flex items-center">
                    <div className="w-[3.5rem] h-full flex items-center justify-center absolute top-0 left-0 pointer-events-none">
                        <GoSearch />
                    </div>
                    <input
                        type="text"
                        placeholder="Ara"
                        className="w-full h-full bg-transparent rounded-full outline-none pl-[3.5rem]"
                        value={search}
                        onFocus={() => setFocus(true)}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    {search && focus && (
                        <button
                            type="button"
                            onClick={() => setSearch("")}
                            className="w-[22px] h-[22px] rounded-full flex items-center justify-center text-black min-w-[22px] absolute top-1/2 -translate-y-1/2 right-3"
                        >
                            <ClearIcon size={20} />
                        </button>
                    )}
                </label>
            </div>

            <div className="flex items-center gap-2 md:gap-6 mt-4 md:mt-0">
                {/* <div className="flex items-center gap-2 text-base text-[#767676] relative">
          <label className="block">Sırala:</label>
          <select
            // onChange={(e) => setSelected(e.target.value)}
            id="countries"
            className="w-32 md:w-52 border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
          >
            <option value="Best Sellers">En Çok Satanlar</option>
            <option value="New Arrival">Yeni Eklenenler</option>
            <option value="Featured">Öne Çıkanlar</option>
          </select>
          <span className="absolute text-sm right-2 md:right-4 top-2.5">
            <GoTriangleDown />
          </span>
        </div> */}

                {/* <div className="flex items-center gap-2 text-[#767676] relative">
                    <label className="block">Göster:</label>
                    <select
                        onChange={(e) => itemsPerPageFromBanner(+e.target.value)}
                        id="countries"
                        className="w-16 md:w-20 border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
                    >
                        <option value="15">15</option>
                        <option value="24">24</option>
                        <option value="48">48</option>
                    </select>
                    <span className="absolute text-sm right-3 top-2.5">
                        <GoTriangleDown />
                    </span>
                </div> */}
            </div>
        </div>
    );
};

export default ProductBanner;
