import React, { useState } from "react";
import Breadcrumbs from "../../components/dealerProps/Breadcrumbs";
import Pagination from "../../components/dealerProps/shopPage/Pagination";
import ProductBanner from "../../components/dealerProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/dealerProps/shopPage/ShopSideNav";
import { Helmet } from "react-helmet";

const Dealer = () => {
  const [itemsPerPage, setItemsPerPage] = useState(48);
  const [nav, setNav] = useState(true);

  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Helmet title="B2B | Ürünler" />
      
      <div className="w-full h-full flex pb-20 pt-5 gap-10">
        <div
          className={
            !nav
              ? "fixed left-0 top-0 w-[65%] h-full mt-[75px] border-r p-2 border-r-gray-900 bg-[#FFFFFF] ease-in-out duration-500 z-30"
              : "fixed left-[-100%] ease-in-out duration-500 z-30"
          }
        >
          <ShopSideNav nav={nav} handleNav={handleNav}/>
        </div>
        <div className="w-full  h-full flex flex-col gap-10">
          <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} nav={nav} handleNav={handleNav} />
          <Pagination itemsPerPage={itemsPerPage} />
        </div>
      </div>
    </div>
  );
};

export default Dealer;
