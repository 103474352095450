import { toast } from "react-toastify";
import { deleteUser } from "../redux/user/actions";

function parseJSON(data) {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    return formData;
}

async function request(url, data = null, method = "GET", type = "FORM_DATA") {
    const options = {
        method,
        headers: {},
    };

    if (localStorage.getItem("token")) {
        options.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    }

    if (type === "JSON") {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(data);
    } else if (data) {
        options.body = parseJSON(data);
    }

    try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (result?.data?.statusCode === 401 || result?.statusCode === 401) {
            localStorage.clear();
            await deleteUser();
            setTimeout(() => {
                window.location.href = "/signin";
            }, 1000);
            return ;
        }
        
        return result;
    } catch (error) {
        console.error("Hata oluştu:", error);
        throw error;
    }
}

export const get = (url) => request(url);
export const post = (url, data) => request(url, data, "POST");
export const postJSON = (url, data) => request(url, data, "POST", "JSON");
export const put = (url, data) => request(url, data, "PUT");
export const putJSON = (url, data) => request(url, data, "PUT", "JSON");
export const del = (url) => request(url, null, "DELETE");
export const delJSON = (url, data) => request(url, data, "DELETE", "JSON");
export const patch = (url, data) => request(url, data, "PATCH");
export const patchJSON = (url, data) => request(url, data, "PATCH", "JSON");
export const head = (url) => request(url, null, "HEAD");
export const headJSON = (url, data) => request(url, data, "HEAD", "JSON");


