import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HttpsIcon from "@mui/icons-material/Https";
import LoginIcon from "@mui/icons-material/Login";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { login } from "../../services/authFetch";
import { setUser } from "../../redux/user/actions";
import { loginSchema } from "../../styles/YupSchemas";
import Cookies from "js-cookie";

const SignIn = () => {
    const [visible, setVisible] = useState(false);
    const [progress, setProgress] = useState(false);
    const [isRemember, setIsRemember] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            userName: Cookies.get("userName") || "",

            // userName: Cookies.get("userName") ? decryptData(Cookies.get("userName")) : "",
            // password: Cookies.get("password") ? decryptData(Cookies.get("password")) : "",
        },
    });

    const onSubmit = async (data) => {
        try {
            setProgress(true);
            const response = await login(data);
            // console.log(response);
            if (response?.data?.statusCode === 200) {
                setProgress(false);
                // console.log(response?.data  );
                const user = {
                    userName: response?.data?.userName,
                    email: response?.data?.email,
                    nameSurname: response?.data?.nameSurname,
                    role: response?.data?.roles?.[0],
                };
                await localStorage.setItem("token", response?.data?.token);
                await setUser(user);
                dispatch(showNotification({ type: "success", message: "Giriş Başarılı" }));
                if (isRemember) {
                    Cookies.set("userName", response?.data?.userName, { expires: 7 });
                } else {
                    Cookies.remove("userName");
                }
                if (
                    response?.data?.roles?.[0] === "bayi" ||
                    response?.data?.roles?.[0] === "bayiUser"
                ) {
                    navigate("/urunler");
                } else {
                    navigate("/");
                }
            } else if (response?.data?.statusCode === 400) {
                dispatch(
                    showNotification({ type: "error", message: response?.data?.statusMessage })
                );
            } else {
                dispatch(showNotification({ type: "error", message: response?.data?.message }));
            }
        } catch (Error) {
            console.log(Error);
        } finally {
            setProgress(false);
        }
    };
    return (
        <div className="w-full h-screen flex items-center justify-center pt-12">
            <div className="w-full h-full">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full mx-auto lgl:w-[450px] flex items-center justify-center"
                >
                    <div className="px-6 py-4 w-full h-[90%] shadow-lg rounded-md flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
                        <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                            Giriş Yap
                        </h1>
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col gap-.5">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="userName"
                                    label="Kullanıcı Adı"
                                    sx={{ color: "#b5cbd7" }}
                                    type={"text"}
                                    autoComplete="current-password"
                                    color="info"
                                    {...register("userName")}
                                    error={!!errors.userName}
                                    helperText={errors?.userName?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            {/* Password */}
                            <div className="flex flex-col gap-.5">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="password"
                                    label="Şifre"
                                    sx={{ color: "white" }}
                                    type={visible ? "text" : "password"}
                                    autoComplete="current-password"
                                    color="info"
                                    {...register("password")}
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <EndAdorment
                                                visible={visible}
                                                setVisible={setVisible}
                                            />
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HttpsIcon color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isRemember}
                                            onChange={(e) => setIsRemember(e.target.checked)}
                                            color="secondary"
                                        />
                                    }
                                    label="Beni Hatırla"
                                />
                                <NavLink to="/forgot-password">Şifremi Unuttum?</NavLink>
                            </Box>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mb: 2,
                                    textTransform: "capitalize",
                                    backgroundColor: "#000000",
                                    ":hover": { backgroundColor: "#262626" },
                                }}
                                // color="primary"
                                disabled={progress}
                                startIcon={
                                    progress ? (
                                        <CircularProgress color="inherit" size={"16px"} />
                                    ) : (
                                        <LoginIcon />
                                    )
                                }
                            >
                                Giriş Yap
                            </Button>
                            <div className="flex items-center justify-start">
                                <p className="text-sm text-center font-titleFont font-medium">
                                    Hesabın Yok Mu?{" "}
                                    <Link to="/signup">
                                        <span className="hover:text-blue-600 duration-300">
                                            Kayıt Ol
                                        </span>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;

const EndAdorment = ({ visible, setVisible }) => {
    return (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={() => setVisible(!visible)}
                onMouseDown={(e) => e.preventDefault()}
            >
                {visible ? <VisibilityIcon color="secondary" /> : <VisibilityOffIcon />}
            </IconButton>
        </InputAdornment>
    );
};
