// import React, { useState } from "react";
// // import { FaPlus } from "react-icons/fa";
// import { ImPlus } from "react-icons/im";
// import NavTitle from "./NavTitle";
// import { useDispatch, useSelector } from "react-redux";
// import { toggleCategory } from "../../../../redux/product/productSlice";

// const Category = () => {
//     const [showSubCatOne, setShowSubCatOne] = useState(false);
//     const [showCategories, setShowCategories] = useState(true);
//     const [showAllCategories, setShowAllCategories] = useState(false);

//     const checkedCategorys = useSelector((state) => state.product.checkedCategorys);
//     const categories = useSelector((state) => state.product.categories);
//     console.log("categories", categories);
//     const dispatch = useDispatch();

//     // const deneme = () => {
//     //   dispatch
//     // }
//     // const category = [
//     //   {
//     //     _id: 9006,
//     //     title: "Imprimante",
//     //   },
//     //   {
//     //     _id: 9007,
//     //     title: "Encre",
//     //   },
//     //   {
//     //     _id: 9008,
//     //     title: "Ruban",
//     //   },
//     //   {
//     //     _id: 9009,
//     //     title: "Bac de dechet",
//     //   },
//     //   {
//     //     _id: 9010,
//     //     title: "Ruban",
//     //   },
//     //   {
//     //     _id: 9011,
//     //     title: "Bac de dechet",
//     //   },
//     // ];

//     const handleToggleCategory = (category) => {
//         dispatch(toggleCategory(category));
//     };
//     const handleShowMore = () => {
//         setShowAllCategories(!showAllCategories);
//     };

//     return (
//         <div>
//             <div className="w-full">
//                 <div className="cursor-pointer" onClick={() => setShowCategories(!showCategories)}>
//                 <NavTitle  title="Shop by Category" icons={true} />
//                 </div>
//                 <div>
//                     {showCategories && (
//                         <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
//                             {categories
//                                 ?.slice(0, showAllCategories ? categories.length : 5)
//                                 .map((item) => (
//                                     <li

//                                         className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300"
//                                     >
//                                         <input
//                                             type="checkbox"
//                                             id={item._id}
//                                             checked={checkedCategorys?.some(
//                                                 (b) => b._id === item._id
//                                             )}
//                                             onChange={() => handleToggleCategory(item)}
//                                         />
//                                         {item.title}
//                                         {item.icons && (
//                                             <span
//                                                 onClick={() => setShowSubCatOne(!showSubCatOne)}
//                                                 className="text-[10px] lg:text-xs cursor-pointer text-gray-400 hover:text-primeColor duration-300"
//                                             >
//                                                 <ImPlus />
//                                             </span>
//                                         )}
//                                     </li>
//                                 ))}
//                             {categories.length > 5 && (
//                                 <button onClick={handleShowMore}>
//                                     {showAllCategories ? "Azalt" : "Daha Fazla Göster"}
//                                 </button>
//                             )}
//                         </ul>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Category;

// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { toggleCategory } from "../../../../redux/product/productSlice";
// import NavTitle from "./NavTitle";
// import CategoryTitle from "./CategoryTitle";

// const Category = () => {
//     const [showAllCategories, setShowAllCategories] = useState(false);
//     const [showSubCategories, setShowSubCategories] = useState(false);
//     const dispatch = useDispatch();
//     const categories = useSelector((state) => state.product.categories);
//     const checkedCategorys = useSelector((state) => state.product.checkedCategorys);
//     console.log("checkedCategorys", checkedCategorys);

//     const handleToggleCategory = (subcategory) => {
//         dispatch(toggleCategory(subcategory));
//     };

//     return (
//         <div>
//             <div
//                 className="cursor-pointer"
//                 onClick={() => setShowAllCategories(!showAllCategories)}
//             >
//                 <NavTitle title="Shop by Category" icons={true} />
//             </div>
//             {showAllCategories && (
//                 <ul>
//                     {categories.map((category) => (
//                         <li key={category.categoryId}>
//                             <div
//                                 // className="cursor-pointer"
//                                 // onClick={() => setShowSubCategories(!showSubCategories)}
//                             >
//                                 <CategoryTitle title={category.categoryName} icons={true} />
//                             </div>
//                             <ul>
//                                 { category.subCategories.map((subcategory) => (
//                                     <li key={subcategory.subCategoryId}>
//                                         <label className="gap-2">
//                                             <input
//                                                 type="checkbox"
//                                                 checked={checkedCategorys.some(
//                                                     (checked) =>
//                                                         checked.subCategoryId ===
//                                                         subcategory.subCategoryId
//                                                 )}
//                                                 onChange={() => handleToggleCategory(subcategory)}
//                                             />
//                                             {subcategory.subCategoryName}
//                                         </label>
//                                     </li>
//                                 ))}
//                             </ul>
//                         </li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     );
// };

// export default Category;

// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { toggleCategory } from "../../../../redux/product/productSlice";
// import NavTitle from "./NavTitle";
// import CategoryTitle from "./CategoryTitle";

// const Category = () => {
//     const [showAllCategories, setShowAllCategories] = useState(false);
//     const [showSubCategories, setShowSubCategories] = useState(false);
//     const dispatch = useDispatch();
//     const categories = useSelector((state) => state.product.categories);
//     console.log("categories", categories);
//     const checkedCategorys = useSelector((state) => state.product.checkedCategorys);
//     console.log("checkedCategorys", checkedCategorys);

//     const handleToggleCategory = (subcategory) => {
//         dispatch(toggleCategory(subcategory));
//     };

//     return (
//         <div>
//             <div
//                 className="cursor-pointer"
//                 onClick={() => setShowAllCategories(!showAllCategories)}
//             >
//                 <NavTitle title="Shop by Category" icons={true} />
//             </div>
//             {showAllCategories && (
//                 <ul>
//                     {categories?.map((category) => (
//                         <li key={category.categoryId}>
//                             <div
//                                 className="cursor-pointer"
//                                 onClick={() => setShowSubCategories(!showSubCategories)}
//                             >
//                                 <CategoryTitle title={category.categoryName} icons={true} />
//                             </div>
//                             <ul>
//                                 {showSubCategories && category?.subCategories?.map((subcategory) => (
//                                     <li key={subcategory.subCategoryId}>
//                                         <label className="gap-2">
//                                             <input
//                                                 type="checkbox"
//                                                 id={subcategory.subCategoryId}
//                                                 checked={checkedCategorys?.some(
//                                                     (checked) =>
//                                                         checked.subCategoryId ===
//                                                         subcategory.subCategoryId
//                                                 )}
//                                                 onChange={() => handleToggleCategory(subcategory)}
//                                             />
//                                             {subcategory.subCategoryName}
//                                         </label>
//                                     </li>
//                                 ))}
//                             </ul>
//                         </li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     );
// };

// export default Category;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCategory } from "../../../../redux/filters/filterSlice";
import NavTitle from "./NavTitle";
import CategoryTitle from "./CategoryTitle";

const Category = () => {
    const [showAllCategories, setShowAllCategories] = useState(true);
    const [openCategories, setOpenCategories] = useState([]); // State to track open categories
    const categories = useSelector((state) => state.product.categories);
    const checkedCategorys = useSelector((state) => state.filter.checkedCategorys);
    // const products = useSelector((state) => state.product.products);
    const dispatch = useDispatch();
    

    const handleToggleCategory = (categoryId) => {
        if (openCategories.includes(categoryId)) {
            setOpenCategories(openCategories.filter((id) => id !== categoryId)); // Close category
        } else {
            setOpenCategories([...openCategories, categoryId]); // Open category
        }
    };

    return (
        <div>
            <div
                className="cursor-pointer"
                onClick={() => setShowAllCategories(!showAllCategories)}
            >
                <NavTitle title="Kategoriye Göre Filtrele" icons={true} />
            </div>
            {showAllCategories && (
                <ul>
                    {categories?.map((category) => (
                        <li key={category.categoryId}>
                            <div
                                className="cursor-pointer"
                                onClick={() => handleToggleCategory(category.categoryId)}
                            >
                                <CategoryTitle title={category.categoryName} icons={true} />
                            </div>
                            <ul>
                                {openCategories.includes(category.categoryId) &&
                                    category?.subCategories?.map((subcategory) => (
                                        <li key={subcategory.subCategoryId}>
                                            <div className="form-control text-white">
                                                <label className="cursor-pointer label ">
                                                    <span className="label-text ">
                                                        {subcategory.subCategoryName}
                                                    </span>
                                                    <input
                                                        type="checkbox"
                                                        id={subcategory.subCategoryId}
                                                        checked={checkedCategorys?.some(
                                                            (checked) =>
                                                                checked.subCategoryId ===
                                                                subcategory.subCategoryId
                                                        )}
                                                        onChange={() =>
                                                            dispatch(toggleCategory(subcategory))
                                                        }
                                                        className="checkbox checkbox-info rounded-full"
                                                    />
                                                    {/* </div> */}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Category;
