import { postJSON, get } from './request';
import { BASEURL } from './baseURL';

export const sendMessage = (data) => postJSON(`${BASEURL}/contact/contactcreateMember`, data);
export const getProduct = (page, status) => get(`${BASEURL}/Bayi/Bayi/BayiGetAllProducts/${page}?stockStatus=${status}`);
export const searcProduct = (name,StatusStock) => get(`${BASEURL}/Bayi/Bayi/SearchGetBayiProducts?MalzemeAdi=${name}&StatusStock=${StatusStock}`);
export const createOrderDealer = (data) => postJSON(`${BASEURL}/Bayi/Bayi/BayiCreateOrders`, data);
export const stockOrderDealerRequest = (data) => postJSON(`${BASEURL}/Bayi/Bayi/BayiProductRequest`, data);





