import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { homeProductsFetch } from "../../../services/homeFetch";

const HomeProducts = () => {
    const [products, setProducts] = useState();
    const getProducts = async () => {
        try {
            const response = await homeProductsFetch();
            if (response?.data) setProducts(response?.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getProducts();
    }, []);
    

    return (
        <div className="w-full pb-20 pt-5">
            <Heading heading="Sana Özel Ürünler" />
            {products ? (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10 px-5">
                    {products?.slice(0,8).map((product, index) => (
                        <Product
                            key={index}
                            _id={product.productId}
                            img={[
                                { img: product.productImage1 },
                                { img: product.productImage2 },
                                { img: product.productImage3 },
                            ]}
                            productName={product.productName}
                            dimension={product.dimensions}
                            categories={product.categories}
                            badge={false}
                            des={product.productDescription}
                            {...product}
                        />
                    ))}
                </div>
            ) : (
                <>
                    <div className="flex items-center justify-center">
                        <span className="loading loading-dots loading-lg text-[#8B8B8B]"></span>
                    </div>
                </>
            )}
        </div>
    );
};

export default HomeProducts;
