import React, { useEffect, useState } from "react";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useParams } from "react-router-dom";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import { useSelector } from "react-redux";

const Offer = () => {
  const [prevLocation] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const categories = useSelector((state) => state.product.categories);
  const [name, setName] = useState("Kategori")
  
  // const itemsPerPageFromBanner = (itemsPerPage) => {
  //   setItemsPerPage(itemsPerPage);
  // };
  const { id } = useParams();
useEffect(() => {
  if(id) {
    const category = categories?.find((category) => category.categoryId === +id);
    setName(category?.categoryName)
  }
}, [id])

  
  return (
    // <div className="max-w-container mx-auto">
    //   <Breadcrumbs title={category} prevLocation={prevLocation} />
    //   <div className="pb-10">
    //     <SpecialOffers />
    //   </div>
    // </div>

    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title={name} prevLocation={prevLocation} />
      <div className="w-full h-full flex pb-20 gap-10">
        {/* <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <ShopSideNav />
        </div> */}
        <div className="w-full  h-full flex flex-col gap-10">
          <SpecialOffers itemsPerPage={itemsPerPage} />
          
        </div>
      </div>
    </div>
  );
};

export default Offer;
