import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import AddCardIcon from "@mui/icons-material/AddCard";
import React, { useEffect, useState } from "react";
import CreateAddressModal from "../../components/modal/CreateAddressModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddress } from "../../services/getAllList";
import { useLocation } from "react-router-dom";
import PaymentAddress from "../../components/payment/PaymentAddress";
import PaymentOrderForm from "../../components/payment/PaymentOrderForm";

const Payment = () => {
    const [value, setValues] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [productInfo, setProductInfo] = useState(null);
    const [selectedInstallment, setSelectedInstallment] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const location = useLocation();
    const address = useSelector((state) => state.product.address);
    const dispatch = useDispatch();
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenModal = () => setOpenModal(true);
    
    useEffect(() => {
        setProductInfo(location?.state?.item);
    }, [location]);

    useEffect(() => {
        fetchAddress(dispatch);
    }, []);

    const handleChange = (event, newValue) => {
        setValues(newValue);
    };
    const handleCheckboxChange = (event, addressId) => {
        if (event.target.checked) {
            setSelectedAddressId(addressId);
        } else {
            setSelectedAddressId(null);
        }
    };
    const handleInstallmentChange = (event, id) => {
        if (event.target.checked) {
            setSelectedInstallment(id);
        } else {
            setSelectedInstallment(null);
        }
    };
    const updateAddress = (id) => {
        let data = address?.find((item) => item?.userAdressId === id);
        if (data) {
            setSelectedAddress(data);
            handleOpenModal();
        }
    };
    useEffect(() => {
     if(selectedAddressId){
        setValues(1)
     }
    }, [selectedAddressId])
    
// console.log(value);
    return (
        <div className="max-w-container mx-auto px-4">
            <CreateAddressModal
                open={openModal}
                handleClose={handleCloseModal}
                address={selectedAddress}
                update={true}
            />
            <Breadcrumbs title="Ödeme Yap" />
            <div className="pb-10">
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            centered
                            aria-label="basic tabs example"
                        >
                            <Tab
                                className="text-xl font-semibold"
                                label="Adres Bilgileri"
                                icon={<HomeIcon />}
                                iconPosition="start"
                                {...a11yProps(0)}
                            />
                            <Tab
                                className="text-xl font-semibold"
                                label="Ödeme Bilgileri"
                                icon={<AddCardIcon />}
                                iconPosition="start"
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <PaymentAddress
                            updateAddress={updateAddress}
                            handleCheckboxChange={handleCheckboxChange}
                            selectedAddressId={selectedAddressId}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <PaymentOrderForm
                            selectedAddressId={selectedAddressId}
                            selectedInstallment={selectedInstallment}
                            productInfo={productInfo}
                            handleInstallmentChange={handleInstallmentChange}
                        />
                    </CustomTabPanel>
                </Box>
            </div>
        </div>
    );
};

export default Payment;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
