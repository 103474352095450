export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: `2px solid #2196f3`,
    boxShadow: 24,
    p: 2,
    borderRadius: 2,
    maxHeight: "90vh",
    overflowY: "auto",
};
