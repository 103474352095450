import React, { useEffect, useState } from "react";
import { BsGridFill } from "react-icons/bs";
import { ImList } from "react-icons/im";
import { GoTriangleDown } from "react-icons/go";
import { IoFilterSharp } from "react-icons/io5";

const ProductBanner = ({ itemsPerPageFromBanner, handleNav, nav }) => {
    //   const [selected, setSelected] = useState("");
    const [girdViewActive, setGridViewActive] = useState(true);
    const [listViewActive, setListViewActive] = useState(false);

    useEffect(() => {
        const gridView = document.querySelector(".gridView");
        const listView = document.querySelector(".listView");

        gridView.addEventListener("click", () => {
            setListViewActive(false);
            setGridViewActive(true);
        });
        listView.addEventListener("click", () => {
            setGridViewActive(false);
            setListViewActive(true);
        });
    }, [girdViewActive, listViewActive ]);

    return (
        <div className="w-full flex flex-col md:flex-row md:items-center justify-between">

            <div className="flex items-center gap-4">
                <span
                    className={`${
                        girdViewActive
                            ? "bg-primeColor text-white"
                            : "border-[1px] border-gray-300 text-[#737373]"
                    } w-8 h-8 text-lg flex items-center justify-center cursor-pointer gridView`}
                >
                    <BsGridFill />
                </span>
                <span
                    className={`${
                        listViewActive
                            ? "bg-primeColor text-white"
                            : "border-[1px] border-gray-300 text-[#737373]"
                    } w-8 h-8 text-base flex items-center justify-center cursor-pointer listView`}
                >
                    <ImList />
                </span>
                <span
                    onClick={handleNav}
                    className="bg-primeColor text-white w-24 gap-1 h-8 text-base smd:hidden flex items-center justify-center cursor-pointer listView"
                >
                  <span>Filtrele</span>
                    <IoFilterSharp />
                </span>
                {/* <div
                    onClick={handleNav}
                    className="mt-5 w-24 h-8 smd:hidden inline-flex cursor-pointer rounded-md text-gray-200 bg-primeColor hover:bg-black hover:text-white font-titleFont text-base tracking-wide font-semibold duration-200 sm:items-center sm:justify-center gap-2"
                >
                    <IoFilterSharp />
                </div> */}

            </div>
            <div className="flex items-center gap-2 md:gap-6 mt-4 md:mt-0">
                {/* <div className="flex items-center gap-2 text-base text-[#767676] relative">
          <label className="block">Sırala:</label>
          <select
            // onChange={(e) => setSelected(e.target.value)}
            id="countries"
            className="w-32 md:w-52 border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
          >
            <option value="Best Sellers">En Çok Satanlar</option>
            <option value="New Arrival">Yeni Eklenenler</option>
            <option value="Featured">Öne Çıkanlar</option>
          </select>
          <span className="absolute text-sm right-2 md:right-4 top-2.5">
            <GoTriangleDown />
          </span>
        </div> */}
                <div className="flex items-center gap-2 text-[#767676] relative">
                    <label className="block">Göster:</label>
                    <select
                        onChange={(e) => itemsPerPageFromBanner(+e.target.value)}
                        id="countries"
                        className="w-16 md:w-20 border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block appearance-none focus-within:outline-none focus-visible:border-primeColor"
                    >
                        <option value="15">15</option>
                        <option value="24">24</option>
                        <option value="48">48</option>
                    </select>
                    <span className="absolute text-sm right-3 top-2.5">
                        <GoTriangleDown />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ProductBanner;
