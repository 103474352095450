import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { GrLinkPrevious } from "react-icons/gr";
import { GrLinkNext } from "react-icons/gr";
import { addDealerProducts } from "../../../redux/product/productSlice";
import { getProduct, searcProduct } from "../../../services/dealerFetch";
import CreateOrderModal from "../../modal/CreateOrderModal";
import StockRequestModal from "../../modal/StockRequestModal";

function Items({ currentItems }) {
    const [id, setId] = useState(null);
    const [stock, setStock] = useState();
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [openStockModal, setOpenStockModal] = useState(false);
    const handleCloseOrderModal = () => setOpenOrderModal(false);
    const handleOpenOrderModal = () => setOpenOrderModal(true);

    const handleCloseStockModal = () => setOpenStockModal(false);
    const handleOpenStockModal = () => setOpenStockModal(true);

    // console.log(currentItems)
    return (
        <>
            {!currentItems?.length > 0 ? (
                <div className="flex items-center justify-center">Ürün Yok </div>
            ) : (
                <div className="grid lgl:grid-cols-2 gap-4">
                    <CreateOrderModal
                        open={openOrderModal}
                        handleClose={handleCloseOrderModal}
                        id={id}
                        stock={stock}
                        currentItems={currentItems}
                    />
                    <StockRequestModal
                        open={openStockModal}
                        handleClose={handleCloseStockModal}
                        id={id}
                    />
                    {currentItems?.map((item, i) => (
                        <div key={i} className="w-full my-1">
                            <div className="mx-auto">
                                <div className="card sm:card-side bg-base-100 shadow-xl h-44">
                                    <figure className="w-1/5">
                                        <img
                                            className="object-cover  h-full rounded-md"
                                            src={item.productImage}
                                            alt="Album"
                                        />
                                    </figure>
                                    <div className="card-body w-3/5 lgl:w-4/5 bg-[#F5F7FA] relative">
                                        <div className="">
                                            <h2
                                                className="text-base lg:text-base"
                                                style={{
                                                    wordWrap: "break-word",
                                                }}
                                            >
                                                {item.malzemeAdi}
                                            </h2>
                                            <div className="pt-1">Ebat: {" "}
                                            <div className="badge badge-warning gap-2">
                                            {item.size}
                                            </div>
                                            </div>
                                            <div className="pt-1">Stok: {" "}
                                            <div className="badge badge-warning gap-2">
                                            {item.stock.stok_Miktari === 0 ? "Stokta Yok" : item.stock.stok_Miktari}
                                            </div>
                                            </div>
                                            <div className="pt-1">Depo Adı: {" "}
                                            <div className="badge badge-warning gap-2">
                                            {item.depoAdi}
                                            </div>
                                            </div>
                                        </div>
                                        <div className="card-actions items-end justify-end absolute right-2 bottom-4">
                                            {item?.stock?.stok_Miktari !== 0 ? (
                                                <button
                                                    onClick={() => {
                                                        setStock(item.stock?.stok_Miktari);
                                                        setId(item.productDimensionId);
                                                        handleOpenOrderModal();
                                                    }}
                                                    className="bg-[#1F2937] max-md:mt-1 rounded-md cursor-pointer hover:bg-black active:bg-gray-900 max-md:p-2 px-4 py-2 font-titleFont font-semibold max-md:text-sm text-lg text-gray-200 hover:text-white gap-2 duration-300 flex items-center justify-center"
                                                >
                                                    <span>Sipariş Ver</span>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        setId(item.productDimensionId);
                                                        handleOpenStockModal();
                                                    }}
                                                    className="bg-[#1F2937] max-md:mt-1 rounded-md cursor-pointer hover:bg-black active:bg-gray-900 max-md:p-2 px-4 py-2 font-titleFont font-semibold max-md:text-sm text-lg text-gray-200 hover:text-white gap-2 duration-300 flex items-center justify-center"
                                                >
                                                    <span>Stok Bildirimi Al</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

const Pagination = ({ itemsPerPage = 30 }) => {
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(1);
    const dispatch = useDispatch();
    const currentItems = useSelector((state) => state.product.dealerProducts);
    const dealerSearchProducts = useSelector((state) => state.filter.dealerSearchProducts);
    const stockCheckState = useSelector((state) => state.filter.stockCheckState);

    const fetchItems = async (page) => {
        setLoading(true);
        try {
            const response = await getProduct(page, stockCheckState);
            // console.log(response);
            if (response?.data) {
                const newRows = response?.data?.map((product) => {
                    const productDimension = product.productDimensions[0];
                    // console.log(productDimension?.desen);
                    return {
                        productImage: productDimension?.desen?.desenImg,
                        malzemeKodu: productDimension?.malzemeKodu,
                        malzemeAdi: product?.malzemeAdi,
                        productDimensionId: productDimension?.productDimensionId,
                        stock: productDimension?.stock,
                        depoAdi : productDimension?.stock?.depo?.depoAdi,
                        size: productDimension?.size,
                    };
                });

                dispatch(addDealerProducts(newRows));

                setPageCount(response?.totalPageCount);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);

            console.error("Error fetching products:", error);
        }
    };

    useEffect(() => {
        fetchItems(currentPage, stockCheckState);
    }, [currentPage, stockCheckState]);

    const fetchSearchItems = async (data) => {
        setLoading(true);
        try {
            const response = await searcProduct(data, stockCheckState);
            console.log(response);
            if (response) {
                const newRows = response?.map((product) => {
                    const productDimension = product.productDimensions[0];
                    return {
                        productImage: product?.productImage1,
                        malzemeKodu: productDimension?.malzemeKodu,
                        malzemeAdi: product?.malzemeAdi,
                        productDimensionId: productDimension?.productDimensionId,
                        stock: productDimension?.stock,
                        size: productDimension?.size,
                        depoAdi : productDimension?.stock?.depo?.depoAdi,

                    };
                });

                dispatch(addDealerProducts(newRows));
                setPageCount(response?.totalPageCount);
                setLoading(false);
            } else if (response?.length === 0) {
                console.log("object");
                setLoading(false);
                dispatch(addDealerProducts([]));
            }
        } catch (error) {
            setLoading(false);

            console.error("Error fetching products:", error);
        }
    };

    useEffect(() => {
        if (dealerSearchProducts.length >= 4) fetchSearchItems(dealerSearchProducts);
        else if (dealerSearchProducts.length === 0) fetchItems(currentPage);
    }, [dealerSearchProducts]);

    const handlePageClick = (event) => {
        const newPage = event.selected + 1;
        setCurrentPage(newPage);
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };
    // console.log(pageCount)
    return (
        <div>
            <div className="grid">
                {loading ? (
                    <div className="mx-auto">
                        <span className="loading loading-dots loading-lg"></span>
                    </div>
                ) : (
                    <Items currentItems={currentItems} />
                )}
            </div>
            <div className="flex flex-col mdl:flex-row justify-between items-center">
                <button onClick={handlePreviousClick} className="p-1">
                    <span className="border-[1px] border-lightColor p-2 hover:border-gray-600 duration-300 flex justify-center items-center rounded-xl">
                        <GrLinkPrevious />
                    </span>
                </button>
                <ReactPaginate
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    forcePage={currentPage - 1}
                    pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-600 duration-300 flex justify-center items-center rounded-xl mx-1"
                    pageClassName="mr-6"
                    containerClassName="flex text-base font-semibold font-titleFont py-10"
                    activeClassName="bg-black text-white rounded-xl"
                    breakClassName="mx-1"
                    previousLabel={null}
                    nextLabel={null}
                />
                <button onClick={handleNextClick} className="p-1 ">
                    <span className="border-[1px] p-2 border-lightColor hover:border-gray-600 duration-300 flex justify-center items-center rounded-xl">
                        <GrLinkNext color="white" />
                    </span>
                </button>
                {/* <p className="text-base font-normal text-lightText mx-1">
                    {items?.length} Ürün içinden {items?.length} ürün gösteriliyor
                </p> */}
            </div>
        </div>
    );
};

export default Pagination;
