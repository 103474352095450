// import React, { useEffect } from "react";
// import Banner from "../../components/Banner/Banner";
// import BannerBottom from "../../components/Banner/BannerBottom";
// import HomeProducts from "../../components/home/HomeProducts/HomeProducts";
// import Sale from "../../components/home/Sale/Sale";
// import YearProduct from "../../components/home/YearProduct/YearProduct";
// import { useDispatch } from "react-redux";
// import { fetchBasketList, fetchCategories, fetchFavorites, fetchProducts, fetchStocks } from "../../services/getAllList";
// import { Helmet } from "react-helmet";
// import { useUser } from "../../redux/user/hooks";

// const Home = () => {
//     const dispatch = useDispatch();
//     const user = useUser();
    
//     const fetchData = async () => {
//         const promises = [fetchProducts(dispatch), fetchCategories(dispatch)];
//         if (user?.userName) {
//             promises.push(fetchStocks(dispatch));
//             promises.push(fetchBasketList(dispatch)); 
//             promises.push(fetchFavorites(dispatch));
//         }
//         await Promise.all(promises);
//     };

//     useEffect(() => {
//         fetchData();
//     }, [dispatch, user?.userName]);

//     return (
//         <div className="w-full mx-auto">
//             <Helmet title="Anasayfa" />
//             <Banner />
//             <BannerBottom />
//             <div className="max-w-container mx-auto px-4">
//                 {/* <NewArrivals /> bu kısım ürün detayda alt kısımda çıkacak */}
//                 <Sale />
//                 <HomeProducts />
//                 {/* <YearProduct /> */}
//                 {/* <SpecialOffers /> */}
//             </div>
//         </div>
//     );
// };

// export default Home;


import React, { useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import HomeProducts from "../../components/home/HomeProducts/HomeProducts";
import Sale from "../../components/home/Sale/Sale";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import { useDispatch } from "react-redux";
import { fetchBasketList, fetchCategories, fetchFavorites, fetchProducts, fetchStocks } from "../../services/getAllList";
import { Helmet } from "react-helmet";
import { useUser } from "../../redux/user/hooks";

const Home = () => {
    const dispatch = useDispatch();
    const user = useUser();

    const fetchData = async () => {
        const commonPromises = [fetchProducts(dispatch), fetchCategories(dispatch)];
        const userPromises = user?.userName 
            ? [fetchStocks(dispatch), fetchBasketList(dispatch), fetchFavorites(dispatch)] 
            : [];
        await Promise.allSettled([...commonPromises, ...userPromises]);
    };

    useEffect(() => {
        fetchData();
    }, [dispatch, user?.userName]);

    return (
        <div className="w-full mx-auto">
            <Helmet title="Anasayfa" />
            <Banner />
            <BannerBottom />
            <div className="max-w-container mx-auto px-4">
                <Sale />
                <HomeProducts />
            </div>
        </div>
    );
};

export default Home;
