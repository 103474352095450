import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HttpsIcon from "@mui/icons-material/Https";
import LoginIcon from "@mui/icons-material/Login";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { userRegister } from "../../services/authFetch";
import InputMask from "react-input-mask";
import { registerSchema } from "../../styles/YupSchemas";

const SignUp = () => {
    const [visible, setVisible] = useState(false);
    const [visibleR, setVisibleR] = useState(false);
    const [progress, setProgress] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(registerSchema),
    });

    const onSubmit = async (data) => {
        try {
            const processedNumber = data.phoneNumber.replace(/[\(\)\s-]/g, "");
            const obj = {
                phoneNumber: "+90" + processedNumber,
                email: data.email,
                password: data.password,
                userName: data.userName,
                name: data.name,
                surname: data.surname,
            };

            setProgress(true);
            const response = await userRegister(obj);
            if (response?.data?.statusCode === 200) {
                setProgress(false);
                dispatch(showNotification({ type: "success", message: "Kayıt Başarılı" }));
                navigate("/signin");
            } else if (response?.data?.statusCode === 400) {
                setProgress(false);
                dispatch(
                    showNotification({ type: "error", message: response?.data?.statusMessage })
                );
            } else {
                dispatch(showNotification({ type: "error", message: response?.message }));
            }
        } catch (Error) {
            dispatch(showNotification({ type: "error", message: "Bir Hata Oluştu!" }));
        } finally {
            setProgress(false);
        }
    };
    return (
        <div className="w-full h-screen flex items-center justify-center">
            <div className="w-full h-full">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full mx-auto lgl:w-[450px] pt-10 flex items-center justify-center"
                >
                    <div className="px-6 py-4 w-full h-[90%] flex flex-col justify-center shadow-lg rounded-md overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
                        <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                            Kayıt Ol
                        </h1>
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col gap-.5">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    label="Email"
                                    sx={{ color: "#b5cbd7" }}
                                    type={"email"}
                                    autoComplete="current-password"
                                    color="info"
                                    {...register("email")}
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div className="flex gap-2">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="name"
                                    label="Adı"
                                    sx={{ color: "white" }}
                                    type={"name"}
                                    autoComplete="surname"
                                    color="info"
                                    {...register("name")}
                                    error={!!errors.name}
                                    helperText={errors?.name?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="surname"
                                    label="Soyadı"
                                    sx={{ color: "white" }}
                                    type={"surname"}
                                    autoComplete="surname"
                                    color="info"
                                    {...register("surname")}
                                    error={!!errors.surname}
                                    helperText={errors?.surname?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="flex gap-2">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="userName"
                                    label="Kullanıcı Adı"
                                    sx={{ color: "white" }}
                                    type={"userName"}
                                    autoComplete="userName"
                                    color="info"
                                    {...register("userName")}
                                    error={!!errors.userName}
                                    helperText={errors?.userName?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <InputMask mask="(999) 999-9999" {...register("phoneNumber")}>
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            margin="normal"
                                            fullWidth
                                            id="phoneNumber"
                                            label="Telefon Numarası"
                                            name="startDate"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            autoComplete="phoneNumber"
                                            type=""
                                            {...register("phoneNumber")}
                                            error={!!errors.phoneNumber}
                                            helperText={errors?.phoneNumber?.message}
                                        />
                                    )}
                                </InputMask>
                            </div>
                            <div className="flex flex-col gap-.5">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="password"
                                    label="Şifre"
                                    sx={{ color: "white" }}
                                    type={visible ? "text" : "password"}
                                    autoComplete="password"
                                    color="info"
                                    {...register("password")}
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <EndAdorment
                                                visible={visible}
                                                setVisible={setVisible}
                                            />
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HttpsIcon color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="flex flex-col gap-.5">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="passwordR"
                                    label="Şifre Tekrar"
                                    sx={{ color: "white" }}
                                    type={visibleR ? "text" : "password"}
                                    autoComplete="password"
                                    color="info"
                                    {...register("passwordR")}
                                    error={!!errors.passwordR}
                                    helperText={errors?.passwordR?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <EndAdorment
                                                visible={visibleR}
                                                setVisible={setVisibleR}
                                            />
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HttpsIcon color="#000000" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    textTransform: "capitalize",
                                    backgroundColor: "#000000",
                                    ":hover": { backgroundColor: "#262626" },
                                }}
                                // color="primary"
                                disabled={progress}
                                startIcon={
                                    progress ? (
                                        <CircularProgress color="inherit" size={"16px"} />
                                    ) : (
                                        <LoginIcon />
                                    )
                                }
                            >
                                Kayıt Ol
                            </Button>
                            <p className="text-sm text-center font-titleFont font-medium">
                                Hesabın Var Mı?{" "}
                                <Link to="/signin">
                                    <span className="hover:text-blue-600 duration-300">
                                        Giriş Yap
                                    </span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUp;

const EndAdorment = ({ visible, setVisible }) => {
    return (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={() => setVisible(!visible)}
                onMouseDown={(e) => e.preventDefault()}
            >
                {visible ? <VisibilityIcon color="secondary" /> : <VisibilityOffIcon />}
            </IconButton>
        </InputAdornment>
    );
};
