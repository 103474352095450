import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { emptyCart } from "../../assets/images/index";
import ItemCard from "./ItemCard";
import { SlBasketLoaded } from "react-icons/sl";
import { fetchStocks } from "../../services/getAllList";
import { Helmet } from "react-helmet";

const Stock = () => {
    const dispatch = useDispatch();
    const stocks = useSelector((state) => state.product.stock);
    const [totalAmt, setTotalAmt] = useState("");
    const [shippingCharge, setShippingCharge] = useState("");

    useEffect(() => {
        let price = 0;
        stocks?.map((item) => {
            price += item.price * item.quantity;
            return price;
        });
        setTotalAmt(price);
    }, [stocks]);

    useEffect(() => {
        fetchStocks(dispatch);
    }, []);

    useEffect(() => {
        if (totalAmt <= 200) {
            setShippingCharge(30);
        } else if (totalAmt <= 400) {
            setShippingCharge(25);
        } else if (totalAmt > 401) {
            setShippingCharge(20);
        }
    }, [totalAmt]);
    return (
        <div className="max-w-container mx-auto px-4">
            <Helmet title="B2B | Stok" />
            <Breadcrumbs title="Stok Durum" />
            {stocks?.length > 0 ? (
                <div className="pb-20">
                    <div className="rounded-md w-full h-20 bg-[#33475B] text-white hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold">
                        <h2 className="col-span-2">Stokta Olmayan Ürünlerim</h2>
                        <h2>Ebat</h2>
                        <h2>Stok Durumu</h2>
                        <h2>Fiyat</h2>
                    </div>
                    <div className="mt-5">
                        {stocks?.map((item) => (
                            <div key={item._id}>
                                <ItemCard item={item} />
                            </div>
                        ))}
                    </div>

                  
                    <div className="max-w-7xl gap-4 flex justify-end mt-4">
                      
                    </div>
                </div>
            ) : (
                <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
                >
                    <div>
                        <img
                            className="w-80 rounded-lg p-4 mx-auto"
                            src={emptyCart}
                            alt="emptyCart"
                        />
                    </div>
                    <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
                        <h1 className="font-titleFont text-xl font-bold uppercase">
                            Stok Takibin Boş
                        </h1>
                      
                        <Link to="/urunle">
                            <button className="flex items-center justify-center  gap-3 bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                                Alışverişe Devam et
                                <SlBasketLoaded size={20} />
                            </button>
                        </Link>
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default Stock;
