import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { SplOfferData } from "../../../constants";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const SpecialOffers = () => {
  //burada kategoriyi alıp filtreleme yapıcaz cektiğimiz veriler içeriisnden veya fetch işlemi yaptıracağız buradan o datayı producta mapleyeceğiz
  const {id} = useParams();
  const products = useSelector((state) => state.product.products);
  const filteredProducts = products?.filter((product) =>
    product?.categories?.some((category) =>
      category?.subCategories?.some((subcategory) => subcategory.subCategoryId === parseInt(id))
    )
  );

  return (
    <div className="w-full pb-20">
      <Heading heading="Kategoriye Özel Ürünler" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-4 xl:grid-cols-4 gap-10 px-5">
        {filteredProducts?.map((data,index) => (
          <Product
          key={index}
          _id={data.productId}
          img={[
              { img: data.productImage1 },
              { img: data.productImage2 },
              { img: data.productImage3 },
          ]}
          productName={data.productName}
          price="35.00"
          dimension={data.dimensions}
          categories={data.categories}
          // color="Blank and White"
          badge={false}
          des={data.productDescription}
          {...data}
      />
        ))}
      </div>
    </div>
  );
};

export default SpecialOffers;
