export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
export const getComparator = (order, orderBy) => {
    return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
// export const descendingComparator = (a, b, orderBy) => {
//     // Önce değerlerin null veya undefined olup olmadığını kontrol edin
//     const aValue = a[orderBy] || '';
//     const bValue = b[orderBy] || '';
  
//     // Karşılaştırmayı yapın
//     if (bValue.localeCompare(aValue, 'tr') === -1) {
//       return -1;
//     }
//     if (bValue.localeCompare(aValue, 'tr') === 1) {
//       return 1;
//     }
//     return 0;
//   }

function descendingComparator(a, b, orderBy) {
    // Değerlerin null veya undefined olup olmadığını kontrol edin ve varsayılan bir değer kullanın
    const aValue = a[orderBy] !== undefined && a[orderBy] !== null ? a[orderBy] : '';
    const bValue = b[orderBy] !== undefined && b[orderBy] !== null ? b[orderBy] : '';
  
    // Sayısal veya dize karşılaştırma yapın
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      // Dize karşılaştırma
      if (bValue.localeCompare(aValue, 'tr') === -1) {
        return -1;
      }
      if (bValue.localeCompare(aValue, 'tr') === 1) {
        return 1;
      }
      return 0;
    } else {
      // Sayısal karşılaştırma
      return bValue - aValue;
    }
  }
