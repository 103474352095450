// /* eslint-disable react/prop-types */
// import Backdrop from "@mui/material/Backdrop";
// import Modal from "@mui/material/Modal";
// import Fade from "@mui/material/Fade";
// import propTypes from "prop-types";
// import { useState } from "react";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import LinearProgress from "@mui/material/LinearProgress";
// import { showNotification } from "../../redux/notifications/notificationSlice";
// import { useForm } from "react-hook-form";
// import { useDispatch } from "react-redux";
// import {
//     Box,
//     Button,
//     TextField,
//     Typography,
//     IconButton,
//     Stack,
//     FormControl,
//     InputLabel,
//     FormHelperText,
// } from "@mui/material";
// import { Grid } from "@mui/material";
// import { modalStyle } from "../../styles/modalStyle";
// import { createDealerOrderSchema } from "../../styles/YupSchemas";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import InputMask from "react-input-mask";

// import { list } from "../../constants/generalFunction";
// import { createOrderDealer } from "../../services/dealerFetch";

// const CreateOrderModal = ({ open, handleClose, id, stock }) => {
//     const [progress, setProgress] = useState(false);

//     const dispatch = useDispatch();
//     const theme = useTheme();

//     const {
//         register,
//         handleSubmit,
//         setValue,
//         reset,
//         watch,
//         formState: { errors },
//     } = useForm({
//         resolver: yupResolver(createDealerOrderSchema),
//     });

//     const quantityValue = watch("quantity");

//     const onSubmit = async (data) => {
//         console.log(data);
//         try {
//             if (stock < data.quantity) {
//                 dispatch(
//                     showNotification({
//                         message: `Ürün Stoğu Yetersiz. Maksimum alabileceğiniz adet sayısı: ${stock}`,
//                         type: "error",
//                     })
//                 );
//             } else {
//                 const obj = {
//                     productDimensionsId: id,
//                     quantity: data.quantity,
//                     userId: null,
//                 };
//                 setProgress(true);
//                 const response = await createOrderDealer(obj);
//                 if (response?.data?.statusCode === 200) {
//                     dispatch(
//                         showNotification({
//                             message: "Sipariş Verildi.",
//                             type: "success",
//                         })
//                     );
//                 } else {
//                     dispatch(
//                         showNotification({
//                             message: "Sipariş Oluşturulurken Bir Hata Oluştu",
//                             type: "info",
//                         })
//                     );
//                 }
//             }
//         } catch (error) {
//             console.log(error);
//             dispatch(
//                 showNotification({
//                     message: "Bayi Eklenirken Bir Hata Oluştu",
//                     type: "error",
//                 })
//             );
//         } finally {
//             setProgress(false);
//             handleClose();
//             reset();
//         }
//     };

//     return (
//         <Box>
//             <Modal
//                 aria-labelledby="create-assignments-modal-title"
//                 aria-describedby="create-assignments-modal-description"
//                 open={open}
//                 closeAfterTransition
//                 slots={{ backdrop: Backdrop }}
//                 slotProps={{
//                     backdrop: {
//                         timeout: 500,
//                     },
//                 }}
//             >
//                 <Fade in={open}>
//                     <Box sx={modalStyle} width={"50%"}>
//                         <Box className="flex justify-between items-center">
//                             <Typography
//                                 id="create-assignments-modal-title"
//                                 variant="h6"
//                                 component="h2"
//                                 // color={theme.palette.primary.light}
//                                 sx={{ color: "#000000"}}
//                             >
//                                 Sipariş Ver
//                             </Typography>
//                             <IconButton
//                                 aria-label="exit"
//                                 size="large"
//                                 onClick={handleClose}
//                                 color="error"
//                             >
//                                 <CloseIcon />
//                             </IconButton>
//                         </Box>
//                         <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
//                             {progress ? <LinearProgress color="primary" /> : null}
//                         </Stack>
//                         <Box
//                             component="form"
//                             onSubmit={handleSubmit(onSubmit)}
//                             noValidate
//                             sx={{ mt: 1, width: "100%" }}
//                         >
//                             <Grid item xs={12} sm={8} className="mt-2">
//                                 <Box className="flex gap-4 pt-2 items-center justify-center">
//                                     <FormControl fullWidth size="small" error={!!errors.quantity}>
//                                         <InputLabel id="priority-label">Adet Seçiniz</InputLabel>
//                                         <Select
//                                             labelId="priority-label"
//                                             id="quantity"
//                                             value={quantityValue || ""}
//                                             onChange={(event) =>
//                                                 setValue("quantity", event.target.value)
//                                             }
//                                             label="Adet Seçiniz"
//                                             error={!!errors.quantity}
//                                         >
//                                             {list?.map((item, i) => (
//                                                 <MenuItem key={i} value={item.piece}>
//                                                     {item.piece}
//                                                 </MenuItem>
//                                             ))}
//                                         </Select>
//                                         <FormHelperText>{errors?.quantity?.message}</FormHelperText>
//                                     </FormControl>
//                                 </Box>
//                             </Grid>

//                             <Button
//                                 type="submit"
//                                 fullWidth
//                                 variant="contained"
//                                 sx={{ mt: 1, mb: 1, backgroundColor: "#000000",
//                                     "&:hover": {
//                                         backgroundColor: "#393939",
//                                     }}}
//                                 // color="primary"
//                                 disabled={progress}
//                             >
//                                 {progress ? "Sipariş İşlemi Devam Ediyor..." : "Sipariş Ver"}
//                             </Button>
//                         </Box>
//                     </Box>
//                 </Fade>
//             </Modal>
//         </Box>
//     );
// };

// export default CreateOrderModal;

// CreateOrderModal.propTypes = {
//     open: propTypes.bool.isRequired,
//     handleClose: propTypes.func.isRequired,
// };



/* eslint-disable react/prop-types */
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import propTypes from "prop-types";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    TextField,
    Typography,
    IconButton,
    Stack,
    FormControl,
    FormHelperText,
} from "@mui/material";
import { Grid } from "@mui/material";
import { modalStyle } from "../../styles/modalStyle";
import { createDealerOrderSchema } from "../../styles/YupSchemas";
import { createOrderDealer } from "../../services/dealerFetch";
import { addToCart } from "../../redux/dealer/dealerSlice";

const CreateOrderModal = ({ open, handleClose, id, stock, currentItems }) => {
    const [progress, setProgress] = useState(false);
    const productInBasket = useSelector((state) => state.dealer.productInBasket);
    console.log(currentItems)
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(createDealerOrderSchema),
    });

    const quantityValue = watch("quantity");

    const onSubmit = async (data) => {
        console.log(data);
        setProgress(true);

        // try {
            if (stock < data.quantity) {
                dispatch(
                    showNotification({
                        message: `Ürün stoğu yetersiz. Maksimum talep edebileceğiniz adet sayısı: ${stock}`,
                        type: "error",
                    })
                );
                setProgress(false);
                handleClose();

            } else {
                const obj = {
                    productDimensionsId: id,
                    quantity: data.quantity,
                    productImage : currentItems?.[0]?.productImage,
                    size: currentItems?.[0]?.size,
                    malzemeAdi: currentItems?.[0]?.malzemeAdi,
                    stock:currentItems?.[0]?.stock?.stok_Miktari, 
                    userId: null,
                };
                console.log("object",obj)
                dispatch(addToCart(obj));
                setProgress(false);
                dispatch(
                    showNotification({
                        message:"Ürün Sepete Eklendi.",
                        type: "success",
                    })
                );
            handleClose();


                // const response = await createOrderDealer(obj);
                // if (response?.data?.statusCode === 200) {
                //     dispatch(
                //         showNotification({
                //             message: "Sipariş Verildi.",
                //             type: "success",
                //         })
                //     );
                // } else {
                //     dispatch(
                //         showNotification({
                //             message: "Sipariş Oluşturulurken Bir Hata Oluştu",
                //             type: "info",
                //         })
                //     );
                // }
            // }
        // } catch (error) {
        //     console.log(error);
        //     dispatch(
        //         showNotification({
        //             message: "Bayi Eklenirken Bir Hata Oluştu",
        //             type: "error",
        //         })
        //     );
        // } finally {
        //     setProgress(false);
        //     handleClose();
        //     reset();
        // }
    };
    }
    return (
        <Box>
            <Modal
                aria-labelledby="create-assignments-modal-title"
                aria-describedby="create-assignments-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle} width={"50%"}>
                        <Box className="flex justify-between items-center">
                            <Typography
                                id="create-assignments-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ color: "#000000" }}
                            >
                                Sipariş Ver
                            </Typography>
                            <IconButton
                                aria-label="exit"
                                size="large"
                                onClick={handleClose}
                                color="error"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
                            {progress ? <LinearProgress color="primary" /> : null}
                        </Stack>
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                            sx={{ mt: 1, width: "100%" }}
                        >
                            <Grid item xs={12} sm={8} className="mt-2">
                                <Box className="flex gap-4 pt-2 items-center justify-center">
                                    <FormControl fullWidth size="small" error={!!errors.quantity}>
                                        <TextField
                                            id="quantity"
                                            label="Adet Giriniz"
                                            type="number"
                                            value={quantityValue || ""}
                                            onChange={(event) =>
                                                setValue("quantity", event.target.value)
                                            }
                                            error={!!errors.quantity}
                                            helperText={errors?.quantity?.message}
                                            inputProps={{ min: 1 }}
                                            {...register("quantity")}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mb: 1,
                                    backgroundColor: "#000000",
                                    "&:hover": {
                                        backgroundColor: "#393939",
                                    },
                                }}
                                color="primary"
                                disabled={progress}
                            >
                                {progress ? "Sepete Ekleme İşlemi Devam Ediyor..." : "Sepete Ekle"}
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default CreateOrderModal;

CreateOrderModal.propTypes = {
    open: propTypes.bool.isRequired,
    handleClose: propTypes.func.isRequired,
    id: propTypes.number.isRequired,
    stock: propTypes.number.isRequired,
};
