import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/json/pageLoading.json";


const PageLoading = () => {
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "120px",
            }}
        >
            <Lottie
                animationData={loadingAnimation}
                style={{ width: "160px", height: "160px" }}
                aria-labelledby="use lottie animation"
                loop={true}
                autoplay={true}
            />
            <Typography variant="body2">
                Yükleniyor...
            </Typography>
        </Box>
    );
};

export default PageLoading;
