import React from "react";
import { BiCaretDown } from "react-icons/bi";

const CategoryTitle = ({ title, icons }) => {
  return (
    <div className="flex items-center font-medium justify-between pb-2">
      {icons ? (
        <>
          <h3 className=" text-primeColor">{title}</h3>
          {icons && <BiCaretDown />}
        </>
      ) : (
        <>
          <h3 className="  text-primeColor">{title}</h3>
        </>
      )}
    </div>
  );
};

export default CategoryTitle;
