import { get, postJSON } from './request';
import { BASEURL } from './baseURL';

export const userBasketCreate = (data) => postJSON(`${BASEURL}/basket/userBasketCreate`, data);
export const userBasketUpdate = (data) => postJSON(`${BASEURL}/basket/userBasketUpdate`, data);
export const userBasketList = () => get(`${BASEURL}/Basket/UserBasketList`);
export const deleteBasketItem = (id) => postJSON(`${BASEURL}/basket/userBasketDelete/${id}`);
export const deleteAllBasket = () => postJSON(`${BASEURL}/basket/userBasketAllDelete`);
export const userCreatePayment = (data) => postJSON(`${BASEURL}/Basket/CreatePayment`, data);

// export const getCityAndDistrictList = () => get(`${BASEURL}/Profile/get-province-district`);



