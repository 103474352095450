import React from "react";
import { FaShoppingBag } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ShopNow = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/urunler");
  };
  return (
    <button onClick={()=> handleClick()} className="bg-primeColor text-white mdl:text-lg flex items-center justify-center gap-2 font-bodyFont w-[100px] mdl:w-[185px] h-[50px] hover:bg-black duration-300 font-bold">
      <span>Alışveriş Yap </span>
      <FaShoppingBag size={22} color="white"/>
    </button>
  );
};

export default ShopNow;
