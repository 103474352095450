import * as yup from "yup";

export const loginSchema = yup.object({
    userName: yup.string().required("Kullanıcı Adı Gerekli*"),
    password: yup.string().min(6, "Şifre 6 karakterden az olamaz").required("Şifre gerekli*"),
});
export const forgotSchema = yup.object({
    email: yup.string().email("Email Formatı Uygun Değil"),
});

export const resetSchema = yup.object({
    password: yup.string().min(6, "Şifre 6 karakterden az olamaz").required("Şifre gerekli*"),
});

export const paymentOrderSchema = yup.object({
    cardHolderName: yup.string().required("Kart Sahibi Adı Zorunludur."),
    cardNumber: yup
        .string()
        .required("Kart Numarası Zorunludur.")
        .matches(/^\d{16}$/, "Geçersiz kart numarası.")
        .max(16, "Kart numarası maksimum 16 karakter olmalıdır."),
    expireMonth: yup.string().required("Ay Zorunludur."),
    expireYear: yup.string().required("Yıl Zorunludur."),
    cvc: yup
        .string()
        .required("CVC Zorunludur.")
        .matches(/^\d{3}$/, "Geçersiz CVC.")
        .max(3, "CVC maksimum 3 karakter olmalıdır."),
});

export const registerSchema = yup.object({
    email: yup.string().email("Email Formatı Uygun Değil"),
    password: yup.string().min(6, "Şifre 6 karakterden az olamaz").required("Şifre gerekli*"),
    passwordR: yup
        .string()
        .oneOf([yup.ref("password"), null], "Şifreler Eşleşmiyor")
        .required("Şifre gerekli*"),
    userName: yup
        .string()
        .min(5, "Kullanıcı adı 5 karakterden az olamaz")
        .required("Kullanıcı Adı gerekli*"),
    name: yup.string().required("İsim gerekli*"),
    surname : yup.string().required("Soyisim gerekli*"),
    phoneNumber: yup.string().required("Telefon Numarası gerekli*"),
});

export const createMeetingSchema = yup.object({
    title: yup.string().required("Toplantı başlığı gerekli*"),
    start: yup.date().required("Başlangıç tarihi gerekli*"),
    end: yup.date().required("Bitiş tarihi gerekli*"),
    organizationId: yup
        .array()
        // .required("Göreve Atanacak Kullanıcılar Gerekli*")
        .min(1, "En az bir kullanıcı seçilmelidir"),
    description: yup.string(),
});

export const createNoteSchema = yup.object({
    title: yup.string().required("Not başlığı gerekli*"),
    description: yup.string().required("Not açıklaması gerekli*"),
});
export const createDealerOrderSchema = yup.object({
    quantity : yup.string().required("Miktar Gerekli*"),
});

export const createAddressSchema = (billingAddressVisible) => {
    return yup.object({
        shippingAddressContactName: yup.string().required("İsim Gerekli*"),
        shippingAddressDescription: yup.string().required("Adres Açıklaması Gerekli*"),
        adressTitle: yup.string().required("Adres Başlığı Gerekli*"),
        shippingAddressCity: yup.string().required("Şehir Gerekli*"),
        shippingAddressDistrict: yup.string().required("İlçe Gerekli*"),
        phoneNumber: yup.string().required("Telefon Numarası Gerekli*"),
        shippingAddressZipCode: yup.string().required("Posta Kodu Gerekli*"),
        billingContactName: !billingAddressVisible ? yup.string().required("Fatura İletişim İsmi Gerekli*") : yup.string(),
        billingAddressDescription: !billingAddressVisible ? yup.string().required("Fatura Adres Açıklaması Gerekli*") : yup.string(),
        billingAddressZipCode: !billingAddressVisible ? yup.string().required("Fatura Adresi Posta Kodu Gerekli*") : yup.string(),
        billingAddressCity: !billingAddressVisible ? yup.string().required("Fatura Adresi Şehir Gerekli*") : yup.string(),
        billingAddressDistrict: !billingAddressVisible ? yup.string().required("Fatura Adresi İlçe Gerekli*") : yup.string(),
    });
};


export const carpetOrderSchema = yup.object({
    quality: yup.string().required("Kalite Gerekli*"),
    pattern : yup.string().required("Desen Gerekli*"),
    palette : yup.string().required("Palet Gerekli*"),
    ground : yup.string().required("Zemin Gerekli*"),
    border : yup.string().required("Kenar Gerekli*"),
    dimension : yup.string().required("Ebat Gerekli*"),
    edgeForm : yup.string().required("Kenar Formu Gerekli*"),
});