import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Lottie from "lottie-react";
import animationData from "../../assets/json/address.json";
import { Box, Button, TextField, Typography, IconButton, Stack, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { Grid } from "@mui/material";
import { modalStyle } from "../../styles/modalStyle";
import { createAddressSchema } from "../../styles/YupSchemas";
import { fetchAddress } from "../../services/getAllList";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputMask from "react-input-mask";
import { getCityAndDistrictList, userAddressCreate, userAddressUpdate } from "../../services/profileFetch";

const CreateAddressModal = ({ open, handleClose, address, update }) => {
    const [list, setList] = useState();
    const [districts, setDistricts] = useState();
    const [billingDistricts, setbillingDistricts] = useState();
    const [billingAddressVisible, setBillingAddressVisible] = useState(true);
    const [progress, setProgress] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();

    const getCityAndDistrict = async () => {
        try {
            const response = await getCityAndDistrictList();
            if (response?.data) {
                setList(response.data);
            }
        } catch (error) {
            dispatch(
                showNotification({
                    message: "İl ve İlçe Listesi Alınamadı",
                    type: "error",
                })
            );
        }
    };

    useEffect(() => {
        getCityAndDistrict();
    }, []);

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(createAddressSchema(billingAddressVisible)),
        
        defaultValues: {
            shippingAddressContactName: address?.shippingAddressContactName || "",
            phoneNumber: address?.phoneNumber || "",
            adressTitle: address?.adressTitle || "",
            shippingAddressCity: address?.shippingAddressCity || "",
            shippingAddressDistrict: address?.shippingAddressDistrict || "",
            shippingAddressZipCode: address?.shippingAddressZipCode || "",
            shippingAddressDescription: address?.shippingAddressDescription || "",
            billingContactName: address?.billingContactName || "",
            billingAddressCity: address?.billingAddressCity || "",
            billingAddressDistrict: address?.billingAddressDistrict || "",
            billingAddressZipCode: address?.billingAddressZipCode || "",
            billingAddressDescription: address?.billingAddressDescription || "",
        }
    });

    useEffect(() => {
        if (address) {
            reset({
                shippingAddressContactName: address.shippingAddressContactName || "",
                phoneNumber: address.phoneNumber.slice(3,13) || "",
                adressTitle: address.adressTitle || "",
                shippingAddressCity: address.shippingAddressCity || "",
                shippingAddressDistrict: address.shippingAddressDistrict || "",
                shippingAddressZipCode: address.shippingAddressZipCode || "",
                shippingAddressDescription: address.shippingAddressDescription || "",
                billingContactName: address.billingContactName || "",
                billingAddressCity: address.billingAddressCity || "",
                billingAddressDistrict: address.billingAddressDistrict || "",
                billingAddressZipCode: address.billingAddressZipCode || "",
                billingAddressDescription: address.billingAddressDescription || "",
            });
        }
    }, [address, reset]);

    const districtValue = watch("shippingAddressDistrict");
    const cityValue = watch("shippingAddressCity");
    useEffect(() => {
        if (cityValue) {
            const selectedCity = list?.find((item) => item.il === cityValue);
            if (selectedCity) {
                setDistricts(selectedCity.ilceleri);
            } else {
                setDistricts([]);
            }
        }
    }, [cityValue, list]);

    const billingDistrictValue = watch("billingAddressDistrict");
    const billingCityValue = watch("billingAddressCity");
    useEffect(() => {
        if (billingCityValue) {
            const selectedCity = list.find((item) => item.il === billingCityValue);
            if (selectedCity) {
                setbillingDistricts(selectedCity.ilceleri);
            } else {
                setbillingDistricts([]);
            }
        }
    }, [billingCityValue, list]);
    
    const onSubmit = async (data) => {
        try {
            const processedNumber = data.phoneNumber.replace(/[\(\)\s-]/g, "");
            const obj = {
                shippingAddressContactName: data.shippingAddressContactName,
                userAdressId: address?.userAdressId || null,
                phoneNumber: "+90" + processedNumber,
                billingAdressStatus: billingAddressVisible,
                shippingAddressDescription: data.shippingAddressDescription,
                adressTitle: data.adressTitle,
                shippingAddressCity: data.shippingAddressCity,
                shippingAddressDistrict: data.shippingAddressDistrict,
                shippingAddressZipCode: data.shippingAddressZipCode,
                billingContactName: data?.billingContactName,
                billingAddressCity: data?.billingAddressCity,
                billingAddressDistrict: data?.billingAddressDistrict,
                billingAddressZipCode: data?.billingAddressZipCode,
                billingAddressDescription: data?.billingAddressDescription,
            };
            setProgress(true);
            const response = await (update ? userAddressUpdate(obj) : userAddressCreate(obj));
            if (response?.data?.statusCode === 200) {
                dispatch(
                    showNotification({
                        message:update ? "Adres Güncellendi" : "Adres Eklendi",
                        type: "success",
                    })
                );
                fetchAddress(dispatch);
            } else {
                dispatch(
                    showNotification({
                        message: "Adres Eklenirken Bir Hata Oluştu",
                        type: "info",
                    })
                );
            }
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    message: "Adres Eklenirken Bir Hata Oluştu",
                    type: "error",
                })
            );
        } finally {
            setProgress(false);
            handleClose();
            reset();
        }
    };

    return (
        <Box>
            <Modal
                aria-labelledby="create-assignments-modal-title"
                aria-describedby="create-assignments-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={modalStyle} width={"50%"}>
                        <Box className="flex justify-between items-center">
                            <Typography
                                id="create-assignments-modal-title"
                                variant="h6"
                                component="h2"
                                sx={{ color: "#000000"}}
                            >
                                Adres Ekle
                            </Typography>
                            <IconButton
                                aria-label="exit"
                                size="large"
                                onClick={handleClose}
                                color="error"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
                            {progress ? <LinearProgress color="primary" /> : null}
                        </Stack>
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                            sx={{ mt: 1, width: "100%" }}
                        >
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    className="flex justify-center items-center"
                                >
                                    <Lottie
                                        animationData={animationData}
                                        style={{ width: "160px", height: "160px" }}
                                        aria-labelledby="use lottie animation"
                                        loop={false}
                                        autoplay={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Box className="flex  gap-4">
                                        <TextField
                                            margin="dense"
                                            size="small"
                                            fullWidth
                                            id="shippingAddressContactName"
                                            label="Adı Soyadı"
                                            name="shippingAddressContactName"
                                            autoComplete="shippingAddressContactName"
                                            autoFocus
                                            {...register("shippingAddressContactName")}
                                            error={!!errors.shippingAddressContactName}
                                            helperText={errors?.shippingAddressContactName?.message}
                                        />
                                      
                                    </Box>
                                    <Box className="flex  gap-4 pt-1.5">
                                        <Grid item xs={12} sm={6}>
                                            <InputMask
                                                mask="(999) 999-9999"
                                                {...register("phoneNumber")}
                                            >
                                                {(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth
                                                        id="phoneNumber"
                                                        label="Telefon Numarası"
                                                        name="startDate"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        autoComplete="phoneNumber"
                                                        type=""
                                                        {...register("phoneNumber")}
                                                        error={!!errors.phoneNumber}
                                                        helperText={errors?.phoneNumber?.message}
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                margin="dense"
                                                size="small"
                                                fullWidth
                                                id=""
                                                label="Adres Başlığı"
                                                name="adressTitle"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                autoComplete="adressTitle"
                                                {...register("adressTitle")}
                                                error={!!errors.adressTitle}
                                                helperText={errors?.adressTitle?.message}
                                            />
                                        </Grid>
                                    </Box>
                                    <TextField
                                        margin="dense"
                                        size="small"
                                        fullWidth
                                        id="shippingAddressZipCode"
                                        label="Zip Kodu"
                                        name="shippingAddressZipCode"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoComplete="shippingAddressZipCode"
                                        type="number"
                                        {...register("shippingAddressZipCode")}
                                        error={!!errors.shippingAddressZipCode}
                                        helperText={errors?.shippingAddressZipCode?.message}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={8} className="mt-2">
                                <Box className="flex gap-4 pt-2 items-center justify-center">
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        error={!!errors.shippingAddressCity}
                                    >
                                        <InputLabel id="priority-label">İl Seçiniz</InputLabel>
                                        <Select
                                            labelId="priority-label"
                                            id="priority"
                                            value={cityValue || ""}
                                            onChange={(event) =>
                                                setValue("shippingAddressCity", event.target.value)
                                            }
                                            label="İl Seçiniz"
                                            error={!!errors.shippingAddressCity}
                                        >
                                            {list?.map((item, i) => (
                                                <MenuItem key={i} value={item.il}>
                                                    {item.il}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {errors?.shippingAddressCity?.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        size="small"
                                        error={!!errors.shippingAddressDistrict}
                                    >
                                        <InputLabel id="priority-label">İlçe Seçiniz</InputLabel>
                                        <Select
                                            labelId="priority-label"
                                            id="priority"
                                            value={districtValue || ""}
                                            disabled={!cityValue}
                                            onChange={(event) =>
                                                setValue(
                                                    "shippingAddressDistrict",
                                                    event.target.value
                                                )
                                            }
                                            label="İlçe Seçiniz"
                                            error={!!errors.shippingAddressDistrict}
                                        >
                                            {districts?.map((item, i) => (
                                                <MenuItem key={i} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {errors?.shippingAddressDistrict?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    id="shippingAddressDescription"
                                    label="Adres Açıklaması"
                                    name="shippingAddressDescription"
                                    autoComplete="shippingAddressDescription"
                                    multiline
                                    rows={4}
                                    {...register("shippingAddressDescription")}
                                    error={!!errors.shippingAddressDescription}
                                    helperText={errors?.shippingAddressDescription?.message}
                                />
                            </Grid>
                            <div className="form-control">
                                <label className="cursor-pointer label self-start gap-2">
                                    <input
                                        onChange={() =>
                                            setBillingAddressVisible(!billingAddressVisible)
                                        }
                                        type="checkbox"
                                        defaultChecked
                                        className="checkbox checkbox-info"
                                    />
                                    <span className="label-text font-semibold">
                                        Fatura Adresim üstteki bilgiler ile aynı
                                    </span>
                                </label>
                            </div>
                            {!billingAddressVisible && (
                                <>
                                    <Grid item xs={12} sm={8}>
                                        <Box className="flex  gap-4">
                                            <TextField
                                                margin="dense"
                                                size="small"
                                                fullWidth
                                                id="billingContactName"
                                                label="Adı "
                                                name="billingContactName"
                                                autoComplete="billingContactName"
                                                autoFocus
                                                {...register("billingContactName")}
                                                error={!!errors.billingContactName}
                                                helperText={errors?.billingContactName?.message}
                                            />
                                            <TextField
                                                margin="dense"
                                                size="small"
                                                fullWidth
                                                id="billingAddressZipCode"
                                                label="Zip Kodu"
                                                name="billingAddressZipCode"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                autoComplete="billingAddressZipCode"
                                                type="number"
                                                {...register("billingAddressZipCode")}
                                                error={!!errors.billingAddressZipCode}
                                                helperText={errors?.billingAddressZipCode?.message}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8} className="mt-2">
                                        <Box className="flex gap-4 pt-2">
                                            <FormControl
                                                fullWidth
                                                size="small"
                                                error={!!errors.billingAddressCity}
                                            >
                                                <InputLabel id="priority-label">
                                                    İl Seçiniz
                                                </InputLabel>
                                                <Select
                                                    labelId="priority-label"
                                                    id="priority"
                                                    value={billingCityValue || ""}
                                                    onChange={(event) =>
                                                        setValue(
                                                            "billingAddressCity",
                                                            event.target.value
                                                        )
                                                    }
                                                    label="İl Seçiniz"
                                                    error={!!errors.billingAddressCity}
                                                >
                                                    {list?.map((item, i) => (
                                                        <MenuItem key={i} value={item.il}>
                                                            {item.il}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>
                                                    {errors?.city?.message}
                                                </FormHelperText>
                                            </FormControl>

                                            <FormControl
                                                fullWidth
                                                size="small"
                                                error={!!errors.billingAddressDistrict}
                                            >
                                                <InputLabel id="priority-label">
                                                    İlçe Seçiniz
                                                </InputLabel>
                                                <Select
                                                    labelId="priority-label"
                                                    id="priority"
                                                    value={billingDistrictValue || ""}
                                                    disabled={!billingCityValue}
                                                    onChange={(event) =>
                                                        setValue(
                                                            "billingAddressDistrict",
                                                            event.target.value
                                                        )
                                                    }
                                                    label="İlçe Seçiniz"
                                                    error={!!errors.billingAddressDistrict}
                                                >
                                                    {billingDistricts?.map((item, i) => (
                                                        <MenuItem key={i} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>
                                                    {errors?.billingAddressDistrict?.message}
                                                </FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            margin="dense"
                                            size="small"
                                            fullWidth
                                            id="billingAddressDescription"
                                            label="Fatura Adres Açıklaması"
                                            name="billingAddressDescription"
                                            autoComplete="billingAddressDescription"
                                            multiline
                                            rows={4}
                                            {...register("billingAddressDescription")}
                                            error={!!errors.billingAddressDescription}
                                            helperText={errors?.billingAddressDescription?.message}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 1, backgroundColor: "#000000",
                                    "&:hover": {
                                        backgroundColor: "#393939",
                                    } }}
                                disabled={progress}
                            >
                                {progress ? "Ekleme İşlemi Devam Ediyor..." : update ? "Adresi Güncelle" : "Adres Ekle"}
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default CreateAddressModal;

CreateAddressModal.propTypes = {
    open: propTypes.bool.isRequired,
    handleClose: propTypes.func.isRequired,
};
