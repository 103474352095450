import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { resetBasketCart } from "../../redux/product/productSlice";
import { emptyCart } from "../../assets/images/index";
import ItemCard from "./ItemCard";
import { fetchBasketList } from "../../services/getAllList";
import { MdDeleteOutline } from "react-icons/md";
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined";
import { deleteAllBasket } from "../../services/basketFetch";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { formatPrice } from "../../constants/generalFunction";

const Cart = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.product.productInBasket);
    const navigate = useNavigate();
    const [totalAmt, setTotalAmt] = useState("");
    const [basketId, setBasketId] = useState();
    const [shippingCharge, setShippingCharge] = useState("");
    console.log(products)
    useEffect(() => {
        let price = 0;
        products?.map((item) => {
            price += item.price * item.quantity;
            setBasketId(item.basketId);
            return price;
        });
        setTotalAmt(price);
    }, [products]);
    
    const handlePaymentDetails = () => {
        const obj = {
            basketId: basketId,
            totalAmount: totalAmt,
            shippingCharge: shippingCharge,
        };

        console.log();
        navigate(`/odeme`, {
            state: {
                item: obj,
            },
        });
    };

    useEffect(() => {
        fetchBasketList(dispatch);
    }, []);

    const deleteBasket = async () => {
        try {
            const response = await deleteAllBasket();
            if (response?.data?.statusCode === 200) {
                dispatch(showNotification({ type: "success", message: "Tüm Ürünler Silindi" }));
                dispatch(resetBasketCart());
            }
        } catch (error) {
            dispatch(
                showNotification({
                    message: "Ürünler Silinirken Hata Oluştu!",
                    type: "error",
                })
            );
        }
    };

    useEffect(() => {
        if (totalAmt <= 200) {
            setShippingCharge(30);
        } else if (totalAmt <= 400) {
            setShippingCharge(25);
        } else if (totalAmt > 401) {
            setShippingCharge(20);
        }
    }, [totalAmt]);
    
    return (
        <div className="max-w-container mx-auto px-4">
            <Breadcrumbs title="Sepet" />
            {products?.length > 0 ? (
                <div className="pb-20 border shadow-md rounded-lg">
                    <div className="w-full h-20 bg-[#33475B] text-white hidden lgl:grid grid-cols-6 place-content-center px-6 text-lg font-titleFont font-semibold text-center">
                        <h2 className="col-span-2">Ürün</h2>
                        <h2>Boyut</h2>
                        <h2>Fiyat</h2>
                        <h2>Miktar</h2>
                        <h2>Toplam Fiyat</h2>
                    </div>
                    <div className="mt-5">
                        {products?.map((item) => (
                            <div key={item._id}>
                                <ItemCard item={item} />
                            </div>
                        ))}
                    </div>
                    <div className="max-w-7xl gap-4 flex justify-end mt-4 ">
                        <div className="w-96 max-md:w-80 flex flex-col gap-4 shadow-xl rounded-md p-2 max-md:p-1">
                            <h1 className="text-2xl font-semibold text-center">Sepet Toplamı</h1>
                            <div>
                                <p className="flex items-center justify-between py-1.5  max-md:text-sm text-lg px-4 font-medium">
                                    Ürün Toplam Fiyat
                                    <span className="font-semibold tracking-wide font-titleFont">
                                        {formatPrice(totalAmt)}TL
                                    </span>
                                </p>
                                <p className="flex items-center justify-between py-1.5 max-md:text-sm text-lg px-4 font-medium">
                                    Kargo Ücreti
                                    <span className="font-semibold tracking-wide font-titleFont">
                                        {shippingCharge}TL
                                    </span>
                                </p>
                                <p className="flex items-center justify-between  max-md:text-sm py-1.5 text-lg px-4 font-medium">
                                    Toplam
                                    <span className="font-bold tracking-wide text-lg font-titleFont">
                                        {formatPrice(totalAmt + shippingCharge)}TL
                                    </span>
                                </p>
                            </div>
                            <div className="flex max-md:flex-col max-md:gap-2 justify-between items-center space-x-2">
                                <button
                                    onClick={deleteBasket}
                                    className="w-52 h-10 flex items-center justify-center bg-red-600 rounded-md  text-white hover:bg-red-500 duration-300"
                                >
                                    <MdDeleteOutline size={22} />
                                    Sepeti temizle
                                </button>

                                <button
                                    onClick={handlePaymentDetails}
                                    className="w-52 h-10 space-x-2 bg-green-600  text-white rounded-md hover:bg-green-500 duration-300"
                                >
                                    <ShoppingCartCheckoutOutlinedIcon color="inherit" />
                                    <span> Satın Al</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
                >
                    <div>
                        <img
                            className="w-80 rounded-lg p-4 mx-auto"
                            src={emptyCart}
                            alt="emptyCart"
                        />
                    </div>
                    <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
                        <h1 className="font-titleFont text-xl font-bold ">
                             SEPETİNİZ BOŞ
                        </h1>
                        <p className="text-sm text-center px-10 -mt-2">
                            Alışveriş sepetinize Ürün Ekleyin.
                        </p>
                        <Link to="/urunler">
                            <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                                Alışveriş Yap
                            </button>
                        </Link>
                    </div>
                </motion.div>
            )}
        </div>
    );
};


export default Cart;
