import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Image from "../designLayouts/Image";
import { bannerFetch } from "../../services/homeFetch";

const CustomSlide = ({ sliderDescription, sliderImage, sliderTitle, secondImage, thirdImage }) => (
    <div
        style={{
            position: "relative",
            backgroundColor: "#F5F5F3", // Gray background color
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Center vertically
        }}
        className="flex max-md:flex-col justify-center items-center bg-[#F5F5F3] relative "
    >
        <div className="max-w-[450px] mr-[100px] px-3 max-md:hidden">
            <h1
                // style={{
                //     marginBottom: "15px",
                //     fontSize: "2.5rem", // Adjust the font size as needed
                //     color: "#000", // Black color
                //     fontWeight: "700",
                // }}
                className="text-4xl max-md:text-2xl font-bold mb-3 text-[#000]"
            >
                {sliderTitle}
            </h1>
            <p
                // style={{
                //     marginBottom: "25px",
                //     fontSize: "1.5rem", // Adjust the font size as needed
                //     color: "#666", // Gray color
                // }}
                className="text-lg max-md:text-base text-[#666] "
            >
                {sliderDescription}
            </p>
            {/* <Link to="/about">
                <button className="bg-primeColor text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-black duration-300 font-bold">
                    buttonText
                </button>
            </Link> */}
        </div>
        <div className="flex md:ml-[100px] mt-5 px-1">
            <div className="md:mr-[20px] max-md:mr-1">
                <Image className="w-[315px] h-[326px] object-cover" imgSrc={sliderImage} />
            </div>
            <div>
                <Image className="w-[315px] h-[155px] max-md:h-[158px] object-cover md:mb-4 max-md:mb-2" imgSrc={sliderImage} />
                <Image className="w-[315px] h-[155px] max-md:h-[158px] object-cover" imgSrc={sliderImage} />
            </div>
        </div>
    </div>
);

const Banner = () => {
    
    const [dotActive, setDotActive] = useState(0);
    const [slides, setSlides] = useState();

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: false,
        beforeChange: (prev, next) => {
            setDotActive(next);
        },
        appendDots: (dots) => (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "2%",
                    transform: "translateY(-50%)",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <ul
                    style={{
                        margin: "0px",
                        padding: "0",
                        listStyle: "none",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    {dots}
                </ul>
            </div>
        ),
        customPaging: (i) => (
            <div
                style={
                    i === dotActive
                        ? {
                              width: "30px",
                              color: "#262626",
                              borderRight: "3px #262626 solid",
                              padding: "8px 0",
                              cursor: "pointer",
                          }
                        : {
                              width: "30px",
                              color: "transparent",
                              borderRight: "3px white solid",
                              padding: "8px 0",
                              cursor: "pointer",
                          }
                }
            >
                0{i + 1}
            </div>
        ),
        responsive: [
            {
                breakpoint: 768, // md breakpoint
                settings: {
                    dots: true,
                    appendDots: (dots) => (
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "2%",
                                transform: "translateY(-50%)",
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <ul
                                style={{
                                    margin: "0px",
                                    padding: "0",
                                    listStyle: "none",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                {dots}
                            </ul>
                        </div>
                    ),
                    customPaging: (i) => (
                        <div
                            style={
                                i === dotActive
                                    ? {
                                          width: "25px",
                                          color: "#262626",
                                          borderRight: "3px #262626 solid",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                      }
                                    : {
                                          width: "25px",
                                          color: "transparent",
                                          borderRight: "3px white solid",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                      }
                            }
                        >
                            0{i + 1}
                        </div>
                    ),
                },
            },
            {
                breakpoint: 576, // Additional settings for smaller screens if needed
                settings: {
                    dots: true,
                    appendDots: (dots) => (
                        <div
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "2%",
                                transform: "translateY(-50%)",
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <ul
                                style={{
                                    margin: "0px",
                                    padding: "0",
                                    listStyle: "none",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                {dots}
                            </ul>
                        </div>
                    ),
                    customPaging: (i) => (
                        <div
                            style={
                                i === dotActive
                                    ? {
                                          width: "25px",
                                          color: "#262626",
                                          borderRight: "3px #262626 solid",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                      }
                                    : {
                                          width: "25px",
                                          color: "transparent",
                                          borderRight: "3px white solid",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                      }
                            }
                        >
                            0{i + 1}
                        </div>
                    ),
                },
            },
        ],
    };
    
    const banner = async () => {
        try {
            const response = await bannerFetch();
            if (response?.data) setSlides(response?.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        banner();
    }, []);

    return (
        <div className="w-full bg-white">
            <Slider {...settings}>
                {slides ? (
                    slides?.map((slide, index) => <CustomSlide key={index} {...slide} />)
                ) : (
                    <>
                        <div className="flex items-center justify-center">
                            <span className="loading loading-dots loading-lg text-[#8B8B8B]"></span>
                        </div>
                    </>
                )}
            </Slider>
        </div>
    );
};

export default Banner;
