import React, { useEffect, useState } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = ({ prevLocation, title }) => {
    const location = useLocation();
    const [paths, setPaths] = useState([]);

    useEffect(() => {
        const pathnames = location.pathname.split("/").filter((x) => x);
        setPaths(pathnames);
    }, [location]);

    return (
        <div className="w-full pb-3 pt-2 xl:pb-5 flex flex-col gap-3 max-w-container mx-auto ">
            <h1 className="text-4xl text-primeColor font-titleFont font-bold">
                {title}
            </h1>
            <p className="text-sm font-normal text-lightText capitalize flex items-center">
                <Link to="/" className="capitalize font-semibold text-primeColor">
                    Anasayfa
                </Link>
                {paths.map((value, index) => {
                    const to = `/${paths.slice(0, index + 1).join("/")}`;
                    return (
                        <span key={to} className="flex items-center">
                            <HiOutlineChevronRight className="px-1" />
                            <Link to={to} className="capitalize font-semibold text-primeColor">
                                {value}
                            </Link>
                        </span>
                    );
                })}
            </p>
        </div>
    );
};

export default Breadcrumbs;
