import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../pageProps/Breadcrumbs";
import { formatDate, formatPrice } from "../../constants/generalFunction";
import { IoArrowBackCircleSharp } from "react-icons/io5";

const OrderDetails = () => {
    const [order, setOrder] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (location) { 
            setOrder(location.state.item);
        }
    }, [location]);
    
    return (
        <div className="ml-8 px-5 max-md:ml-0">
            <div className="flex flex-col items-start pt-2">
                <IoArrowBackCircleSharp
                    onClick={() => navigate(-1)}
                    size={45}
                    className="cursor-pointer"
                    color="#1F2937"
                />

                <Breadcrumbs />
            </div>
            <div className="">
                <h1 className="text-2xl font-semibold">Sipariş {order?.orderStatus}</h1>
                <p className="max-md:text-sm">{formatDate(order?.createDate)} Tarihinde Sipariş Verildi</p>
            </div>
            <div className="flex max-md:flex-col items-start mt-5 w-full gap-5">
                <div className="flex items-center w-2/3 max-md:w-full max-md:flex-col pt-5 border-y-[1px] border-black p-2">
                    <div className="flex">
                        <div>
                            <img className="w-44 h-44 object-cover rounded-md" src={order?.productDimension?.product?.productImage1} />
                        </div>
                        <div className="flex flex-col gap-0.5 pl-5">
                            <p className="text-[#8DC41A] font-bold md:py-1 max-md:text-sm">{order?.orderStatus}</p>
                            <p className="text-lg font-semibold md:py-1 max-md:text-sm">{order?.productDimension?.product?.productName}</p>
                            <p className="max-md:text-sm md:py-1 font-bold">
                                {formatPrice(order?.totalPrice)} TL
                            </p>
                            <p className="max-md:text-sm md:py-1 font-bold">Ebat:{" "}{order?.productDimension?.dimension?.size}</p>
                        </div>
                    </div>
                </div>
                <div className=" flex flex-col flex-wrap pl-6 max-md:p-0  w-1/3 max-md:w-full">
                    <div className="flex flex-wrap flex-col border-t-[1px] border-black py-2">
                        <h3 className="font-semibold py-3">Adres</h3>
                        <p className="max-md:text-sm ">
                            {order?.userAdress?.shippingAddressContactName}
                        </p>
                        <p className="max-md:text-sm ">
                            {order?.userAdress?.shippingAddressDescription}
                        </p>
                        <p className="max-md:text-sm ">
                            {order?.userAdress?.shippingAddressCity}/
                            {order?.userAdress?.shippingAddressDistrict}
                        </p>
                    </div>
                    <div className="flex flex-col border-y-[1px] border-black">
                        <h3 className="font-semibold py-3">Ödeme</h3>
                        <p className="max-md:text-sm ">
                            Kredi Kartı -{order?.totalPrice}
                        </p>
                        <p className="max-md:text-sm ">**** **** **** 9696</p>
                    </div>
                    <div className="flex flex-col py-2 border-b-[1px] border-black">
                    <div className="flex items-center justify-between">
                        <h3 className="font-semibold py-3">Kargo</h3>
                        <p className="max-md:text-sm ">0TL</p>
                     </div>
                     <div className="flex items-center justify-between">
                        <h3 className="font-semibold py-3">Toplam Vergi</h3>
                        <p className="max-md:text-sm ">{formatPrice(order?.totalPrice * (order?.productDimension?.kDV?.taxRate)) }TL</p>
                     </div>
                     <div className="flex items-center justify-between">
                        <h3 className="font-semibold py-3">Toplam</h3>
                        <p className="max-md:text-sm ">{formatPrice(order?.totalPrice)}TL</p>
                     </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetails;
