import React, { useEffect, useState } from "react";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import ProductNextArrow from "./ProductNextArrow";
import ProductPrevArrow from "./ProductPrevArrow";
import Slider from "react-slick";
import { convertTurkishChars, formatPrice } from "../../../constants/generalFunction";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { useUser } from "../../../redux/user/hooks";
import { createFavoriteFetch, deleteFavoriteItemFetch } from "../../../services/favoriteFetch";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "../../../redux/notifications/notificationSlice";
import { fetchFavorites } from "../../../services/getAllList";
import { BiLoaderAlt } from "react-icons/bi";

const Product = (props) => {
    // console.log(props)

    const [exist, setExist] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const productItem = props;
    const user = useUser();
    const dispatch = useDispatch();
    const favorite = useSelector((state) => state.product.favorite);
    let arr = Array.isArray(props?.img) ? props.img : [];
    const userFavorite = favorite?.find(
        (f) => f.productDimensionId === productItem?.productDimensions?.[0]?.productDimensionId
    );
    
    useEffect(() => {
        if (productItem?.productDimensions?.[0]?.productDimensionId) {
            const isFavorite = favorite?.some(
                (item) =>
                    item.productDimensionId ===
                    productItem?.productDimensions?.[0]?.productDimensionId
            );
            setExist(isFavorite);
        }
    }, [favorite, productItem?.productDimensions]);

    const handleProductDetails = () => {
        const productNameEnglish = convertTurkishChars(props?.productName);
        navigate(`/product/${productNameEnglish}`, {
            state: {
                item: productItem,
            },
        });
    };

    const addToFavoriteList = async () => {
        if (user?.userName) {
            try {
                setLoading(true);
                let id = productItem?.productDimensions?.[0]?.productDimensionId;
                const data = {
                    productDimensionId: id,
                };
                const response = await createFavoriteFetch(data);
                if (response?.data?.statusCode === 200) {
                    dispatch(showNotification({ type: "success", message: "Favorilere Eklendi." }));
                    fetchFavorites(dispatch);
                    setLoading(false);
                } 
                else if (response?.data?.statusCode === 400) {
                    dispatch(showNotification({ type: "error", message: "Ürün Favorilerde Mevcut." }));
                    fetchFavorites(dispatch);
                    setLoading(false);
                }else {
                    setLoading(false);
                    dispatch(
                        showNotification({
                            type: "error",
                            message: "Favorilere Eklenirken Bir Hata Oluştu!",
                        })
                    );
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            dispatch(
                showNotification({ type: "error", message: "Favorilere Eklemek İçin Giriş Yapın!" })
            );
            // navigate("/signin");
        }
    };

    const deleteFavorite = async () => {
        setLoading(true);
        try {
            const response = await deleteFavoriteItemFetch(userFavorite?.userFavoriId);
            if (response?.statusCode === 200) {
                dispatch(
                    showNotification({ type: "error", message: "Ürün Favorilerden Silindi." })
                    );
                fetchFavorites(dispatch);
            // dispatch(deleteFavoriteItem(userFavorite?.productDimensionId))

                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
            if (response?.statusCode === 404) {
                setLoading(false);
                dispatch(
                    showNotification({ type: "error", message: "Ürün Favorilerde Bulunamadı." })
                );
            }
        } catch (error) {
            setLoading(false);
            dispatch(
                showNotification({
                    message: "Ürün Silinirken Bir Hata Oluştu!",
                    type: "error",
                })
            );
        }
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ProductNextArrow />,
        prevArrow: <ProductPrevArrow />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    };

    // console.log(props)
    const productDimension = props?.productDimensions?.[0];
    // console.log(productDimension);
    const discount = productDimension?.discount || 0;
    // console.log(discount);
    const cariDiscount = productDimension?.cariDiscount || 0;
    // console.log(cariDiscount);
    const discountedPrice = productDimension?.price / (1 - discount);
    // console.log(discountedPrice);
    const finalPrice = discountedPrice / (1 - cariDiscount);
    // console.log(finalPrice);
    const discountRatio = 1 - productDimension?.price / finalPrice;
    // console.log(discountRatio);

    // return (
    //     <div className="w-full group relative">
    //         <div className=" relative overflow-y-hidden ">
    //             <div className="shadow-md w-full">
    //                 <Slider {...settings}>
    //                     {arr?.map((img, index) => (
    //                         <div
    //                             onClick={handleProductDetails}
    //                             key={index}
    //                             className="cursor-pointer border-[1px]"
    //                         >
    //                             <Image
    //                                 className="object-cover h-96 max-md:h-52 w-full hover:scale-125 duration-200"
    //                                 imgSrc={img.img}
    //                             />
    //                         </div>
    //                     ))}
    //                 </Slider>
    //             </div>
    //             <div className="absolute top-6 left-3">
    //                 {(discount !== 0 || cariDiscount !== 0) && <Badge discount={discountRatio} />}
    //             </div>
    //             <div className="absolute bg-white rounded-full w-[39px] h-[39px] top-6 right-3 flex items-center justify-center">
    //                 {loading ? (
    //                     <BiLoaderAlt className="animate-spin h-6 w-6 " />
    //                 ) : exist ? (
    //                     <FaHeart
    //                         onClick={deleteFavorite}
    //                         className="text-red-500 cursor-pointer"
    //                         size={20}
    //                     />
    //                 ) : (
    //                     <FaRegHeart
    //                         onClick={addToFavoriteList}
    //                         className="text-black cursor-pointer"
    //                         size={20}
    //                     />
    //                 )}
    //             </div>
    //         </div>
    //         <div className="rounded max-w-80 h-36 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4 ">
    //             <div className="flex flex-col items-start justify-between font-titleFont w-full gap-2">
    //                 <h2 className="text-primeColor text-[14px] w-full">
    //                     {props?.productName}
    //                 </h2>
    //                 <p className=" flex items-center justify-between  w-full">
    //                     {(discount !== 0 || cariDiscount !== 0) && (
    //                         <span className="line-through text-[#B8ACA3] font-semibold">
    //                             {formatPrice(finalPrice) ?? "N/A"}TL
    //                         </span>
    //                     )}
    //                     <span className="text-[#198754] text-[18px] font-semibold">
    //                         {formatPrice(props?.productDimensions?.[0]?.price) ?? "N/A"}TL
    //                     </span>
    //                 </p>
    //             </div>
    //         </div>
    //         <div className="rounded w-full h-32 absolute bg-white -bottom-[50px] group-hover:-bottom-[30px] hidden group-hover:block duration-700">
    //             <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
    //                 <li
    //                     onClick={handleProductDetails}
    //                     className="text-[#767676] hover:text-primeColor  font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-between gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
    //                 >
    //                     <span className="line-through">
    //                         {(discount !== 0 || cariDiscount !== 0) && (
    //                             <span className="line-through">
    //                                 {formatPrice(finalPrice) ?? "N/A"}TL
    //                             </span>
    //                         )}
    //                     </span>
    //                     <span className="text-[#198754] text-[18px] font-semibold">
    //                         {formatPrice(props?.productDimensions?.[0]?.price) ?? "N/A"}TL
    //                     </span>
    //                 </li>
    //                 <li
    //                     onClick={handleProductDetails}
    //                     className="text-[#767676] hover:text-primeColor  font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center  justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
    //                 >
    //                     Ürün Detay
    //                     <span className="">
    //                         <MdOutlineLabelImportant />
    //                     </span>
    //                 </li>
    //             </ul>
    //         </div>
    //     </div>
    // );

    return (
        <div className="w-full group relative">
            <div className="relative overflow-hidden max-md:h-[290px]">
                <div className="shadow-md w-full">
                    <Slider {...settings}>
                        {arr?.map((img, index) => (
                            <div
                                onClick={handleProductDetails}
                                key={index}
                                className="cursor-pointer border-[1px]"
                            >
                                <Image
                                    className="object-cover h-[28rem] p-1 max-md:h-72 w-full hover:scale-125 duration-200"
                                    imgSrc={img.img}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="absolute top-6 left-3">
                    {(discount !== 0 || cariDiscount !== 0) && <Badge discount={discountRatio} />}
                </div>
                <div className="absolute bg-white rounded-full w-[39px] h-[39px] top-6 right-3 max-md:right-1 flex items-center justify-center">
                    {loading ? (
                        <BiLoaderAlt className="animate-spin h-6 w-6 " />
                    ) : exist ? (
                        <FaHeart
                            onClick={deleteFavorite}
                            className="text-red-500 cursor-pointer"
                            size={20}
                        />
                    ) : (
                        <FaRegHeart
                            onClick={addToFavoriteList}
                            className="text-black cursor-pointer"
                            size={20}
                        />
                    )}
                </div>
            </div>
            <div className="rounded max-w-80  flex flex-col gap-1 border-[1px] border-t-0 px-4 mt-0 md:mt-4 py-6">
                <div className="flex flex-col items-start justify-between font-titleFont w-full gap-2">
                    <h2 className="text-primeColor text-[14px] w-full">
                        {props?.productName}
                    </h2>
                    <p className="flex items-center justify-between w-full">
                        {(discount !== 0 || cariDiscount !== 0) && (
                            <span className="line-through text-[#B8ACA3] font-semibold">
                                {formatPrice(finalPrice) ?? "N/A"}TL
                            </span>
                        )}
                        <span className="text-[#198754] text-[18px] font-semibold">
                            {formatPrice(props?.productDimensions?.[0]?.price) ?? "N/A"}TL
                        </span>
                    </p>
                </div>
            </div>
            <div className="rounded w-full h-32 absolute bg-white -bottom-[50px] group-hover:-bottom-[30px] hidden group-hover:block duration-700">
                <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
                    <li
                        onClick={handleProductDetails}
                        className="text-[#767676] hover:text-primeColor font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-between gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
                    >
                        <span className="line-through">
                            {(discount !== 0 || cariDiscount !== 0) && (
                                <span className="line-through">
                                    {formatPrice(finalPrice) ?? "N/A"}TL
                                </span>
                            )}
                        </span>
                        <span className="text-[#198754] text-[18px] font-semibold">
                            {formatPrice(props?.productDimensions?.[0]?.price) ?? "N/A"}TL
                        </span>
                    </li>
                    <li
                        onClick={handleProductDetails}
                        className="text-[#767676] hover:text-primeColor font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
                    >
                        Ürün Detay
                        <span className="">
                            <MdOutlineLabelImportant />
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Product;
