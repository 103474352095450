import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { FiPhoneCall } from "react-icons/fi";
import Map from "../../components/Contact/Map";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Lottie from "lottie-react";
import animationData from "../../assets/json/contact.json";
import { getContact, sendMessage } from "../../services/contactFetch";
import { useDispatch } from "react-redux";
import { showNotification } from "../../redux/notifications/notificationSlice";
import { BiMailSend } from "react-icons/bi";
import CircularProgress from "@mui/material/CircularProgress";

const Contact = () => {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        setPrevLocation(location?.state?.data);
    }, [location]);

    // Yup schema tanımı
    const schema = yup.object({
        name: yup.string().required("İsim Gerekli*"),
        email: yup.string().email("Geçerli bir email girin").required("Email Gerekli*"),
        subject: yup.string().required("Konu Gerekli*"),
        message: yup.string().required("Mesaj Gerekli*"),
    });

    // useForm hook kullanımı
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const contactData = async () => {
        try {
            const response = await getContact();
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };
    // console.log(data);
    useEffect(() => {
        contactData();
    }, []);

    // Form submit fonksiyonu
    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const response = await sendMessage(data);
            if (response.statusCode === 200) {
                dispatch(
                    showNotification({
                        message: "Mesajınız Başarıyla Gönderildi.",
                        type: "success",
                    })
                );
            }
            reset();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch(
                showNotification({
                    message: "Bir Hata Oluştu. Lütfen Daha Sonra Tekrar Deneyin.",
                    type: "error",
                })
            );
        }
    };

    return (
        <>
            {data?.map((item, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, translateY: 40 }}
                    animate={{ opacity: 1, translateY: 0 }}
                >
                    <div className="max-w-container mx-auto px-4">
                        <Breadcrumbs title="İletişim" prevLocation={prevLocation} />
                        <Helmet title="B2B | İletişim" />
                        <div className="max-w-container mx-auto">
                            <div className="text-white text-center mx-auto w-full rounded-xl bg-[#272b2f] gap-3 md:px-[50px] md:py-[15px] px-[20px] py-[30px] mt-[20px]">
                                <div className="flex flex-start text-2xl font-semibold">
                                    Liberta
                                </div>
                                <div className="text-start mt-1">
                                    <h1 className="text-[#9d9d9d] text-sm">Şirket Adres</h1>
                                    <p className="text-[0.9rem] md:w-[50%]">{item?.address}</p>
                                </div>
                                <div className="flex flex-col md:flex-row pb-10 md:gap-12 gap-8">
                                    <div className="md:w-[60%] md:gap-4 gap-1.8 grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 mt-2 overflow-hidden">
                                        <div className="text-start mt-2">
                                            <h1 className="text-[#9d9d9d] text-sm mb-1">email</h1>
                                            <a href={`mailto:${item?.email1}`} className="text-[#4b7bec] md:text-sm text-xs">
                                                {item?.email1}
                                            </a>
                                        </div>
                                        <div className="text-start mt-2">
                                            <a href={`mailto:${item?.email2}`}  className="text-[#9d9d9d] text-sm mb-1">email</a>
                                            <p className="text-[#4b7bec] md:text-sm text-xs">
                                                {item?.email2}
                                            </p>
                                        </div>

                                        <div className="text-start mt-2">
                                            <h1  className="text-[#9d9d9d] text-sm mb-1">
                                                Telefon Numarası
                                            </h1>
                                            <a href={`phone:${item?.phone2}`}  className="text-[0.875rem] md:text-sm text-xs">
                                                {item?.phone2}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:gap-5 gap-2 order-last w-[40%] md:mt-[-35px]">
                                        <div className="md:w-[288px] md:h-[120px] w-[230px] h-[100px] text-center rounded-2xl shadow-md bg-white flex p-5">
                                            <FiPhoneCall
                                                name="contact"
                                                size={40}
                                                color={"#c8102e"}
                                            />
                                            <div className="flex flex-col md:ml-5 ml-2 text-black">
                                                <h1 className="mb-1 md:text-xl">
                                                    Telefon Numarası
                                                </h1>
                                                <a
                                                    href={`phone:${item?.phone1}`} 
                                                    className="font-semibold text-start"
                                                >
                                                    {item?.phone1}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 gap-2">
                        <div className="max-w-container mx-auto px-4 py-12">
                            <form className="pb-20" onSubmit={handleSubmit(onSubmit)}>
                                <h1 className="font-titleFont font-semibold text-3xl">
                                    İletişim Formu
                                </h1>
                                <div className="w-[500px] max-md:w-[270px] h-auto py-6 flex flex-col gap-6">
                                    <div>
                                        <p className="text-base font-titleFont font-semibold px-2">
                                            Ad Soyad
                                        </p>
                                        <input
                                            {...register("name")}
                                            className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                                            type="text"
                                            placeholder="Adınız Soyadınızı Girin"
                                        />
                                        {errors.name && (
                                            <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2">
                                                {errors.name.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <p className="text-base font-titleFont font-semibold px-2">
                                            E-mail Adresiniz
                                        </p>
                                        <input
                                            {...register("email")}
                                            className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                                            type="email"
                                            placeholder="E-mail"
                                        />
                                        {errors.email && (
                                            <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2">
                                                {errors.email.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <p className="text-base font-titleFont font-semibold px-2">
                                            Başlık
                                        </p>
                                        <input
                                            {...register("subject")}
                                            className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                                            type="text"
                                            placeholder="Başlık giriniz*"
                                        />
                                        {errors.subject && (
                                            <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2">
                                                {errors.subject.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <p className="text-base font-titleFont font-semibold px-2">
                                            Mesajınız
                                        </p>
                                        <textarea
                                            {...register("message")}
                                            cols="30"
                                            rows="3"
                                            className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor resize-none"
                                            placeholder="Mesajınız..*"
                                        ></textarea>
                                        {errors.message && (
                                            <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2">
                                                {errors.message.message}
                                            </p>
                                        )}
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-44 bg-primeColor cursor-pointer text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200 flex items-center justify-center gap-2"
                                    >
                                        {loading ? (
                                            <>
                                                <CircularProgress color="inherit" size={"16px"} />
                                            </>
                                        ) : (
                                            <>
                                                <span>Gönder</span>
                                                <BiMailSend size={20} color="white" />
                                            </>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="flex items-center justify-center m-auto">
                            <Lottie
                                animationData={animationData}
                                style={{ width: "300px", height: "300px" }}
                                aria-labelledby="use lottie animation"
                                loop={false}
                                autoplay={true}
                            />
                        </div>
                    </div>
                    <Map mapAddress={item?.embedCode} />
                </motion.div>
            ))}
        </>
    );
};

export default Contact;
