// import React from "react";
// // import Brand from "./shopBy/Brand";
// import Category from "./shopBy/Category";
// import Price from "./shopBy/Price";
// import { useParams } from "react-router-dom";
// // import { IoMdClose } from "react-icons/io";
// import { IoClose } from "react-icons/io5";

// const ShopSideNav = ({ nav, handleNav }) => {
//     const { id } = useParams();
    
//     return (
//         <div className="w-full flex flex-col gap-6">
//             {!nav ?
//             (
//                 <div onClick={handleNav} className=" items-center smd:hidden inline-flex justify-end pt-5">
//                     <IoClose size={37}  className="p-1 cursor-pointer text-red-500 rounded-full hover:bg-red-500 hover:text-white"/>
//                 </div>
//             ) : null}
//             {id === undefined && <Category icons={false} />}
//             {/* <Brand />
//             <Color />*/}
//             {id === undefined && <Price />}
//         </div>
//     );
// };

// export default ShopSideNav;


import React from "react";
import Category from "./shopBy/Category";
import Price from "./shopBy/Price";
import { useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const ShopSideNav = ({ nav, handleNav }) => {
    const { id } = useParams();
    
    return (
        <div className="w-full flex flex-col gap-6 h-full overflow-auto">
            {!nav ? (
                <div onClick={handleNav} className="items-center smd:hidden inline-flex justify-end pt-5">
                    <IoClose size={37} className="p-1 cursor-pointer text-red-500 rounded-full hover:bg-red-500 hover:text-white" />
                </div>
            ) : null}
            {id === undefined && <Category icons={false} />}
            {/* <Brand />
            <Color />*/}
            {id === undefined && <Price />}
        </div>
    );
};

export default ShopSideNav;
