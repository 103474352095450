import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { useSelector } from "react-redux";

function Items({ currentItems, selectedPrices, selectedCategories }) {
    const filteredItems = currentItems?.filter((item) => {
        const isSelectedPrices =
        selectedPrices?.length === 0 ||
        selectedPrices?.some((price) =>
          item.productDimensions.some((dimension) =>
            dimension.price >= price.priceOne && dimension.price <= price.priceTwo
          )
        );
        
      const isCategorySelected =
          selectedCategories.length === 0 ||
          item.categories.some((category) =>
              selectedCategories.some((selectedCategory) =>
                  category.subCategories.some((subCategory) =>
                      selectedCategory.subCategoryId === subCategory.subCategoryId
                  )
              )
          );
  
      return isSelectedPrices && isCategorySelected;
  });

  console.log(filteredItems)
    return (
        <>
            {filteredItems.map((item) => (
                <div key={item.productId} className="w-full">
                    <Product
                        _id={item.productId}
                        img={[
                            { img: item.productImage1 },
                            { img: item.productImage2 },
                            { img: item.productImage3 },
                        ]}
                        productName={item.productName}
                        price="35.00"
                        dimension={item.dimensions}
                        categories={item.categories}
                        badge={false}
                        des={item.productDescription}
                        {...item}
                    />
                </div>
            ))}
        </>
    );
} 

const Pagination = ({ itemsPerPage }) => {
    const items = useSelector((state) => state.product.products);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemStart, setItemStart] = useState(1);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items?.slice(itemOffset, endOffset);
    const selectedPrices = useSelector((state) => state.filter.checkedPrices);
    const selectedCategories = useSelector((state) => state.filter.checkedCategorys);
    const pageCount = Math.ceil(items?.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        const newStart = newOffset + 1;
        setItemOffset(newOffset);
        setItemStart(newStart);
    };

    return (
        <div>
            <div className="grid xs:grid-cols-1 sm:grid-cols-1 mdl:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10 px-5">
                <Items
                    currentItems={currentItems}
                    selectedPrices={selectedPrices}
                    selectedCategories={selectedCategories}
                />
            </div>
            <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
                <ReactPaginate
                    nextLabel=""
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel=""
                    pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-600 duration-300 flex justify-center items-center rounded-xl"
                    pageClassName="mr-6"
                    containerClassName="flex text-base font-semibold font-titleFont py-10"
                    activeClassName="bg-black text-white rounded-xl"
                />

                <p className="text-base font-normal text-lightText">
                    {items.length} Ürün içinden {Math.min(endOffset, items.length)} ürün gösteriliyor
                    {/* {items.length} */}
                </p>
                {/* <button onClick={() => console.log(selectedBrands)}> test</button> */}
            </div>
        </div>
    );
};

export default Pagination;
