// import React, { useEffect, useState } from "react";
// import { Link, NavLink, useLocation } from "react-router-dom";
// import { MdClose } from "react-icons/md";
// import { HiMenuAlt2 } from "react-icons/hi";
// import { motion } from "framer-motion";
// import { logo } from "../../../assets/images";
// import Image from "../../designLayouts/Image";
// import { navBarList } from "../../../constants";
// import Flex from "../../designLayouts/Flex";
// import { useSelector } from "react-redux";
// import { useUser } from "../../../redux/user/hooks";
// import { MdOutlineLocalShipping } from "react-icons/md";

// const Header = () => {
//     const [showMenu, setShowMenu] = useState(true);
//     const [sidenav, setSidenav] = useState(false);
//     const [category, setCategory] = useState(false);
//     const [brand, setBrand] = useState(false);
//     const categories = useSelector((state) => state.product.categories);
//     const location = useLocation();
//     const user = useUser();

//     useEffect(() => {
//         let ResponsiveMenu = () => {
//             if (window.innerWidth < 667) {
//                 setShowMenu(false);
//             } else {
//                 setShowMenu(true);
//             }
//         };
//         ResponsiveMenu();
//         window.addEventListener("resize", ResponsiveMenu);
//     }, []);

//     const filteredNavBarList = navBarList.filter((item) => {
//         if (user?.role === "bayi" || user?.role === "userBayi") {
//            return item.title.toLowerCase() === "ürünler";
//         }
      
//         return true;
//     });

//     return (
//         <div className="w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200 shadow-sm">
//             <nav className="h-full px-4 max-w-container mx-auto relative">
//                 <Flex className="flex items-center justify-between h-full">
//                     <Link to="/">
//                         <div>
//                             <Image className="w-32 object-cover max-h-20" imgSrc={logo} />
//                         </div>
//                     </Link>
//                     <div>
//                         {showMenu && (
//                             <motion.ul
//                                 initial={{ y: 30, opacity: 0 }}
//                                 animate={{ y: 0, opacity: 1 }}
//                                 transition={{ duration: 0.5 }}
//                                 className="flex items-center w-auto z-50 p-0 gap-2"
//                             >
//                                 <>
//                                     {filteredNavBarList.map(({ _id, title, link }) =>
//                                         link ? (
//                                             <NavLink
//                                                 key={_id}
//                                                 className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
//                                                 to={link}
//                                                 state={{ data: location.pathname.split("/")[1] }}
//                                             >
//                                                 <li>{title}</li>
//                                                 <li>
//                                                 {title === "B2B Girişi" && (
//                                                         <MdOutlineLocalShipping className="ml-2 text-xl text-black" />
//                                                     )}
//                                                     </li>
//                                             </NavLink>
//                                         ) : (
//                                             <div
//                                                 key={_id}
//                                                 className="flex font-normal bg-inherit w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
//                                             >
//                                                 <div className="dropdown dropdown-hover">
//                                                     <div
//                                                         tabIndex={0}
//                                                         role="button"
//                                                         className="btn m-1 text-[#767676] bg-[#FFFFFF] border-none hover:bg-inherit hover:text-inherit"
//                                                     >
//                                                         {title}
//                                                     </div>
//                                                     <div
//                                                         tabIndex={0}
//                                                         className="flex flex-shrink justify-start mr-8"
//                                                     >
//                                                         <ul className="dropdown-content right-1 xl:menu-horizontal lg:min-w-max rounded-box z-[1] f menu p-2 shadow bg-base-100 text-[#878786]">
//                                                             {categories?.map((category, i) => (
//                                                                 <li key={i}>
//                                                                     <Link className="text-black">
//                                                                         {category.categoryName}
//                                                                     </Link>
//                                                                     <ul className="text-xs">
//                                                                         {category.subCategories?.map(
//                                                                             (
//                                                                                 subcategory,
//                                                                                 index
//                                                                             ) => (
//                                                                                 <li key={index}>
//                                                                                     <Link
//                                                                                         to={`/category/${subcategory?.subCategoryId}`}
//                                                                                     >
//                                                                                         {
//                                                                                             subcategory?.subCategoryName
//                                                                                         }
//                                                                                     </Link>
//                                                                                 </li>
//                                                                             )
//                                                                         )}
//                                                                     </ul>
//                                                                 </li>
//                                                             ))}
//                                                         </ul>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         )
//                                     )}
//                                 </>
//                             </motion.ul>
//                         )}
//                         <HiMenuAlt2
//                             onClick={() => setSidenav(!sidenav)}
//                             className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
//                         />
//                         {sidenav && (
//                             <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
//                                 <motion.div
//                                     initial={{ x: -300, opacity: 0 }}
//                                     animate={{ x: 0, opacity: 1 }}
//                                     transition={{ duration: 0.5 }}
//                                     className="w-[80%] h-full relative"
//                                 >
//                                     <div className="w-full h-full bg-primeColor p-6">
//                                         <img className="w-28 mb-6" src={logo} alt="logoLight" />
//                                         <ul className="text-gray-200 flex flex-col gap-2">
//                                             {filteredNavBarList.map((item) => (
//                                                 <li
//                                                     className="font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
//                                                     key={item._id}
//                                                 >
//                                                     <NavLink
//                                                         to={item.link}
//                                                         state={{
//                                                             data: location.pathname.split("/")[1],
//                                                         }}
//                                                         onClick={() => setSidenav(false)}
//                                                     >
//                                                         {item.title}
//                                                     </NavLink>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                         <div className="mt-4">
//                                             <h1
//                                                 onClick={() => setCategory(!category)}
//                                                 className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
//                                             >
//                                                 Kategori Listesi{" "}
//                                                 <span className="text-lg">
//                                                     {category ? "-" : "+"}
//                                                 </span>
//                                             </h1>
//                                             {category && (
//                                                 <motion.ul
//                                                     initial={{ y: 15, opacity: 0 }}
//                                                     animate={{ y: 0, opacity: 1 }}
//                                                     transition={{ duration: 0.4 }}
//                                                     className="text-sm flex flex-col gap-1"
//                                                 >
//                                                     <li className="headerSedenavLi">
//                                                         New Arrivals
//                                                     </li>
//                                                     <li className="headerSedenavLi">Gudgets</li>
//                                                     <li className="headerSedenavLi">Accessories</li>
//                                                     <li className="headerSedenavLi">Electronics</li>
//                                                     <li className="headerSedenavLi">Others</li>
//                                                 </motion.ul>
//                                             )}
//                                         </div>
//                                         <div className="mt-4">
//                                             <h1
//                                                 onClick={() => setBrand(!brand)}
//                                                 className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
//                                             >
//                                                 Shop by Brand
//                                                 <span className="text-lg">{brand ? "-" : "+"}</span>
//                                             </h1>
//                                             {brand && (
//                                                 <motion.ul
//                                                     initial={{ y: 15, opacity: 0 }}
//                                                     animate={{ y: 0, opacity: 1 }}
//                                                     transition={{ duration: 0.4 }}
//                                                     className="text-sm flex flex-col gap-1"
//                                                 >
//                                                     <li className="headerSedenavLi">
//                                                         New Arrivals
//                                                     </li>
//                                                     <li className="headerSedenavLi">Gudgets</li>
//                                                     <li className="headerSedenavLi">Accessories</li>
//                                                     <li className="headerSedenavLi">Electronics</li>
//                                                     <li className="headerSedenavLi">Others</li>
//                                                 </motion.ul>
//                                             )}
//                                         </div>
//                                     </div>
//                                     <span
//                                         onClick={() => setSidenav(false)}
//                                         className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
//                                     >
//                                         <MdClose />
//                                     </span>
//                                 </motion.div>
//                             </div>
//                         )}
//                     </div>
//                 </Flex>
//             </nav>
//         </div>
//     );
// };

// export default Header;




import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";
import { motion } from "framer-motion";
import { logo } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { menuList, navBarList } from "../../../constants";
import Flex from "../../designLayouts/Flex";
import { useSelector } from "react-redux";
import { useUser } from "../../../redux/user/hooks";
import { MdOutlineLocalShipping } from "react-icons/md";

const Header = () => {
    const [showMenu, setShowMenu] = useState(true);
    const [sidenav, setSidenav] = useState(false);
    const [category, setCategory] = useState(false);
    const [brand, setBrand] = useState(false);
    const categories = useSelector((state) => state.product.categories);
    const location = useLocation();
    const user = useUser();

    useEffect(() => {
        const ResponsiveMenu = () => {
            setShowMenu(window.innerWidth >= 667);
        };
        ResponsiveMenu();
        window.addEventListener("resize", ResponsiveMenu);
        return () => window.removeEventListener("resize", ResponsiveMenu);
    }, []);

    const filteredNavBarList = navBarList.filter((item) => {
        if (user?.role === "bayi" || user?.role === "userBayi") {
            return item.title.toLowerCase() === "ürünler";
        }
        return true;
    });

    const filteredNavBarMenuList = menuList.filter((item) => {
        if (user?.role === "bayi" || user?.role === "userBayi") {
            return item.title.toLowerCase() === "ürünler";
        }
        return true;
    });

    return (
        <div className="w-full h-20 bg-white sticky top-0 z-50 border-b-[1px] border-b-gray-200 shadow-sm">
            <nav className="h-full px-4 max-w-container mx-auto relative">
                <Flex className="flex items-center justify-between h-full">
                    <Link to="/">
                        <div>
                            <Image className="w-32 object-cover max-h-20" imgSrc={logo} />
                        </div>
                    </Link>
                    <div>
                        {showMenu && (
                            <motion.ul
                                initial={{ y: 30, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className="flex items-center w-auto z-50 p-0 gap-2"
                            >
                                {filteredNavBarList.filter(({ title }) => !(title === "B2B Girişi" && user?.role)).map(({ _id, title, link }) =>
                                    link ? (
                                        <NavLink
                                            key={_id}
                                            className="flex font-normal hover:font-bold w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] decoration-[1px] hover:text-[#262626] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                                            to={link}
                                            state={{ data: location.pathname.split("/")[1] }}
                                        >
                                            <li>{title}</li>
                                            <li>
                                                {title === "B2B Girişi" && (
                                                    <MdOutlineLocalShipping className="ml-2 text-xl text-black" />
                                                )}
                                            </li>
                                        </NavLink>
                                    ) : (
                                        <div
                                            key={_id}
                                            className="flex font-normal bg-inherit w-20 h-6 justify-center items-center px-12 text-base text-[#767676] hover:underline underline-offset-[4px] md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                                        >
                                            <div className="dropdown dropdown-hover">
                                                <div
                                                    tabIndex={0}
                                                    role="button"
                                                    className="btn m-1 text-[#767676] bg-[#FFFFFF] border-none hover:bg-inherit hover:text-inherit"
                                                >
                                                    {title}
                                                </div>
                                                <div
                                                    tabIndex={0}
                                                    className="flex flex-shrink justify-start mr-8"
                                                >
                                                    <ul className="dropdown-content right-1 xl:menu-horizontal lg:min-w-max rounded-box z-[1] f menu p-2 shadow bg-base-100 text-[#878786]">
                                                        {categories?.map((category, i) => (
                                                            <li key={i}>
                                                                <Link className="text-black">
                                                                    {category.categoryName}
                                                                </Link>
                                                                <ul className="text-xs">
                                                                    {category.subCategories?.map(
                                                                        (
                                                                            subcategory,
                                                                            index
                                                                        ) => (
                                                                            <li key={index}>
                                                                                <Link
                                                                                    to={`/category/${subcategory?.subCategoryId}`}
                                                                                >
                                                                                    {
                                                                                        subcategory?.subCategoryName
                                                                                    }
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </motion.ul>
                        )}
                        <HiMenuAlt2
                            onClick={() => setSidenav(!sidenav)}
                            className="inline-block md:hidden cursor-pointer w-8 h-6 absolute top-6 right-4"
                        />
                        {sidenav && (
                            <div className="fixed top-0 left-0 w-full h-screen bg-black text-gray-200 bg-opacity-80 z-50">
                                <motion.div
                                    initial={{ x: -300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    className="w-[80%] h-full relative"
                                >
                                    <div className="w-full h-full bg-primeColor p-6">
                                        <img className="w-28 mb-6" src={logo} alt="logoLight" />
                                        <ul className="text-gray-200 flex flex-col gap-2">
                                            {filteredNavBarMenuList.filter(({ title }) => !(title === "B2B Girişi" && user?.role)).map((item) => (
                                                <li
                                                    className="font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                                                    key={item._id}
                                                >
                                                    <NavLink
                                                        to={item.link}
                                                        state={{
                                                            data: location.pathname.split("/")[1],
                                                        }}
                                                        onClick={() => setSidenav(false)}
                                                    >
                                                        {item.title}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="mt-1">
                                            <h1
                                                onClick={() => setCategory(!category)}
                                                className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                                            >
                                                Kategori Listesi{" "}
                                                <span className="text-lg">
                                                    {category ? "-" : "+"}
                                                </span>
                                            </h1>
                                            {category && (
                                                <motion.ul
                                                    initial={{ y: 15, opacity: 0 }}
                                                    animate={{ y: 0, opacity: 1 }}
                                                    transition={{ duration: 0.4 }}
                                                    className="grid grid-cols-2 gap-1"
                                                >
                                                    {categories?.map((category, i) => (
                                                        <li key={i} className="mb-2">
                                                            <Link className="text-red-500 mb-1">
                                                                {category.categoryName}
                                                            </Link>
                                                            <ul className="text-lg">
                                                                {category.subCategories?.map(
                                                                    (
                                                                        subcategory,
                                                                        index
                                                                    ) => (
                                                                        <li key={index}>
                                                                            <Link
                                                                                to={`/category/${subcategory?.subCategoryId}`}
                                                                                className="text-sm"
                                                                            >
                                                                                {
                                                                                    subcategory?.subCategoryName
                                                                                }
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </li>
                                                    ))}
                                                </motion.ul>
                                            )}
                                        </div>
                                        {/* <div className="mt-4">
                                            <h1
                                                onClick={() => setBrand(!brand)}
                                                className="flex justify-between text-base cursor-pointer items-center font-titleFont mb-2"
                                            >
                                                Shop by Brand
                                                <span className="text-lg">{brand ? "-" : "+"}</span>
                                            </h1>
                                            {brand && (
                                                <motion.ul
                                                    initial={{ y: 15, opacity: 0 }}
                                                    animate={{ y: 0, opacity: 1 }}
                                                    transition={{ duration: 0.4 }}
                                                    className="text-sm flex flex-col gap-1"
                                                >
                                                    <li className="headerSedenavLi">
                                                        New Arrivals
                                                    </li>
                                                    <li className="headerSedenavLi">Gudgets</li>
                                                    <li className="headerSedenavLi">Accessories</li>
                                                    <li className="headerSedenavLi">Electronics</li>
                                                    <li className="headerSedenavLi">Others</li>
                                                </motion.ul>
                                            )}
                                        </div> */}
                                    </div>
                                    <span
                                        onClick={() => setSidenav(false)}
                                        className="w-8 h-8 border-[1px] border-gray-300 absolute top-2 -right-10 text-gray-300 text-2xl flex justify-center items-center cursor-pointer hover:border-red-500 hover:text-red-500 duration-300"
                                    >
                                        <MdClose />
                                    </span>
                                </motion.div>
                            </div>
                        )}
                    </div>
                </Flex>
            </nav>
        </div>
    );
};

export default Header;
